const BUTTON_STYLES = {
    default: 'btn uix-btn-default',
    disabled: 'btn btn-primary disabled',
    primary: 'btn btn-primary fb-form-button',
    secondary: 'btn btn-outline-primary fb-form-button italics',
    active: 'btn uix-btn-active',
    danger: 'btn btn-danger',
    success: 'btn btn-success',
    warning: 'btn btn-warning',
    info: 'btn btn-info',
    light: 'btn btn-light',
    dark: 'btn btn-dark',
    link: 'btn btn-link',
    linkItalic: 'btn btn-link italics',
    return: 'btn btn-return',
    abort: 'btn btn-abort',
    caution: 'btn btn-caution',
    defaultOutline: 'btn uix-btn-outline-default',
    primaryOutline: 'btn btn-outline-primary fb-form-button',
    secondaryOutline: 'btn btn-outline-secondary',
    activeOutline: 'btn uix-btn-outline-active',
    dangerOutline: 'btn btn-outline-danger',
    successOutline: 'btn btn-outline-success',
    warningOutline: 'btn btn-outline-warning',
    infoOutline: 'btn btn-outline-info',
    lightOutline: 'btn btn-outline-light',
    darkOutline: 'btn btn-outline-dark',
    linkOutline: 'btn btn-outline-link',
};

const ALERT_INPUT_CLASSES = {
    trailerNotFound: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.trailerNotFound',
        textClass: 'uix-error-text',
    },
    error: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.error',
        textClass: 'uix-error-text',
    },
    required: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.required',
        textClass: 'uix-error-text',
    },
    maxLength: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.maxLength',
        textClass: 'uix-error-text',
    },
    minLength: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.maxLength',
        textClass: 'uix-error-text',
    },
    validation: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.validation',
        textClass: 'uix-error-text',
    },
    info: {
        baseClass: 'info',
        class: 'has-info',
        text: 'alertText.info!',
        textClass: 'uix-info-text',
    },
    invalidPhoneNumber: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.invalidPhoneNumber',
        textClass: 'uix-error-text',
    },
    notFound: {
        baseClass: 'error',
        class: 'has-error',
        text: 'alertText.notFound',
        textClass: 'uix-error-text',
    },
    warning: {
        baseClass: 'warning',
        class: 'has-warning',
        text: 'alertText.warning',
        textClass: 'uix-warning-text',
    },
    success: {
        baseClass: 'success',
        class: 'has-success',
        text: 'alertText.success',
        textClass: 'uix-success-text',
    },
};

const VIRTUAL_KEYBOARD_INPUT_MODES = [
    'text',
    'decimal',
    'numeric',
    'tel',
    'search',
    'email',
    'url',
];
const DEFAULT_DROPDOWN_MENU_CLASSES = [
    'dropdown-menu',
    'uix-dropdown-menu',
    'fbx-select',
].join(' ');
const DEFAULT_DROPDOWN_PARENT_CLASSES = [
    'uix-control',
    'uix-select-control',
    'fbx-select',
].join(' ');

const ALERT_INPUT_THEMES = Object.keys(ALERT_INPUT_CLASSES);
const BUTTON_THEMES = Object.keys(BUTTON_STYLES);

export default {
    buttonThemes: BUTTON_THEMES,
    alertInputThemes: ALERT_INPUT_THEMES,
    defaultDropdownMenuClasses: DEFAULT_DROPDOWN_MENU_CLASSES,
    defaultDropdownParentClasses: DEFAULT_DROPDOWN_PARENT_CLASSES,
    virtualKeyboardInputModes: VIRTUAL_KEYBOARD_INPUT_MODES,

    isTheClickTargetADropdown(event) {
        const targetClass = event?.target?.className || '';
        if (typeof targetClass !== 'string' && !Array.isArray(targetClass)) {
            return false;
        }
        if (
            targetClass.includes('dropdown') ||
            targetClass.includes('select') ||
            targetClass.includes('fb-dropdown-toggle')
        ) {
            return true;
        } else {
            return false;
        }
    },
    getButtonClassesForTheme(theme) {
        if (BUTTON_THEMES.includes(theme)) {
            return BUTTON_STYLES[theme];
        } else {
            return 'btn';
        }
    },

    getInputClassesForTheme(theme) {
        if (ALERT_INPUT_THEMES.includes(theme)) {
            return ALERT_INPUT_CLASSES[theme];
        } else {
            return '';
        }
    },
    getButtonThemes() {
        return BUTTON_THEMES;
    },
};
