export enum ActionTypes {
    AUTHORIZE_DRIVER = 'AUTHORIZE_DRIVER',
    CLEAR_TRAILER_ASSESSMENT = 'CLEAR_TRAILER_ASSESSMENT',
    CREATE_NAVIGATION_DESTINATION = 'CREATE_NAVIGATION_DESTINATION',
    FETCH_CURRENT_NAVIGATION_DESTINATION = 'FETCH_CURRENT_NAVIGATION_DESTINATION',
    FETCH_CUSTOMER_AND_TRAILERS_AT_CURRENT_LOCATION = 'FETCH_CUSTOMER_AND_TRAILERS_AT_CURRENT_LOCATION',
    FETCH_DRIVER = 'FETCH_DRIVER',
    FETCH_FEATURE_FLAGS = 'FETCH_FEATURE_FLAGS',
    FETCH_NEARBY_TRAILER_RESULTS = 'FETCH_NEARBY_TRAILER_RESULTS',
    FETCH_TRUCK = 'FETCH_TRUCK',
    FETCH_ACTIVE_LOAD = 'FETCH_ACTIVE_LOAD',
    FETCH_LAST_ACTIVE_TRAILER_ASSESSMENT = 'FETCH_LAST_ACTIVE_TRAILER_ASSESSMENT',
    BEGIN_TRAILER_ASSESSMENT = 'BEGIN_TRAILER_ASSESSMENT',
    POST_TRAILER_ASSESSMENT = 'POST_TRAILER_ASSESSMENT',
    POST_ATTACH_DRIVER_TRAILER = 'POST_ATTACH_DRIVER_TRAILER',
    POST_DETACH_DRIVER_TRAILER = 'POST_DETACH_DRIVER_TRAILER',
    REFRESH_TRUCK = 'REFRESH_TRUCK',
    REMOVE_NAVIGATION_DESTINATION = 'REMOVE_NAVIGATION_DESTINATION',
    REMOVE_PARTIAL_TRAILER_ASSESSMENT = 'REMOVE_PARTIAL_TRAILER_ASSESSMENT',
    SAVE_PARTIAL_TRAILER_ASSESSMENT = 'SAVE_PARTIAL_TRAILER_ASSESSMENT',
    CLEAR_QUERY_PARAM_AND_ROUTE = 'CLEAR_QUERY_PARAM_AND_ROUTE',
    SET_NETWORK_TIMEOUT = 'SET_NETWORK_TIMEOUT',
    CLEAR_ERROR = 'CLEAR_ERROR',
    SET_SELECTED_TRAILER = 'SET_SELECTED_TRAILER',
    UPDATE_TRAILER_ASSESSMENT = 'UPDATE_TRAILER_ASSESSMENT',
    UPDATE_TRAILER_ASSESSMENT_QUESTION = 'UPDATE_TRAILER_ASSESSMENT_QUESTION',
    UPDATE_TRAILER_ASSESSMENT_OUTCOME = 'UPDATE_TRAILER_ASSESSMENT_OUTCOME',
    REMOVE_TRAILER_ASSESSMENT = 'REMOVE_TRAILER_ASSESSMENT',
    UPDATE_TRAILER_RESULTS_WITH_ROUTE_INFO = 'UPDATE_TRAILER_RESULTS_WITH_ROUTE_INFO',
    UPDATE_DRIVER_PTA = 'UPDATE_DRIVER_PTA',
    SET_LOCATION_OUTDATED_WARNING_BANNER = 'SET_LOCATION_OUTDATED_WARNING_BANNER',
    SET_PERMISSION_DENIED_WARNING_BANNER = 'SET_PERMISSION_DENIED_WARNING_BANNER',
    UPDATE_PERSISTENT_ALERT_BANNER = 'UPDATE_PERSISTENT_ALERT_BANNER',
    HANDLE_LOCATION_ACCESS_ERROR = 'HANDLE_LOCATION_ACCESS_ERROR',
    CLEAR_BANNER_ALERT = 'CLEAR_BANNER_ALERT',
    SUBMIT_DRIVER_FEEDBACK = 'SUBMIT_DRIVER_FEEDBACK',
    GET_HIGH_ACCURACY_LOCATION = 'GET_HIGH_ACCURACY_LOCATION',
    BEGIN_WATCHING_DEVICE_LOCATION = 'BEGIN_WATCHING_DEVICE_LOCATION',
    STOP_WATCHING_DEVICE_LOCATION = 'STOP_WATCHING_DEVICE_LOCATION',
    REFRESH_PINNED_LOCATION = 'REFRESH_PINNED_LOCATION',
    REPORT_QR_UNASSOCIATED = 'REPORT_QR_UNASSOCIATED',
    SET_FEEDBACK_SUCCESS_BANNER = 'SET_FEEDBACK_SUCCESS_BANNER',
    SET_FEEDBACK_ERROR_BANNER = 'SET_FEEDBACK_ERROR_BANNER',
    SHOW_LOCATION_BEGGING_MODAL = 'SHOW_LOCATION_BEGGING_MODAL',
    HIDE_LOCATION_BEGGING_MODAL = 'HIDE_LOCATION_BEGGING_MODAL',
    SET_WORKFLOW_ORIGIN = 'SET_WORKFLOW_ORIGIN',
    SET_DETACH_SUCCESS_BANNER = 'SET_DETACH_SUCCESS_BANNER',
    SET_DETACH_ERROR_BANNER = 'SET_DETACH_ERROR_BANNER',
}
