import { FeedbackTypes } from '@/types/FeedbackTypes';

const globalConstants = {
    companies: {
        knight: 'knight',
        swift: 'swift',
    },
    nearbyResultListCount: 5,
    customEventTypes: {
        HIGHLIGHT_POINT: 'highlightpoint',
    },
    reasonCodes: {
        configurationError: 461,
        truckNotFound: 468,
        driverNotFound: 494,
        trailerNotAttachable: 440,
        trailerNotDetachable: 441,
        trailerFailedElasticUpsert: 442,
        loadFailedElasticUpsert: 443,
        loadedTrailerNotDetachable: 449,
        invalidQr: 452,
        QrNotAssociated: 455,
        trailerNotFound: 480,
        invalidTrailerType: 484,
    },
    httpStatusCodes: {
        success: 200,
        timeout: 408,
    },
    locale: {
        english: 'en',
        spanish: 'es',
    },
    map: {
        customerLocationViewRadius: 1,
    },
    driverFeedback: {
        options: [
            'Customer refused to release',
            'Trailer damaged',
            'Unable to find trailer',
            'Trailer is loaded',
            'Another truck is attached',
        ],
        optionCodes: [
            FeedbackTypes.customerRefused,
            FeedbackTypes.trailerDamaged,
            FeedbackTypes.unableToFindTrailer,
            FeedbackTypes.trailerIsLoaded,
            FeedbackTypes.anotherTruckIsAttached,
        ],
    },
    trailer: {
        assistance: {
            knight: 'Breakdown',
            swift: 'OnRoad',
        },
        trailerAssessment: {
            updateAssessmentSource: 'Trailer Solutions Driver Update',
        },
        assessmentTypes: {
            brakes: 'brakes',
            tires: 'tires',
            lights: 'lights',
            damage: 'damage',
            kingpinLanding: 'kingpinLanding',
            airlinesElectrical: 'airlinesElectrical',
            other: 'other',
            subTypes: {
                kingpin: 'kingpin',
                driverSide: 'driverSide',
                passengerSide: 'passengerSide',
                frontSide: 'frontSide',
                rearSide: 'rearSide',
                driverTop: 'driverTop',
                centerTop: 'centerTop',
                passengerTop: 'passengerTop',
                driverBottom: 'driverBottom',
                centerBottom: 'centerBottom',
                passengerBottom: 'passengerBottom',
                leftAirline: 'leftAirline',
                sevenWay: '7way',
                rightAirline: 'rightAirline',
                lowerAirlineElectrical: 'lowerAirlineElectrical',
                other: 'other',
            },
        },
        cargoStatuses: {
            loaded: 0,
            empty: 1,
            noSensor: 2,
            sensorError: 3,
        },

        availability: {
            0: 'Available',
            1: 'Available+',
            2: 'Unavailable',
        },
        trailerNumberInputLength: 6,
        types: {
            DEDICATED_ROLL_DOOR: 'Dedicated Roll-Door',
            DEDICATED_VAN: 'Dedicated Van',
            HIGH_CUBE: 'High Cube',
            ROLL_DOOR: 'Roll-Door',
            STANDARD: 'Standard',
        },
        typeCodes: {
            DEDICATED_ROLL_DOOR: ['L3'],
            DEDICATED_VAN: ['DV'],
            HIGH_CUBE: ['HC', 'H3'],
            ROLL_DOOR: ['LR', '3P', 'P3'],
            STANDARD: [
                'HB',
                'VN',
                'D3',
                'LS',
                'HA',
                'LC',
                'A3',
                'W3',
                'E3',
                'V3',
            ],
        },
    },
    keyboard: {
        key: {
            backspace: 'Backspace',
            digits: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        },
        code: {
            backspace: 'Backspace',
            digits: [
                'Digit1',
                'Digit2',
                'Digit3',
                'Digit4',
                'Digit5',
                'Digit6',
                'Digit7',
                'Digit8',
                'Digit9',
                'Digit0',
            ],
        },
    },
    keycloak: {
        realm: 'drivers',
        clientId: 'trailer-solutions',
    },
    viewNames: {
        findTrailer: 'find a trailer',
        selectTrailer: 'select a trailer',
    },
    distanceConversions: {
        metersPerMile: 1609.344,
    },
};

export default globalConstants;
