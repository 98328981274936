import TrailerAssessmentSubType from '@/types/TrailerAssessmentSubType';
import TrailerAssessmentType from '@/types/TrailerAssessmentType';
import { DateTime, Interval } from 'luxon';
import TrailerAssessment from '@/types/TrailerAssessment';
import Trailer from '@/types/Trailer';
import globalConstants from './globalConstants';
const { knight, swift } = globalConstants.companies;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNullOrUndefined = (val: any): val is null | undefined => {
    return val === null || val === undefined || typeof val === 'undefined';
};

export const convertNullUndefinedOrEmptyToDash: (val?: string) => string = (
    val?: string
) => {
    if (isNullOrUndefined(val) || (val as string).trim() === '') {
        return '-';
    } else {
        return val as string;
    }
};

export const assessmentHasImagesOrComments = function (
    assessment: TrailerAssessmentType,
    subTypeName: string
) {
    if (isNullOrUndefined(assessment) || isNullOrUndefined(subTypeName)) {
        return false;
    }

    const subType = assessment.subTypes?.find(
        (ele: TrailerAssessmentSubType) => ele.subType === subTypeName
    );

    if (
        subType === null ||
        subType === undefined ||
        typeof subType === 'undefined'
    ) {
        return false;
    }

    return !isNullOrUndefined(subType.comment);
};

export const shouldClearTypeOnSubtypeRemoval = (
    assessment: TrailerAssessment,
    damageUpdate: TrailerAssessmentSubType
) => {
    const subTypes = assessment[damageUpdate.type]?.subTypes;
    if (isNullOrUndefined(subTypes)) {
        return false;
    }

    return (
        subTypes.length === 1 && subTypes[0].subType === damageUpdate.subType
    );
};

export const stopPropagation = (evt: Event) => {
    try {
        if (typeof evt.preventDefault === 'function') {
            evt.preventDefault();
        }
        if (typeof evt.stopImmediatePropagation === 'function') {
            evt.stopImmediatePropagation();
        } else {
            evt.cancelBubble = true;
        }
    } catch (exception) {
        // No-op
    }
};

export const isToday = (dateTime: DateTime) => {
    return DateTime.now().toISODate() === dateTime.toISODate();
};

export const isTomorrow = (dateTime: DateTime) => {
    return (
        DateTime.now().plus({ days: 1 }).toISODate() === dateTime.toISODate()
    );
};

export const elapsedTimeInSecondsSince = (startTime: DateTime) => {
    const assessmentDuration = Interval.fromDateTimes(
        startTime,
        DateTime.now()
    );

    return Math.round(assessmentDuration.length('seconds'));
};

export async function getCurrentLocation(
    options?: PositionOptions
): Promise<GeolocationPosition> {
    return new Promise(
        (resolve: PositionCallback, reject: PositionErrorCallback) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    resolve,
                    reject,
                    options
                );
            } else {
                reject(<GeolocationPositionError>{
                    code: GeolocationPositionError.POSITION_UNAVAILABLE,
                    message: 'navigator.geolocation unavailable or denied.',
                });
            }
        }
    );
}

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hashCode(str: string): number {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function trailerType(trailer: Trailer): string {
    const { typeCodes, types } = globalConstants.trailer;
    const type = trailer.type;

    if (typeCodes.DEDICATED_ROLL_DOOR.includes(type))
        return types.DEDICATED_ROLL_DOOR;
    if (typeCodes.DEDICATED_VAN.includes(type)) return types.DEDICATED_VAN;
    if (typeCodes.HIGH_CUBE.includes(type)) return types.HIGH_CUBE;
    if (typeCodes.ROLL_DOOR.includes(type)) return types.ROLL_DOOR;
    if (typeCodes.STANDARD.includes(type)) return types.STANDARD;

    return '';
}

export function titleCase(value: string): string {
    return value[0].toUpperCase() + value.slice(1);
}

export function getReadableGeolocationPositionErrorCode(value: number) {
    let readableCode = '';
    switch (value) {
        case GeolocationPositionError.POSITION_UNAVAILABLE:
            readableCode = 'POSITION_UNAVAILABLE';
            break;
        case GeolocationPositionError.PERMISSION_DENIED:
            readableCode = 'PERMISSION_DENIED';
            break;
        case GeolocationPositionError.TIMEOUT:
            readableCode = 'TIMEOUT';
            break;
        default:
            readableCode = 'Unknown';
            break;
    }
    return readableCode;
}

export function getReturnAppName(company?: string) {
    if (company === knight) {
        return 'KT Mobile';
    } else if (company === swift) {
        return 'Pointe';
    } else {
        return '';
    }
}

export function getDirectContactTitle(company?: string, lowercase?: boolean) {
    let contact = 'Driver Manager/Leader';

    if (company === knight) {
        contact = 'Driver Manager';
    } else if (company === swift) {
        contact = 'Driver Leader';
    }

    if (lowercase) {
        contact = contact.toLowerCase();
    }
    return contact;
}

export function trimInput(input: string | null) {
    if (!isNullOrUndefined(input)) {
        input = input.trim();

        if (input.length === 0) input = null;
    }

    return input;
}

export function GenerateGuid(): string | null {
    try {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        return (<any>crypto)?.randomUUID() + '-' + DateTime.now().toMillis();
    } catch (ex: any) {
        console.error('Crypto is not supported: ', ex);
        return null;
    }
}
