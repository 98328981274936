import axios from 'axios';
import { KnxApp } from '@/types/KnxApp';
import PersistentAlert from '@/types/PersistentAlert';
import FbaApiError from '@/types/FbaApiError';
import { isNullOrUndefined } from '@/shared/helpers';
import apiErrorHandler from '@/libraries/apiErrorHandler';
import IPersistentAlertDto from '@/types/ApiDtos/IPersistentAlertDto';

export default async function getPersistentAlert(): Promise<
    PersistentAlert | null | FbaApiError
> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/alerts/persistentAlert/${KnxApp.FreightBoxTrailerSolutions}`,
        });

        const { data } = response;
        if (!isNullOrUndefined(data?.alert))
            return PersistentAlert.mapFromIPersistentAlertDto(
                data?.alert as IPersistentAlertDto
            );
        else return null;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
