import { store } from '@/store';
import { ActionTypes } from '@/store/actionTypes';
import useCameraPermission from '@/shared/composables/useCameraPermission';
import useLocationPermission from '@/shared/composables/useLocationPermission';

export default function usePermissionAlert() {
    const { shouldAlertOnLocationPermission } = useLocationPermission();
    const { shouldAlertOnCameraPermission } = useCameraPermission();

    const setPermissionAlert = (): void => {
        store.dispatch(ActionTypes.SET_PERMISSION_DENIED_WARNING_BANNER, {
            alertCamera: shouldAlertOnCameraPermission(),
            alertLocation: shouldAlertOnLocationPermission(),
        });
    };

    return {
        setPermissionAlert,
    };
}
