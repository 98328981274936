import apiErrorHandler from '@/libraries/apiErrorHandler';
import { SelectedQRSide } from '@/store/types';
import FbaApiError from '@/types/FbaApiError';
import axios from 'axios';

export interface IReportQrUnassociatedDto {
    driverCode: string;
    driverCompany: string;
    scannedQrId: string;
    trailerNumber: string;
    trailerCompany: string;
    isDeviceLocationShared: boolean;
    reportedLocation: LocationDto;
    selectedQRSide: SelectedQRSide;
}

interface LocationDto {
    latitude: number;
    longitude: number;
}

export default async function postReportQrUnassociated(
    data: IReportQrUnassociatedDto
): Promise<{ IsSuccessful: boolean } | FbaApiError> {
    try {
        const response = await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/reportQrUnassociated`,
            data,
        });

        return response.data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
