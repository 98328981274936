/* eslint-disable @typescript-eslint/no-explicit-any */
//// <reference types="heremaps" />

import CustomMarker from './marker';

export default class CustomTheme {
    static create() {
        return (points: H.clustering.DataPoint[], options: any) => {
            const themeOptions: any = {
                theme: {
                    getNoisePresentation: function (
                        point: H.clustering.INoisePoint
                    ) {
                        const data = point.getData();
                        const domMarker = CustomMarker.createDomMarker(data);
                        domMarker.setData(data);
                        return domMarker;
                    },

                    getClusterPresentation: function (
                        cluster: H.clustering.ICluster
                    ) {
                        const clusterMarker = new H.map.Marker(
                            cluster.getPosition(),
                            {
                                data: {}, //Type any, but is required.
                                min: cluster.getMinZoom(),
                                max: cluster.getMaxZoom(),
                                visibility: false,
                            }
                        );
                        clusterMarker.setData(cluster);
                        return clusterMarker;
                    },
                },
            };

            Object.entries(options).forEach((item) => {
                const [key, val] = item;
                themeOptions[key] = val;
            });

            return new H.clustering.Provider(points, themeOptions);
        };
    }
}
