import { DateTime } from 'luxon';
import DriverDto, { DispatchLoadDto } from './ApiDtos/IDriverDto';
import PhoneNumber from './PhoneNumber';

export default class Driver {
    isLoaded: boolean;
    company: string;
    driverCode: string;
    phoneNumbers: PhoneNumber[];
    truckCode: string;
    id: string;
    firstName: string;
    lastName: string;
    pta: DateTime;
    dispatch: DispatchLoadDto;

    constructor(
        isLoaded: boolean,
        company: string,
        driverCode: string,
        phoneNumbers: PhoneNumber[],
        truckCode: string,
        id: string,
        firstName: string,
        lastName: string,
        pta: DateTime,
        dispatch: DispatchLoadDto
    ) {
        this.isLoaded = isLoaded;
        this.company = company;
        this.driverCode = driverCode;
        this.phoneNumbers = phoneNumbers;
        this.truckCode = truckCode;
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.pta = pta;
        this.dispatch = dispatch;
    }

    static mapFromDriverDto(dto: DriverDto): Driver {
        return new Driver(
            dto.isLoaded,
            dto.company,
            dto.driverCode,
            dto.phoneNumbers.map(
                (num) => new PhoneNumber(num.number, num.type)
            ),
            dto.truckCode,
            dto.id,
            dto.firstName,
            dto.lastName,
            DateTime.fromISO(dto.pta),
            dto.dispatch
        );
    }
}
