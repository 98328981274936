import BaseMapPoint from './BaseMapPoint';
import Geopoint from './Geopoint';
import Truck from './Truck';

export default class TruckMapPoint implements BaseMapPoint {
    location: Geopoint;
    icon: HTMLElement;
    data: Truck;

    constructor(location: Geopoint, data: Truck) {
        this.location = location;
        this.data = data;
        this.icon = this.getIcon();
    }

    getIcon() {
        const element = document.createElement('div');
        element.classList.add('truck-icon');
        element.classList.add('map-icon');
        return element;
    }
}
