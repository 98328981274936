import globalConstants from '@/shared/globalConstants';
/// <reference types="heremaps" />
// various unused variables are currently in here as template/boilerplate stuff, since these
// markers will probably end up becoming as cursed as the ones in asset tracker.
/* eslint-disable @typescript-eslint/no-unused-vars */
import BaseMapPoint from '@/types/BaseMapPoint';
const H = (<any>window).H; // eslint-disable-line @typescript-eslint/no-explicit-any

const mouseoverHandler =
    (element: HTMLElement, domMarker: H.map.DomMarker) => (event: Event) => {
        element.classList.add('hover');
    };

const mouseoutHandler =
    (element: HTMLElement, domMarker: H.map.DomMarker) => (event: Event) => {
        element.classList.remove('hover');
    };

const markerHighlightListener =
    (element: HTMLElement, domMarker: H.map.DomMarker) => (event: any) => {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        const ref = domMarker.getData();

        if (event?.detail?.trailers[0]?.id === ref?.data?.trailers[0]?.id) {
            element.classList.add('highlight-icon-marker');
        } else {
            element.classList.remove('highlight-icon-marker');
        }
    };

export default class CustomMarker {
    static createDomMarker(point: BaseMapPoint): H.map.DomMarker {
        const domIcon = new H.map.DomIcon(point.icon, {
            onAttach: (
                element: HTMLElement,
                domIcon: H.map.DomIcon,
                domMarker: H.map.DomMarker
            ) => {
                element.addEventListener(
                    'mouseover',
                    mouseoverHandler(element, domMarker)
                );
                element.addEventListener(
                    'mouseout',
                    mouseoutHandler(element, domMarker)
                );
                element.addEventListener(
                    globalConstants.customEventTypes.HIGHLIGHT_POINT,
                    markerHighlightListener(element, domMarker)
                );
            },
            onDetach: (
                element: HTMLElement,
                domIcon: H.map.DomIcon,
                domMarker: H.map.DomMarker
            ) => {
                element.removeEventListener(
                    'mouseover',
                    mouseoverHandler(element, domMarker)
                );
                element.removeEventListener(
                    'mouseout',
                    mouseoutHandler(element, domMarker)
                );
                element.removeEventListener(
                    globalConstants.customEventTypes.HIGHLIGHT_POINT,
                    markerHighlightListener(element, domMarker)
                );
            },
        });
        return new H.map.DomMarker(point.location, { icon: domIcon });
    }
}
