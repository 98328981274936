import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import IEleosNavigationTaskDto from '@/types/ApiDtos/IEleosNavigationTaskDto';
import FbaApiError from '@/types/FbaApiError';

export default async function getActiveNavigationDestination(
    company: string,
    driverCode: string
): Promise<string | null | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/eleosnavigationtasks/companies/${company}/drivers/${driverCode}`,
        });
        return response.data
            ? (<IEleosNavigationTaskDto>response.data).locationDescription
            : null;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
