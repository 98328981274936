import FbaApiError from '@/types/FbaApiError';
import axios from '@/libraries/axios/axiosConfig';
import apiErrorHandler from '@/libraries/apiErrorHandler';
import apmTransactions from '@/libraries/apm/transactions';
import ITrailerQrDto from '@/types/ApiDtos/ITrailerQrDto';
import Trailer from '@/types/Trailer';

export default async function getTrailerByQrId(
    id: string
): Promise<Trailer | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/getByQrId/${id}?dryVansOnly=true`,
        });
        if (response?.data?.trailerId) {
            const { data } = response;
            const trailer = Trailer.mapFromITrailerQrDto(data as ITrailerQrDto);
            trailer.trailerQr = id;
            return trailer;
        } else {
            const { data } = response;

            if (data?.isFound === false) {
                const qrId = id;

                apmTransactions.instrumentTrailerQrNotFound(
                    data.reasonCode,
                    data.message,
                    qrId,
                    'trailerQrId'
                );
            }

            return new FbaApiError(data.message, data.reasonCode);
        }
    } catch (error) {
        return apiErrorHandler(error);
    }
}
