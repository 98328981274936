import { store } from '@/store';
import { computed } from 'vue';
import { usePermission } from '@vueuse/core';
const locationPermission = usePermission('geolocation');

export default function useLocationPermission() {
    const isLocationPermissionGranted = computed(
        (): boolean => locationPermission.value === 'granted'
    );

    const shouldAlertOnLocationPermission = (): boolean => {
        const { isGeoLocationShared } = store.getters.getDeviceInfo;
        return !isLocationPermissionGranted.value && !isGeoLocationShared;
    };

    return {
        shouldAlertOnLocationPermission,
    };
}
