import apiErrorHandler from '@/libraries/apiErrorHandler';
import FbaApiError from '@/types/FbaApiError';
import axios from 'axios';
import { DateTime } from 'luxon';

type Response = { isSuccessful: false } | { isSuccessful: true; pta: string };

export default async function updateDriverPta(
    driverId: string,
    pta: DateTime
): Promise<Response | FbaApiError> {
    try {
        const response = await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_EXT_API_URL}api/drivers/updatePta`,
            data: {
                driverId: driverId,
                projectedAvailabilityOn: pta.toISO(),
            },
        });

        return response.data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
