import { DateTime } from 'luxon';
import Geopoint from './Geopoint';
import DeviceLocationWatcher from './LocationWatcher';

export default class DeviceInfo {
    deviceLocationWatcher: DeviceLocationWatcher | undefined;

    watchedDeviceLocationUpdateTime = undefined as DateTime | undefined;
    watchedDeviceLocationGeopoint = undefined as Geopoint | undefined;
    isGeoLocationShared = undefined as boolean | undefined;
    isGeoLocationUnsharedWarningDismissed = false;

    watchDeviceLocation(
        onSuccess: PositionCallback,
        onError: PositionErrorCallback,
        positionOptions?: PositionOptions,
        interval?: number
    ): DeviceLocationWatcher {
        this.stopWatchingDeviceLocation();

        this.deviceLocationWatcher = new DeviceLocationWatcher(
            onSuccess,
            onError,
            positionOptions,
            interval
        );
        return this.deviceLocationWatcher;
    }

    stopWatchingDeviceLocation() {
        if (this.deviceLocationWatcher != undefined)
            this.deviceLocationWatcher.stopWatching();

        this.deviceLocationWatcher = undefined;
    }
}
