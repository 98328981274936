import { DateTime } from 'luxon';
import LoadDto from './ApiDtos/ILoadDto';
import { DropoffEvent } from './DropoffEvent';

export default class Load {
    constructor(
        public readonly id: string,
        public readonly destination: LoadDestination
    ) {}

    static mapFromLoadDto({ id, destination }: LoadDto) {
        const earliestArrivalTime = undefinedOrFromIso(
            destination.earliestArrivalTime
        );
        const latestArrivalTime = undefinedOrFromIso(
            destination.latestArrivalTime
        );

        return new Load(id, {
            ...destination,
            earliestArrivalTime,
            latestArrivalTime,
        });
    }
}

function undefinedOrFromIso(isoFormattedDate?: string) {
    const dateTime = DateTime.fromISO(isoFormattedDate || '');

    if (dateTime.isValid) {
        return dateTime;
    }
}

interface LoadDestination {
    earliestArrivalTime?: DateTime;
    latestArrivalTime?: DateTime;
    eventType: DropoffEvent;
}
