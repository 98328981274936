import Address from './Address';
import INearbyTrailerDto from './ApiDtos/INearbyTrailerDto';
import INearbyTrailerResultDto from './ApiDtos/INearbyTrailerResultDto';
import IReservedTrailerDto from './ApiDtos/IReservedTrailerDto';
import Geopoint from './Geopoint';
import LocationPoolInfo from './LocationPoolInfo';
import { NearbyTrailerResultType } from './NearbyTrailerResultType';
import RouteSummary from './RouteSummary';
import Trailer from './Trailer';

export default class NearbyTrailerResult {
    distance: number;
    location: Geopoint;
    locationAddress: Address | undefined;
    locationName: string;
    customerId: string;
    customerCode: string;
    locationId: string;
    trailers: Array<Trailer>;
    locationPoolInfo: LocationPoolInfo | undefined;
    type: NearbyTrailerResultType;
    route: RouteSummary | undefined;
    geofenceIds: string[];

    constructor(
        distance: number,
        latitude: number,
        locationAddress: Address | undefined,
        locationName: string,
        customerId: string,
        customerCode: string,
        locationId: string,
        longitude: number,
        trailers: Array<Trailer>,
        locationPoolInfo: LocationPoolInfo | undefined,
        type: NearbyTrailerResultType,
        geofenceIds: string[]
    ) {
        this.distance = distance;
        this.location = new Geopoint(latitude, longitude);
        this.locationAddress = locationAddress;
        this.locationName = locationName;
        this.customerId = customerId;
        this.customerCode = customerCode;
        this.locationId = locationId;
        this.trailers = trailers;
        this.locationPoolInfo = locationPoolInfo;
        this.type = type;
        this.route = undefined;
        this.geofenceIds = geofenceIds;
    }

    static mapFromINearbyTrailerResultDto(
        dto: INearbyTrailerResultDto
    ): NearbyTrailerResult {
        return new NearbyTrailerResult(
            dto.distance,
            dto.latitude,
            dto.locationAddress,
            dto.locationName,
            dto.customerId,
            dto.customerCode,
            dto.locationId,
            dto.longitude,
            dto.trailers?.map((trailer: INearbyTrailerDto) =>
                Trailer.mapFromINearbyTrailerDto(trailer)
            ) ?? [],
            LocationPoolInfo.mapFromINearbyTrailerDto(dto),
            dto.type,
            dto.geofenceIds
        );
    }

    static mapFromIReservedTrailerDto(
        dto: IReservedTrailerDto
    ): NearbyTrailerResult {
        return new NearbyTrailerResult(
            dto.distance,
            dto.latitude,
            dto.locationAddress,
            dto.locationName,
            '',
            '',
            dto.locationId,
            dto.longitude,
            [Trailer.mapFromIReservedTrailerDto(dto)],
            undefined,
            dto.locationName
                ? NearbyTrailerResultType.customerLocation
                : NearbyTrailerResultType.standaloneTrailer,
            []
        );
    }
}
