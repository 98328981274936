import queries from '@/queries';
import { AttachabilityResponseDto } from '@/queries/getAssociabilityEvaluation';
import FbaApiError from '@/types/FbaApiError';
import { defineComponent } from 'vue';
import { AssociationType } from '@/types/AssociationType';
import { isNullOrUndefined } from '@/shared/helpers';

interface availabilityCheckOptions {
    allowPendingAvailable: boolean;
}

interface Data {
    attachability: AttachabilityResponseDto | null;
}

interface AssociabilityOptions {
    trailerNumber: string;
    trailerCompany: string;
    scannedQrId?: string;
    driverId: string;
}

export default defineComponent({
    data(): Data {
        return { attachability: null };
    },
    computed: {
        trailerViolatesProximityEvaluation(this: Data) {
            const proximity = this.attachability?.attachabilityEvaluations.find(
                (e) => e.name === 'trailer_proximity'
            );

            return !!proximity && proximity.result !== 'pass';
        },
        trailerAndDriverAreLoaded(this: Data) {
            const loaded = this.attachability?.attachabilityEvaluations.find(
                (evaluation) => evaluation.name === 'loaded_status'
            );
            return !!loaded && loaded.result === 'fail';
        },
    },
    methods: {
        async checkTrailerAttachability(dto: AssociabilityOptions) {
            const trailerIdentifier = !isNullOrUndefined(dto.scannedQrId)
                ? { scannedQrId: dto.scannedQrId }
                : { compositeId: `${dto.trailerNumber}|${dto.trailerCompany}` };

            const response = await queries.getAssociabilityEvaluation(
                AssociationType.ATTACH,
                {
                    trailer: trailerIdentifier,
                    driver: { compositeId: dto.driverId },
                }
            );

            if (response instanceof FbaApiError) {
                return false;
            }

            if (Array.isArray(response)) {
                return false;
            }

            this.attachability = response;

            return response.canAttach;
        },
    },
});
