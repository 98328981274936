export enum TrailerAssessmentQuestion {
    PREVIOUS_DEFECTS_REPORTED = 'Were previous defects reported?',
    PREVIOUS_DEFECTS_PERSIST = 'Are previous defects still present?',
    DOT_SAFE = 'Is the trailer still DOT compliant and safe to perform the load with?',
    WILL_TAKE_POSSESSION = 'Please confirm you will take possession despite damage.',
    WILL_CONTACT_BREAKDOWN = 'Will you take possession and contact breakdown immediately?',
}

export enum TrailerAssessmentAnswer {
    YES = 'Yes',
    NO = 'No',
}

export type TrailerAssessmentQuestions =
    | TrailerAssessmentQuestion.PREVIOUS_DEFECTS_REPORTED
    | TrailerAssessmentQuestion.PREVIOUS_DEFECTS_PERSIST
    | TrailerAssessmentQuestion.DOT_SAFE
    | TrailerAssessmentQuestion.WILL_TAKE_POSSESSION
    | TrailerAssessmentQuestion.WILL_CONTACT_BREAKDOWN;

export type TrailerAssessmentAnswers =
    | TrailerAssessmentAnswer.YES
    | TrailerAssessmentAnswer.NO;
