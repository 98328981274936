import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import { isNullOrUndefined } from '@/shared/helpers';
import INearbyTrailerDto from '@/types/ApiDtos/INearbyTrailerDto';
import FbaApiError from '@/types/FbaApiError';
import Trailer from '@/types/Trailer';

export default async function getAttachedTrailersByTruckId(
    truckNumber: string,
    truckCompany: string
): Promise<Array<Trailer> | null | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/getAttachedTrailerByAssetTruckId/${truckNumber}/${truckCompany}`,
        });

        const { data } = response;
        if (!isNullOrUndefined(data?.trailers) && data.trailers.length > 0)
            return data.trailers.map((trailer: INearbyTrailerDto) =>
                Trailer.mapFromINearbyTrailerDto(trailer)
            );
        else return null;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
