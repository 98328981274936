import globalConstants from '@/shared/globalConstants';
import TrailerAssessmentType from './TrailerAssessmentType';
import TrailerAssessmentResponseDto from './ApiDtos/ITrailerAssessmentResponseDto';

export default class TrailerAssessment {
    brakes: TrailerAssessmentType;
    tires: TrailerAssessmentType;
    lights: TrailerAssessmentType;
    damage: TrailerAssessmentType;
    kingpinLanding: TrailerAssessmentType;
    airlinesElectrical: TrailerAssessmentType;
    other: TrailerAssessmentType;

    constructor(
        brakes?: TrailerAssessmentType,
        tires?: TrailerAssessmentType,
        lights?: TrailerAssessmentType,
        damage?: TrailerAssessmentType,
        kingpinLanding?: TrailerAssessmentType,
        airlinesElectrical?: TrailerAssessmentType,
        other?: TrailerAssessmentType
    ) {
        this.brakes =
            brakes ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .brakes as keyof TrailerAssessment
            );
        this.tires =
            tires ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .tires as keyof TrailerAssessment
            );
        this.lights =
            lights ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .lights as keyof TrailerAssessment
            );
        this.damage =
            damage ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .damage as keyof TrailerAssessment
            );
        this.kingpinLanding =
            kingpinLanding ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .kingpinLanding as keyof TrailerAssessment
            );
        this.airlinesElectrical =
            airlinesElectrical ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .airlinesElectrical as keyof TrailerAssessment
            );
        this.other =
            other ||
            new TrailerAssessmentType(
                globalConstants.trailer.assessmentTypes
                    .other as keyof TrailerAssessment
            );
    }

    static mapFromTrailerAssessmentResponseDto(
        dto: TrailerAssessmentResponseDto
    ) {
        const assessment = new TrailerAssessment(
            dto.types.find((at) => at.type == 'brakes'),
            dto.types.find((at) => at.type == 'tires'),
            dto.types.find((at) => at.type == 'lights'),
            dto.types.find((at) => at.type == 'damage'),
            dto.types.find((at) => at.type == 'kingpinLanding'),
            dto.types.find((at) => at.type == 'airlinesElectrical'),
            dto.types.find((at) => at.type == 'other')
        );
        return assessment;
    }
}
