import globalConstants from '@/shared/globalConstants';

export default class RouteSummary {
    baseDuration: number;
    duration: number;
    length: number; //distance in meters.

    lengthInMiles() {
        return this.length / globalConstants.distanceConversions.metersPerMile;
    }

    constructor(baseDuration: number, duration: number, length: number) {
        (this.baseDuration = baseDuration),
            (this.duration = duration),
            (this.length = length);
    }
}
