import BaseMapPoint from './BaseMapPoint';
import Geopoint from './Geopoint';
import NearbyTrailerResult from './NearbyTrailerResult';

export default class TrailerMapPoint implements BaseMapPoint {
    location: Geopoint;
    icon: HTMLElement;
    data: NearbyTrailerResult;

    constructor(location: Geopoint, data: NearbyTrailerResult) {
        this.location = location;
        this.data = data;
        this.icon = this.getIcon();
    }

    getIcon(): HTMLElement {
        const element = document.createElement('div');
        element.classList.add('trailer-icon');
        element.classList.add('map-icon');
        if (this.data.trailers[0].reservation?.isActive) {
            element.classList.add('reserved-trailer-icon');
            element.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
                              <g id="available_icon" data-name="available icon" transform="translate(-1112 -591)">
                                <g id="bg" transform="translate(1114 593)" fill="#fff">
                                  <path d="M 10.5 29.00000190734863 C 9.741069793701172 29.00000190734863 9.030289649963379 28.62775230407715 8.598679542541504 28.00423240661621 C 7.182929992675781 25.95181274414062 5.991399765014648 24.25436210632324 4.940130233764648 22.75673294067383 C 0.1404100060462952 15.91906261444092 -1 14.2944221496582 -1 10.49999237060547 C -1 7.42822265625 0.1962099969387054 4.540322303771973 2.368259906768799 2.368262529373169 C 4.540319919586182 0.1962124705314636 7.428229808807373 -0.9999974966049194 10.5 -0.9999974966049194 C 13.57176971435547 -0.9999974966049194 16.45968055725098 0.1962124705314636 18.63174057006836 2.368262529373169 C 20.80378913879395 4.540322303771973 22 7.42822265625 22 10.49999237060547 C 22 14.2944221496582 20.85959053039551 15.91906261444092 16.05986976623535 22.75673294067383 C 15.00860023498535 24.25436210632324 13.81707000732422 25.95181274414062 12.40225028991699 28.00288200378418 C 11.96969032287598 28.62776184082031 11.25891971588135 29.00000190734863 10.5 29.00000190734863 Z" stroke="none"/>
                                  <path d="M 10.50000190734863 28.0000057220459 C 10.90914249420166 28.0000057220459 11.31840229034424 27.81166076660156 11.5790901184082 27.43507194519043 C 19.52507972717285 15.91576290130615 21 14.73352241516113 21 10.49999237060547 C 21 4.700992584228516 16.29900932312012 2.477722091498435e-06 10.5 2.477722091498435e-06 C 4.700990200042725 2.477722091498435e-06 0 4.700992584228516 0 10.49999237060547 C 0 14.73352241516113 1.474920034408569 15.91576290130615 9.420909881591797 27.43507194519043 C 9.681636810302734 27.81172752380371 10.09076023101807 28.0000057220459 10.50000190734863 28.0000057220459 M 10.5 30.00000190734863 C 9.412960052490234 30.00000190734863 8.394809722900391 29.4666919708252 7.776450157165527 28.57339286804199 C 6.362090110778809 26.52299308776855 5.171820163726807 24.82732200622559 4.121650218963623 23.33126258850098 C 1.690019965171814 19.86716270446777 0.2222999930381775 17.77625274658203 -0.6844199895858765 16.01758193969727 C -1.631150007247925 14.18131256103516 -2 12.63432216644287 -2 10.49999237060547 C -2 7.161112308502197 -0.6997799873352051 4.022092342376709 1.661159992218018 1.661162495613098 C 4.022089958190918 -0.6997775435447693 7.161119937896729 -1.999997496604919 10.5 -1.999997496604919 C 13.83887958526611 -1.999997496604919 16.97790908813477 -0.6997775435447693 19.33884048461914 1.661162495613098 C 21.69977951049805 4.022092342376709 23 7.161112308502197 23 10.49999237060547 C 23 12.63432216644287 22.63114929199219 14.18131256103516 21.68441963195801 16.01758193969727 C 20.77770042419434 17.77625274658203 19.30998039245605 19.86716270446777 16.87834930419922 23.33126258850098 C 15.82818031311035 24.82732200622559 14.63790988922119 26.52299308776855 13.22541046142578 28.57069206237793 C 12.60515975952148 29.46670341491699 11.58701992034912 30.00000190734863 10.5 30.00000190734863 Z" stroke="none" fill="#fff"/>
                                </g>
                                <path id="icon" d="M17908.5,22325a1.287,1.287,0,0,1-1.076-.566c-1.434-2.078-2.67-3.838-3.662-5.25-4.77-6.789-5.762-8.2-5.762-11.686a10.5,10.5,0,0,1,21,0c0,3.48-.992,4.891-5.752,11.67-1.049,1.5-2.236,3.189-3.668,5.266A1.3,1.3,0,0,1,17908.5,22325Z" transform="translate(-16784 -21704)" fill="#719A2D"/>
                                <path id="watch-solid" d="M9.619,6.195a4.81,4.81,0,1,1-4.81-4.81A4.811,4.811,0,0,1,9.619,6.195Zm-5.411-2v2a.538.538,0,0,0,.268.478l1.2.8a.552.552,0,0,0,.812-.145A.58.58,0,0,0,6.345,6.5l-.934-.621V4.191a.605.605,0,0,0-.624-.6.615.615,0,0,0-.6.6Z" transform="translate(1119.689 596.99)" fill="#fff"/>
                              </g>
                            </svg>`;
            return element;
        } else {
            element.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
                              <g id="Component_355_1" data-name="Component 355 – 1" transform="translate(2 2)">
                                <g id="bg" fill="#fff">
                                  <path d="M 10.02246570587158 27.65740776062012 C 9.239107131958008 27.65740776062012 8.50525951385498 27.30415534973145 8.008476257324219 26.6881103515625 C 7.051827907562256 25.5114860534668 4.603939056396484 22.41119194030762 2.518845796585083 19.01814842224121 C 0.1839057058095932 15.21853828430176 -1.000004291534424 12.19194793701172 -1.000004291534424 10.02246856689453 C -1.000004291534424 7.07822847366333 0.1465257108211517 4.31022834777832 2.228375673294067 2.228378295898438 C 4.310235500335693 0.1465283185243607 7.078225612640381 -1.000001668930054 10.02246570587158 -1.000001668930054 C 16.10027503967285 -1.000001668930054 21.04493522644043 3.944658279418945 21.04493522644043 10.02246856689453 C 21.04493522644043 12.18497848510742 19.85484504699707 15.21195793151855 17.50772476196289 19.01934814453125 C 15.6755952835083 21.99132919311523 13.52355575561523 24.82708740234375 12.04084587097168 26.68265914916992 C 11.54216575622559 27.30296897888184 10.80716609954834 27.65740776062012 10.02246570587158 27.65740776062012 Z" stroke="none"/>
                                  <path d="M 10.02246570587158 26.65741157531738 C 10.48052310943604 26.65741157531738 10.93858051300049 26.45774269104004 11.25961589813232 26.05840873718262 C 13.93748569488525 22.7071475982666 20.04493522644043 14.58477878570557 20.04493522644043 10.02246856689453 C 20.04493522644043 4.487138271331787 15.55570602416992 -1.677551267675881e-06 10.02246570587158 -1.677551267675881e-06 C 4.487145900726318 -1.677551267675881e-06 -4.294128302717581e-06 4.487138271331787 -4.294128302717581e-06 10.02246856689453 C -4.294128302717581e-06 14.58477878570557 6.060455799102783 22.7071475982666 8.78531551361084 26.05840873718262 C 9.106350898742676 26.45774269104004 9.564408302307129 26.65741157531738 10.02246570587158 26.65741157531738 M 10.02246570587158 28.65740776062012 C 8.93485164642334 28.65740776062012 7.917212009429932 28.16834449768066 7.229629993438721 27.31533432006836 C 6.258337020874023 26.12052345275879 3.780903100967407 22.98185348510742 1.666855692863464 19.54170799255371 C -0.8005642890930176 15.52650833129883 -2.000004291534424 12.41273784637451 -2.000004291534424 10.02246856689453 C -2.000004291534424 6.811118125915527 -0.7494543194770813 3.791998386383057 1.521275758743286 1.521268367767334 C 3.792005777359009 -0.7494516968727112 6.811115741729736 -2.000001668930054 10.02246570587158 -2.000001668930054 C 13.232985496521 -2.000001668930054 16.25178527832031 -0.7494916915893555 18.52277565002441 1.521168351173401 C 20.79407501220703 3.792138338088989 22.04493522644043 6.811298370361328 22.04493522644043 10.02246856689453 C 22.04493522644043 12.40617847442627 20.8392448425293 15.52072811126709 18.35897636413574 19.54410743713379 C 16.50041580200195 22.55896759033203 14.32213592529297 25.42961883544922 12.82206535339355 27.30690765380859 L 12.81838607788086 27.31149864196777 C 12.13077545166016 28.16684913635254 11.11169528961182 28.65740776062012 10.02246570587158 28.65740776062012 Z" stroke="none" fill="#fff"/>
                                </g>
                                <path id="location-dot-solid" d="M8.785,26.058C6.06,22.707,0,14.585,0,10.022a10.022,10.022,0,0,1,20.045,0c0,4.562-6.107,12.685-8.785,16.036a1.577,1.577,0,0,1-2.474,0Zm1.237-12.7a3.341,3.341,0,1,0-3.341-3.341A3.344,3.344,0,0,0,10.022,13.363Z" fill="#052991"/>
                              </g>
                            </svg>`;
            return element;
        }
    }
}
