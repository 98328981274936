import queries from '@/queries';
import FbaApiError from '@/types/FbaApiError';
import { defineComponent } from 'vue';
import { AssociationType } from '@/types/AssociationType';
import { isNullOrUndefined } from '@/shared/helpers';
import { mapGetters } from 'vuex';

interface Data {
    available: boolean;
}

export default defineComponent({
    data(): Data {
        return { available: false };
    },
    computed: {
        ...mapGetters({
            trailer: 'selectedTrailer',
            driver: 'getDriver',
        }),
    },
    methods: {
        async checkTrailerAvailability() {
            const trailerIdentifier = !isNullOrUndefined(
                this.trailer?.trailerQr
            )
                ? { scannedQrId: this.trailer?.trailerQr }
                : {
                      compositeId: `${this.trailer?.trailerNumber}|${this.trailer?.company}`,
                  };

            const response = await queries.getAssociabilityEvaluation(
                AssociationType.ATTACH,
                {
                    trailer: trailerIdentifier,
                    driver: { compositeId: this.driver.id },
                }
            );

            if (response instanceof FbaApiError) {
                return false;
            }

            if (Array.isArray(response)) {
                return false;
            }

            this.available = response.canAttach;

            return response.canAttach;
        },
    },
});
