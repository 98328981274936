import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import globalConstants from '@/shared/globalConstants';
import { isNullOrUndefined } from '@/shared/helpers';
import FbaApiError from '@/types/FbaApiError';

export default async function postDeactivateTrailerAssessment(
    assessmentId: string,
    source = globalConstants.trailer.trailerAssessment.updateAssessmentSource
): Promise<boolean | null | FbaApiError> {
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/deactivateTrailerAssessment`,
            data: {
                assessmentId: assessmentId,
                source: source,
            },
        });

        const { data } = response;
        if (!isNullOrUndefined(data?.isSuccessful)) return data.isSuccessful;
        else return null;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
