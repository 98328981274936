import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import { isNullOrUndefined } from '@/shared/helpers';
import { IEleosAuthenticationResponseDto } from '@/types/ApiDtos/EleosAuthenticationResponseDto';
import EleosAuthenticationResponse from '@/types/EleosAuthenticationResponse';
import FbaApiError from '@/types/FbaApiError';

export default async function authorizeDriverCookie(
    company: string | undefined
): Promise<EleosAuthenticationResponse | null | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/eleosauthentication/companies/${company}`,
            withCredentials: true,
        });
        if (!isNullOrUndefined(response?.data)) {
            return EleosAuthenticationResponse.mapFromEleosAuthenticationResponseDto(
                response.data as IEleosAuthenticationResponseDto
            );
        } else {
            return null;
        }
    } catch (error: any) {
        const handledError = await apiErrorHandler(error);
        handledError.unauthorizedResponseTypes =
            error?.response?.data?.responseType;

        return handledError;
    }
}
