import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import INearbyTrailerResultDto from '@/types/ApiDtos/INearbyTrailerResultDto';
import FbaApiError from '@/types/FbaApiError';
import Geopoint from '@/types/Geopoint';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';

export default async function getTrailersAtCustomer(
    company: string,
    customerCode: string,
    reportedLocation: Geopoint
): Promise<Array<NearbyTrailerResult> | FbaApiError> {
    try {
        const response = await axios({
            maxBodyLength: Infinity,
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/availableatcustomer/${company}/${customerCode}?reportedLat=${reportedLocation.lat}&reportedLon=${reportedLocation.lng}`,
        });
        return response.data.map((dto: INearbyTrailerResultDto) =>
            NearbyTrailerResult.mapFromINearbyTrailerResultDto(dto)
        );
    } catch (error) {
        return apiErrorHandler(error);
    }
}
