import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import {
    DriverIdentifierDto,
    LoadIdentifierDto,
    LocationDto,
    TrailerIdentifierDto,
    TruckIdentifierDto,
    WithOptionalLocation,
} from '@/types/ApiDtos';
import FbaApiError from '@/types/FbaApiError';
import { AssociationType } from '@/types/AssociationType';
import apmTransactions from '@/libraries/apm/transactions';
import Trailer from '@/types/Trailer';
import TrailerReservation from '@/types/TrailerReservation';
import { TrailerSelectionMethod } from '@/types/TrailerSelectionMethod';
import { GenerateGuid } from '@/shared/helpers';

type DriverDto = WithOptionalLocation<DriverIdentifierDto>;
type TrailerDto = WithOptionalLocation<TrailerIdentifierDto>;

interface TrailerAssociationTelemetryInfo {
    attachingTrailer: Trailer | undefined;
    attachingTrailerLocationId?: string | undefined;
    attachingTrailerSelectionMethod: TrailerSelectionMethod | undefined;
    trailerReservation?: TrailerReservation | undefined;
    reservedTrailer?: Trailer | undefined;
}

interface DriverTrailerAssociationDto {
    trailer: TrailerDto;
    truck: TruckIdentifierDto;
    driver: DriverDto;
    load?: LoadIdentifierDto;
    telemetryInfo?: TrailerAssociationTelemetryInfo;
}

export default interface DriverTrailerAssociationResponseDto {
    isSuccessful: boolean;
    reasonCode: number;
}

async function postAssociation(
    association: AssociationType,
    dto: DriverTrailerAssociationDto
): Promise<DriverTrailerAssociationResponseDto | FbaApiError> {
    try {
        const eventId = GenerateGuid();
        const response = await axios({
            method: 'post',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailerAssociations/${association}`,
            data: {
                eventId: eventId ?? '',
                truckId: dto.truck.compositeId,
                ...(dto.load || {}),
                ...trailerToParams(dto.trailer),
                ...driverToParams(dto.driver),
            },
        });

        if (response?.data?.isSuccessful) {
            logTrailerAssociation(
                eventId ?? '',
                association,
                dto.trailer,
                dto.telemetryInfo
            );
        }

        return response.data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

export const postAttach = postAssociation.bind(null, AssociationType.ATTACH);
export const postDetach = postAssociation.bind(null, AssociationType.DETACH);

interface TrailerParams {
    scannedId?: string;
    trailerId?: string;
}

interface DriverParams {
    driver: {
        assetDriverId?: string;
        mobileNumber?: string;
        reportedLocation?: LocationDto;
        driverName?: string;
    };
}

function trailerToParams(
    trailer: DriverTrailerAssociationDto['trailer']
): TrailerParams {
    if ('scannedQrId' in trailer) {
        return { scannedId: trailer.scannedQrId };
    }

    return { trailerId: trailer.compositeId };
}

function driverToParams(
    driver: DriverTrailerAssociationDto['driver']
): DriverParams {
    const params: DriverParams['driver'] = {
        reportedLocation: driver.location,
        driverName: driver.name,
    };

    if ('compositeId' in driver) {
        params.assetDriverId = driver.compositeId;
    } else {
        params.mobileNumber = driver.mobileNumber;
    }

    return { driver: params };
}

function logTrailerAssociation(
    eventId: string,
    association: string,
    trailerDto: TrailerDto,
    telemetryInfo?: TrailerAssociationTelemetryInfo
) {
    const trailerMapped = trailerToParams(trailerDto);
    const trailerIdentifier =
        trailerMapped?.trailerId || trailerMapped?.scannedId;

    apmTransactions.instrumentTrailerAssociation(
        eventId,
        trailerIdentifier,
        Object.keys(trailerMapped)[0],
        association,
        telemetryInfo?.attachingTrailerLocationId,
        telemetryInfo?.attachingTrailer?.location,
        telemetryInfo?.attachingTrailerSelectionMethod,
        telemetryInfo?.trailerReservation?.trailerLocationId,
        telemetryInfo?.trailerReservation?.trailerId,
        telemetryInfo?.trailerReservation?.trailerLocation, //The reserved trailer's location when reserved.
        telemetryInfo?.reservedTrailer?.location //The reserved trailer's current location.
    );
}
