import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw,
} from 'vue-router';
import AssociateTrailerSuccess from '@/views/AssociateTrailerSuccess.vue';
import DamageReported from '@/views/DamageReported.vue';
import BodyDamage from '../views/TrailerAssessment/BodyDamage.vue';
import BrakeDamage from '../views/TrailerAssessment/BrakeDamage.vue';
import KingpinDamage from '../views/TrailerAssessment/KingpinDamage.vue';
import DamageComments from '../views/TrailerAssessment/DamageComments.vue';
import DriverLanding from '@/views/DriverLanding/DriverLanding.vue';
import DetachTrailerSuccess from '@/views/DetachTrailerSuccess.vue';
import Unauthorized from '@/views/DriverLanding/Unauthorized.vue';
import EleosDriverContainer from '@/views/EleosDriverContainer.vue';
import ElectricalDamage from '../views/TrailerAssessment/ElectricalDamage.vue';
import AirlinesElectricalDamage from '../views/TrailerAssessment/AirlinesElectricalDamage.vue';
import SelectTrailer from '../views/SelectTrailer/SelectTrailer.vue';
import EnterTrailer from '../views/EnterTrailer/EnterTrailer.vue';
import EnterTrailerRedirect from '../views/EnterTrailer/EnterTrailerRedirect.vue';
import FindTrailer from '../views/FindTrailer/FindTrailer.vue';
import TrailerAssessment from '../views/TrailerAssessment/TrailerAssessment.vue';
import TireDamage from '../views/TrailerAssessment/TireDamage.vue';
import ConfirmPta from '@/views/ConfirmPta/ConfirmPta.vue';
import UpdatePta from '@/views/UpdatePta/UpdatePta.vue';
import globalConstants from '@/shared/globalConstants';
import { ActionTypes } from '@/store/actionTypes';
import { store } from '@/store';
import apmTransactions from '@/libraries/apm/transactions';
import EleosAuthenticationResponse from '@/types/EleosAuthenticationResponse';
import TrailerUnavailable from '@/views/TrailerUnavailable.vue';
import {
    canAccessProtectedRoute,
    isAuthorizationEnabled,
    isPublicRoute,
    driverAndCompanyFromQueryParams,
    getAuthorizeDriverResponseAndUpdateAlertBanner,
} from '@/router/helpers';
import FbaApiError from '@/types/FbaApiError';
import { WorkflowTypes } from '@/types/WorkflowTypes';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/pwaEntry',
        name: 'PWA Entry',
        beforeEnter: async () => {
            const authorizationResponse = await store.dispatch(
                ActionTypes.AUTHORIZE_DRIVER,
                undefined
            );

            if (authorizationResponse instanceof EleosAuthenticationResponse) {
                router.push({
                    name: 'DriverLanding',
                    params: {
                        company: authorizationResponse.company,
                        driverCode: authorizationResponse.username,
                    },
                });
            } else {
                const query =
                    authorizationResponse instanceof FbaApiError
                        ? {
                              type: authorizationResponse?.unauthorizedResponseTypes,
                          }
                        : undefined;
                router.push({
                    name: 'Unauthorized',
                    query,
                });
            }
        },
        component: Unauthorized,
    },
    {
        path: '/company/:company/driver/:driverCode',
        name: 'Driver',
        component: EleosDriverContainer,
        props: (route) => ({
            company: route.params.company,
            driverCode: route.params.driverCode,
        }),
        children: [
            {
                path: '',
                name: 'DriverLanding',
                component: DriverLanding,
                beforeEnter: () => {
                    store.dispatch(ActionTypes.SET_WORKFLOW_ORIGIN, undefined);
                },
            },
            {
                path: 'trailerUnavailable',
                name: 'TrailerUnavailable',
                component: TrailerUnavailable,
            },
            {
                path: 'findtrailer',
                name: 'FindTrailer',
                component: FindTrailer,
                props: (route) => ({
                    latitude: route.query.lat,
                    longitude: route.query.lng,
                }),
                beforeEnter: () => {
                    store.dispatch(
                        ActionTypes.SET_WORKFLOW_ORIGIN,
                        WorkflowTypes.findATrailer
                    );
                },
            },
            {
                path: 'selecttrailer',
                name: 'SelectTrailer',
                component: SelectTrailer,
                beforeEnter: () => {
                    store.dispatch(
                        ActionTypes.SET_WORKFLOW_ORIGIN,
                        WorkflowTypes.selectATrailer
                    );
                },
            },
            {
                path: 'entertrailer',
                name: 'EnterTrailer',
                component: EnterTrailer,
            },
            {
                path: 'entertrailerredirect',
                name: 'EnterTrailerRedirect',
                component: EnterTrailerRedirect,
            },
            {
                path: 'associatetrailersuccess',
                name: 'AssociateTrailerSuccess',
                component: AssociateTrailerSuccess,
                props: (route) => ({
                    trailerCompany: route.query.trailerCompany,
                    trailerNumber: route.query.trailerNumber,
                    hasBodyDamage: route.query.hasBodyDamage,
                    association: route.query.association,
                }),
            },
            {
                path: 'detachtrailersuccess',
                name: 'DetachTrailerSuccess',
                component: DetachTrailerSuccess,
                props: (route) => ({
                    trailerNumber: route.query.trailerNumber,
                }),
            },
            {
                path: 'attachTrailerRedTag',
                name: 'AttachTrailerRedTag',
                component: DamageReported,
            },
            {
                path: 'trailerNotFitForDuty',
                name: 'TrailerNotFit',
                component: DamageReported,
            },
            {
                path: 'trailer/:trailerNumber/trailerassessment',
                component: TrailerAssessment,
                name: 'TrailerAssessment',
                props: (route) => ({
                    trailerNumber: route.params.trailerNumber,
                    company: route.params.company,
                    association: route.query.association,
                }),
            },
            {
                path: 'trailer/:trailerNumber/electricaldamage',
                name: 'ElectricalDamage',
                component: ElectricalDamage,
            },
            {
                path: 'trailer/:trailerNumber/bodydamage',
                name: 'BodyDamage',
                component: BodyDamage,
            },
            {
                path: 'trailer/:trailerNumber/brakedamage',
                name: 'BrakeDamage',
                component: BrakeDamage,
            },
            {
                path: 'trailer/:trailerNumber/tiredamage',
                name: 'TireDamage',
                component: TireDamage,
            },
            {
                path: 'trailer/:trailerNumber/kingpindamage',
                name: 'KingpinDamage',
                component: KingpinDamage,
            },
            {
                path: 'trailer/:trailerNumber/airlineselectricaldamage',
                name: 'AirlinesElectricalDamage',
                component: AirlinesElectricalDamage,
            },
            {
                path: 'trailer/:trailerNumber/otherAssessment/:subType',
                name: 'OtherAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes.other,
                },
            },
            {
                path: 'trailer/:trailerNumber/airlinesAssessment/:subType',
                name: 'AirlinesAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes
                            .airlinesElectrical,
                },
            },
            {
                path: 'trailer/:trailerNumber/kingpinAssessment/:subType',
                name: 'KingpinAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes.kingpinLanding,
                },
            },
            {
                path: 'trailer/:trailerNumber/brakeAssessment/:subType',
                name: 'BrakeAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes.brakes,
                },
            },
            {
                path: 'trailer/:trailerNumber/damageAssessment/:subType',
                name: 'BodyDamageAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes.damage,
                },
            },
            {
                path: 'trailer/:trailerNumber/electricalAssessment/:subType',
                name: 'ElectricalAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes.lights,
                },
            },
            {
                path: 'trailer/:trailerNumber/tiresAssessment/:subType',
                name: 'TiresAssessment',
                component: DamageComments,
                props: {
                    assessmentType:
                        globalConstants.trailer.assessmentTypes.tires,
                },
            },
        ],
    },
    {
        // TODO AFBAPP-1837: Update Confirm PTA flow to work within standard TS route assumptions /company/<company>/driver/<driver>/<route>
        path: '/confirmPta',
        name: 'ConfirmPTA',
        component: ConfirmPta,
        props: driverAndCompanyFromQueryParams,
    },
    {
        // TODO AFBAPP-1837: ensure this fits within our standard route assumptions
        path: '/updatePta',
        name: 'UpdatePTA',
        component: UpdatePta,
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized,
        props: (route: RouteLocationNormalized) => ({
            company: route?.redirectedFrom?.params?.company,
        }),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

if (isAuthorizationEnabled()) {
    router.beforeEach(
        async (
            to: RouteLocationNormalized,
            from: RouteLocationNormalized,
            next: NavigationGuardNext
        ) => {
            if (isPublicRoute(to)) {
                next();
                return;
            }

            const authorizationResponse =
                await getAuthorizeDriverResponseAndUpdateAlertBanner(
                    to.params?.company as string | undefined
                );

            if (
                authorizationResponse instanceof EleosAuthenticationResponse &&
                canAccessProtectedRoute(to)
            ) {
                next();
            } else {
                apmTransactions.instrumentUnauthorized(
                    to.params?.driverCode as string,
                    to.params?.company as string
                );

                const query =
                    authorizationResponse instanceof FbaApiError
                        ? {
                              type: authorizationResponse?.unauthorizedResponseTypes,
                          }
                        : undefined;

                next({
                    name: 'Unauthorized',
                    query,
                });
            }
        }
    );
}
export default router;
