import FbaApiError from '../types/FbaApiError';
import apmTransactions from '@/libraries/apm/transactions';
import globalConstants from '@/shared/globalConstants';
import { store } from '../store';
import { ActionTypes } from '@/store/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function (axiosError: any): Promise<FbaApiError> {
    if (axiosError.response) {
        //Got response from API but it was an error
        const apiError = new FbaApiError(
            axiosError.response.data?.message,
            axiosError.response.data?.reasonCode,
            axiosError.response.status
        );
        return apiError;
    } else if (axiosError.request) {
        //Made a request but didn't get a response
        console.error("Can't Reach Api");
        apmTransactions.instrumentApiTimeout();
        store.dispatch(ActionTypes.SET_NETWORK_TIMEOUT, true);
        return new FbaApiError(
            'No Response From API',
            undefined,
            globalConstants.httpStatusCodes.timeout
        );
    } else {
        //Something else
        console.error('Axios Error', axiosError.message);
        apmTransactions.instrumentUnhandledApiResponse();
        return new FbaApiError();
    }
}
