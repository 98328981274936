import apiErrorHandler from '@/libraries/apiErrorHandler';
import DriverFeedback from '@/types/DriverFeedback';
import FbaApiError from '@/types/FbaApiError';
import axios from 'axios';

type Response = { isSuccessful: false } | { isSuccessful: true };

export default async function postDriverFeedback(
    feedbackData: DriverFeedback
): Promise<Response | FbaApiError> {
    try {
        const response = await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_EXT_API_URL}api/drivers/submitFeedback`,
            data: feedbackData,
        });

        return response.data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
