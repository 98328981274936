import TrailerAssessment from './TrailerAssessment';

export default class TrailerAssessmentSubType {
    type: keyof TrailerAssessment;
    subType: string;
    comment: string | undefined;

    constructor(type: keyof TrailerAssessment, subType: string) {
        this.type = type;
        this.subType = subType;
        this.comment = undefined;
    }
}
