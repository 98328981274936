import { DateTime, Duration } from 'luxon';
import Geopoint from './Geopoint';

export default class TrailerReservation {
    expiresAt: DateTime;
    isActive: boolean;
    trailerId: string;
    driverId?: string;
    trailerLocation?: Geopoint;
    trailerLocationId?: string;

    constructor(
        expiresAt: string,
        trailerId: string,
        driverId?: string,
        trailerLocation?: Geopoint,
        trailerLocationId?: string
    ) {
        this.expiresAt = DateTime.fromISO(expiresAt);
        this.trailerId = trailerId;
        this.driverId = driverId;
        this.trailerLocation = trailerLocation;
        this.trailerLocationId = trailerLocationId;

        this.isActive = this.expiresAt > DateTime.now();
    }

    timeRemaining(): Duration {
        return this.expiresAt.diff(DateTime.now());
    }
}
