import INearbyTrailerResultDto from './ApiDtos/INearbyTrailerResultDto';

export default class LocationPoolInfo {
    IsVerified: boolean;
    HasPool: boolean;
    IsOverPool: boolean;
    HasAvailablePlusTrailers: boolean;

    constructor(
        isVerified: boolean,
        hasPool: boolean,
        isOverPool: boolean,
        hasAvailablePlusTrailers: boolean
    ) {
        this.IsVerified = isVerified;
        this.HasPool = hasPool;
        this.IsOverPool = isOverPool;
        this.HasAvailablePlusTrailers = hasAvailablePlusTrailers;
    }

    static mapFromINearbyTrailerDto(dto: INearbyTrailerResultDto) {
        if (dto?.locationPoolInfo == undefined) return undefined;

        return new LocationPoolInfo(
            dto.locationPoolInfo.isVerified,
            dto.locationPoolInfo.hasPool,
            dto.locationPoolInfo.isOverPool,
            dto.locationPoolInfo.hasAvailablePlusTrailers
        );
    }
}
