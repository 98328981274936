import Customer from '@/types/Customer';
import Driver from '@/types/Driver';
import FbaApiError from '@/types/FbaApiError';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';
import Truck from '@/types/Truck';
import User from '@/types/User';
import Load from '@/types/Load';
import { GetterTree } from 'vuex';
import { State } from './state';
import { cloneDeep } from 'lodash';
import TrailerAssessment from '@/types/TrailerAssessment';
import TrailerAssessmentMeta from '@/types/TrailerAssessmentMeta';
import Trailer from '@/types/Trailer';
import TrailerReservation from '@/types/TrailerReservation';
import DeviceInfo from '@/types/DeviceInfo';
import DeviceCameraInfo from '@/types/DeviceCameraInfo';
import Geopoint from '@/types/Geopoint';
import { WorkflowTypes } from '@/types/WorkflowTypes';
import globalConstants from '@/shared/globalConstants';
import { isNullOrUndefined } from '@/shared/helpers';
import { TrailerSelectionMethod } from '@/types/TrailerSelectionMethod';

export type Getters = {
    getCompany(state: State): string | undefined;
    getCurrentNavigationDestination(state: State): string | null | undefined;
    getCurrentTrailerReservation(state: State): TrailerReservation | undefined;
    getCustomerAtCurrentLocation(state: State): Customer | null | undefined;
    getDriver(state: State): Driver | undefined;
    getActiveLoad(state: State): Load | null | undefined;
    getDriverCode(state: State): string | undefined;
    getNearbyTrailerResults(state: State): NearbyTrailerResult[] | undefined;
    getTrailersAtCurrentLocation(
        state: State
    ): NearbyTrailerResult[] | undefined;
    getNearbyTrailerResultsDeniedResponses(
        state: State
    ): NearbyTrailerResult[] | undefined;
    getTruck(state: State): Truck | undefined;
    getUser(state: State): User | undefined;
    getError(state: State): FbaApiError | undefined;
    getDeviceInfo(state: State): DeviceInfo;
    getDeviceCameraInfo(state: State): DeviceCameraInfo;
    getExcludeCustomer(state: State): string | null | undefined;
    trailerAssessmentMeta(state: State): TrailerAssessmentMeta | undefined;
    trailerAssessment(state: State): TrailerAssessment | undefined;
    selectedTrailer(state: State): Trailer | undefined;
    networkTimeout(state: State): boolean;
    trailerAssociationsEnabled(state: State): boolean | null | undefined;
    driverDeviceLocationFeatureEnabled(
        state: State
    ): boolean | null | undefined;
    getPinnedBestAvailableLocation(state: State): Geopoint | undefined;
    getPermissionsStepsGuideShown(state: State): boolean;
    getBannerAlertMaintenanceDisplayed(state: State): boolean | undefined;
    getWorkflowOrigin: (state: State) => WorkflowTypes | undefined;
    isDetachError: (state: State) => boolean | undefined;
    getTrailerSelectionMethod(state: State): TrailerSelectionMethod | undefined;
};

export const getters: GetterTree<State, State> & Getters = {
    getCurrentTrailerReservation: (state) => {
        return cloneDeep(state.currentTrailerReservation) as
            | TrailerReservation
            | undefined;
    },
    getCompany: (state) => {
        return state.truck?.company;
    },
    getCurrentNavigationDestination: (state) => {
        return state.currentNavigationDestination;
    },
    getCustomerAtCurrentLocation: (state) => {
        return cloneDeep(state.customerAtCurrentLocation) as
            | Customer
            | null
            | undefined;
    },
    getDriver: (state) => {
        return cloneDeep(state.driver) as Driver | undefined;
    },
    getDriverCode: (state) => {
        return state.driver?.driverCode;
    },
    getActiveLoad: (state) => {
        return state.activeLoad;
    },
    getNearbyTrailerResults: (state) => {
        return cloneDeep(state.nearbyTrailerResults) as
            | NearbyTrailerResult[]
            | undefined;
    },
    getTrailersAtCurrentLocation: (state) => {
        return cloneDeep(state.trailersAtCurrentLocation) as
            | NearbyTrailerResult[]
            | undefined;
    },
    showLocationBeggingModal: (state) => {
        return state.locationBeggingModalShown;
    },
    getNearbyTrailerResultsDeniedResponses: (state) => {
        return cloneDeep(state.nearbyTrailerResultsDeniedResponses) as
            | NearbyTrailerResult[]
            | undefined;
    },
    getTruck: (state) => {
        return cloneDeep(state.truck) as Truck | undefined;
    },
    getUser: (state) => {
        return cloneDeep(state.user) as User | undefined;
    },
    getError: (state) => {
        return cloneDeep(state.error) as FbaApiError | undefined;
    },
    getDeviceInfo: (state) => {
        return state.deviceInfo !== undefined
            ? cloneDeep(state.deviceInfo)
            : new DeviceInfo();
    },
    getDeviceCameraInfo: (state) => {
        return state.deviceCameraInfo;
    },
    trailerAssessmentMeta: (state) => {
        return cloneDeep(state.trailerAssessmentMeta) as
            | TrailerAssessmentMeta
            | undefined;
    },
    trailerAssessment: (state) => {
        return cloneDeep(state.trailerAssessment) as
            | TrailerAssessment
            | undefined;
    },
    selectedTrailer: (state) => {
        return cloneDeep(state.selectedTrailer) as Trailer | undefined;
    },
    networkTimeout: (state) => {
        return state.networkTimeout;
    },
    getExcludeCustomer: (state) => {
        return state.excludeCustomer;
    },
    isLoading: (state) => {
        return state.isLoading;
    },
    lastActiveTrailerAssessmentResult: (state) => {
        return state.lastActiveTrailerAssessmentResult;
    },
    trailerAssociationsEnabled: (state) => {
        return (
            state.isRunningInExternalBrowser &&
            state.featureFlags?.trailerAssociationsEnabled
        );
    },
    driverDeviceLocationFeatureEnabled: (state) => {
        return state.isRunningInExternalBrowser;
    },
    getActiveBannerAlert: (state) => {
        return state.bannerAlertMessages[0];
    },
    hasActiveBannerAlerts: (state) => {
        return state.bannerAlertMessages.length > 0;
    },
    getBannerAlertMaintenanceDisplayed: (state) => {
        return state.bannerAlertMaintenanceDisplayed;
    },
    getPinnedBestAvailableLocation: (state) => {
        return state.pinnedBestAvailableLocation;
    },
    getPermissionsStepsGuideShown: (state) => {
        return state.permissionsStepsGuideShown;
    },
    getWorkflowOrigin: (state) => {
        return state.workflowOrigin;
    },
    isDetachError: (state) => {
        const {
            trailerFailedElasticUpsert,
            loadFailedElasticUpsert,
            trailerNotDetachable,
        } = globalConstants.reasonCodes;
        return (
            !isNullOrUndefined(state.error?.message) &&
            (state.error?.reasonCode === trailerNotDetachable ||
                state.error?.reasonCode === loadFailedElasticUpsert ||
                state.error?.reasonCode === trailerFailedElasticUpsert)
        );
    },
    getTrailerSelectionMethod: (state: State) => {
        return state.trailerSelectionMethod;
    },
};
