/* eslint-disable @typescript-eslint/no-explicit-any */
import { apmBase } from '@elastic/apm-rum';
import { afterFrame } from '@elastic/apm-rum-core';
import router from '@/router';
import { Router } from 'vue-router';

const { name } = require('../../../package.json'); // eslint-disable-line

const routeHooks = function (router: Router, apm: any) {
    let transaction: any;

    router.beforeEach((to, from, next) => {
        const matched = to.matched || [];
        let path = to.path;
        if (matched.length > 0) {
            path = matched[matched.length - 1].path || path;
        }
        transaction = apm.startTransaction(path, 'route-change', {
            managed: true,
            canReuse: true,
        });
        next();
    });

    router.afterEach(() => {
        afterFrame(() => transaction && transaction.detectFinish());
    });
    router.onError(() => {
        transaction && transaction.end();
    });
};

const getErrorHandler = (app: any, apm: any) => {
    const previousErrorHandler = app.config.errorHandler;

    return (error: any, vm: any, info: any) => {
        if (vm && vm.$options) {
            const options = vm.$options;
            let component;
            if (vm.$root === vm) {
                component = 'Root';
            } else {
                component =
                    options.name || options._componentTag || 'Anonymous';
            }

            error.component = component;
            error.file = options.__file || '';
        }

        if (info) {
            error.lifecycleHook = info;
        }

        apm.captureError(error);

        if (typeof previousErrorHandler === 'function') {
            previousErrorHandler.call(this, error, vm, info);
        }
        console.error(error);
    };
};

const apmConfig = {
    router,
    config: {
        serviceName: name,
        serverUrl: process.env.VUE_APP_APM_SERVER_URL,
        environment: process.env.VUE_APP_APM_ENV,
        distributedTracingOrigins: [
            process.env.VUE_APP_TACLITE_ROOT_URL,
            'http://localhost:5001',
            'https://localhost:5001',
            'https://tac-api-dev01.freightbox.com',
            'https://tac-api.freightbox.com',
            'https://tac-api-alpha.freightbox.com',
            'https://eleos-driver-frontend.dev.aks.freightbox.com',
            'https://eleos-driver-frontend.test.aks.freightbox.com',
        ],
        logLevel: process.env.VUE_APP_APM_LOG_LEVEL,
        active: process.env.VUE_APP_APM_ACTIVE,
        propagateTracestate: true,
    },
    captureErrors: true,
};

export const apm = apmBase.init(apmConfig.config);

export const ApmVuePlugin = {
    install: (app: any) => {
        const majorVersion = app.version.split('.').shift();

        if (apm.isActive()) {
            if (router && process.env.NODE_ENV !== 'test') {
                routeHooks(router, apm);
            }

            if (apmConfig.captureErrors) {
                app.config.errorHandler = getErrorHandler(app, apm);
            }
        }
        if (majorVersion >= 3) {
            app.config.globalProperties.$apm = apm;
            app.provide('$apm', apm);
        } /** Backward compatibility with Vue 2 */ else
            app.prototype.$apm = apm;
    },
};
