/* eslint-disable @typescript-eslint/no-explicit-any */
/// <reference types="@here/maps-api-for-javascript" />
import globalConstants from '@/shared/globalConstants';
import BaseMapPoint from '@/types/BaseMapPoint';
import { ClusterLayer } from '@/types/ClusterLayer';
import Geopoint from '@/types/Geopoint';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';

export default class HereMapsMap {
    private clusteringOptions: H.clustering.Provider.ClusteringOptions;
    private clusterLayer: ClusterLayer;
    private behavior: H.mapevents.Behavior;
    private infoBubble: H.ui.InfoBubble | undefined;
    private infoBubblePosition?: Geopoint = undefined;
    private fitToMapView?: boolean = false;
    private platform: H.service.Platform;
    private map: H.Map;
    private ui: H.ui.UI;
    private clusterTapListeners: Array<(event: Event) => void> = [];
    private resizeTimeoutFnId: number | null = null;

    constructor(
        apiKey: string,
        container: HTMLElement,
        center: Geopoint,
        zoom = 12,
        radius: number | undefined,
        shouldFitMapView: boolean
    ) {
        this.platform = new H.service.Platform({ apikey: apiKey });
        const layers = this.platform.createDefaultLayers({ pois: true });
        this.map = new H.Map(container, (layers as any).vector.normal.map, {
            center,
            zoom,
        });
        this.fitToMapView = shouldFitMapView;

        if (radius) this.setView(center, radius);

        const poisConfig =
            `${process.env.VUE_APP_ELEOS_DRIVER_ROOT_URL}` +
            'normal.day.pois.yaml';
        const style = new H.map.Style(
            poisConfig,
            'https://js.api.here.com/v3/3.1/styles/omv/miami/'
        );
        this.map.getBaseLayer()?.getProvider().setStyleInternal(style);

        const satelliteService = this.platform.getRasterTileService({
            queryParams: {
                lang: 'en',
                ppi: '100',
                size: '256',
                style: 'explore.satellite.day',
            },
        });

        const satelliteProvider = new H.service.rasterTile.Provider(
            satelliteService
        );
        (layers as any).raster.satellite.map = new H.map.layer.TileLayer(
            satelliteProvider
        );

        const trafficFlowConfig =
            `${process.env.VUE_APP_ELEOS_DRIVER_ROOT_URL}` +
            'vector.normal.traffic_flow.style.yaml';
        const flowStyle = new H.map.render.webgl.Style(trafficFlowConfig);
        const trafficConditionsService =
            this.platform.getTrafficVectorTileService({
                layer: 'flow',
            });

        const trafficConditionsProvider =
            new H.service.trafficVectorTile.Provider(
                trafficConditionsService,
                flowStyle
            );
        (layers as any).vector.normal.traffic = new H.map.layer.TileLayer(
            trafficConditionsProvider
        );

        this.ui = H.ui.UI.createDefault(this.map, layers);
        const control = new H.ui.Control();
        control.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);
        this.ui.addControl('control', control);
        this.ui
            .getControl('zoom')
            ?.setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM);

        this.behavior = new H.mapevents.Behavior(
            new H.mapevents.MapEvents(this.map)
        );

        this.setMapPadding();

        this.clusteringOptions = {
            eps: 32, // maximum radius of the neighbourhood
            minWeight: -1, // minimum weight of points required to form a cluster
            strategy: H.clustering.Provider.Strategy.GRID,
        };
        this.clusterLayer = {
            obj: null,
            points: [],
            rendered: false,
            theme: null,
            dataProvider: null,
        };

        window.addEventListener('resize', this.resizeListener.bind(this));

        const satellite = this.ui.getControl('mapsettings');
        satellite?.setAlignment(H.ui.LayoutAlignment.TOP_RIGHT);
        const satelliteElement = satellite?.getElement();
        if (satelliteElement) {
            for (let i = 0; i < satelliteElement.children.length ?? 0; i++) {
                const child = satelliteElement.children[i] as HTMLElement;
                if (child.className.includes('H_btn')) {
                    child.style.width = '52px';
                    child.style.height = '52px';
                    child.style.margin = '-1.4em -0.9em 0px 0px';
                }
            }
        }

        this.ui.getControl('zoom')?.setVisibility(false);
    }

    addClusterTapListener(listener: (event: Event) => void): HereMapsMap {
        this.clusterTapListeners.push(listener);
        return this;
    }

    addInfoBubble(position: Geopoint, content: string): void {
        if (
            position.lat !== this.infoBubblePosition?.lat &&
            position.lng !== this.infoBubblePosition?.lng
        ) {
            if (this.infoBubble) {
                this.ui.removeBubble(this.infoBubble);
            }
            // offset tooltip height so it doesn't cover markers
            const { x, y } =
                this.map.geoToScreen(position) ?? new H.math.Point(0, 0);
            this.infoBubble = new H.ui.InfoBubble(
                this.map.screenToGeo(x, y - 20) ?? new H.geo.Point(0, 0),
                { content }
            );
            this.infoBubblePosition = position;
            this.ui.addBubble(this.infoBubble);
        }
    }

    highlightSelectedPin(res: NearbyTrailerResult) {
        const pins = document.getElementsByClassName('map-icon');

        // add/remvove highlighted class if pin is already in view
        for (let i = 0; i < pins.length; i++) {
            if (pins[i].classList.contains('truck-icon')) continue;

            pins[i].dispatchEvent(
                new CustomEvent(
                    globalConstants.customEventTypes.HIGHLIGHT_POINT,
                    { detail: res }
                )
            );
        }

        // update cluster points and classes so they are properly added to the document when brought into view
        this.clusterLayer?.points?.forEach((point: any) => {
            if (
                !point.data?.icon?.classList?.contains('truck-icon') &&
                point?.data?.data?.trailers[0]?.id === res?.trailers[0]?.id
            ) {
                point.data.icon.classList.add('highlight-icon-marker');
            } else if (
                point.data?.icon?.classList?.contains('highlight-icon-marker')
            ) {
                point.data.icon.classList.remove('highlight-icon-marker');
            }
        });

        this.clusterLayer.dataProvider?.setDataPoints(this.clusterLayer.points);
    }

    addPoint(...points: BaseMapPoint[]): HereMapsMap {
        points.forEach((p) => {
            this.clusterLayer.points.push(
                new H.clustering.DataPoint(
                    p?.location?.lat || 0,
                    p?.location?.lng || 0,
                    undefined,
                    p
                )
            );
        });

        if (this.fitToMapView) this.fitViewToPoints();
        return this;
    }

    addTheme(theme: any): HereMapsMap {
        this.clusterLayer.theme = theme;
        return this;
    }

    clearPoints(): HereMapsMap {
        // remove layer obj
        if (this.clusterLayer.rendered) {
            try {
                // remove listeners
                this.clusterLayer.dataProvider?.removeEventListener(
                    'tap',
                    this.clusterTapListener.bind(this)
                );
            } catch (e) {
                // do nothing, listeners probably didn't exist to begin with
            }

            try {
                // remove layer
                if (this.clusterLayer.obj)
                    this.map.removeLayer(this.clusterLayer.obj);
            } catch (e) {
                // do nothing
            }
        }

        // remove points
        this.clusterLayer.points = [];
        this.clusterLayer.obj = null;

        this.clusterLayer.rendered = false;

        return this;
    }

    destroy(): void {
        window.removeEventListener('resize', this.resizeListener.bind(this));
    }

    removeClusterTapListener(listener: (event: Event) => void): HereMapsMap {
        const index = this.clusterTapListeners.findIndex(
            (cb) => cb === listener
        );
        if (index >= 0) {
            this.clusterTapListeners.splice(index, 1);
        }

        return this;
    }

    removeInfoBubble(): void {
        if (this.infoBubble) {
            this.ui.removeBubble(this.infoBubble);
            this.infoBubblePosition = undefined;
        }
    }

    render(): HereMapsMap {
        if (this.clusterLayer.rendered && this.clusterLayer.obj) {
            // clear currently rendered layer
            this.map.removeLayer(this.clusterLayer.obj);
        }

        const clusteredDataProvider = this.clusterLayer.theme
            ? this.clusterLayer.theme(this.clusterLayer.points, {
                  clusteringOptions: this.clusteringOptions,
              })
            : new H.clustering.Provider(this.clusterLayer.points, {
                  clusteringOptions: this.clusteringOptions,
              });

        if (
            clusteredDataProvider &&
            !(clusteredDataProvider instanceof H.clustering.Provider)
        ) {
            throw new Error(
                'layer theme must be a function that returns a cluster provider object'
            );
        }

        // add events
        clusteredDataProvider.addEventListener(
            'tap',
            this.clusterTapListener.bind(this)
        );

        // save provider for later
        this.clusterLayer.dataProvider = clusteredDataProvider;

        // render clusters
        this.clusterLayer.obj = new H.map.layer.ObjectLayer(
            clusteredDataProvider
        );

        // Add layer with a z-index of 2 so that clusters won't be obscured by search markers
        this.map.addLayer(this.clusterLayer.obj, 2);

        this.clusterLayer.rendered = true;

        return this;
    }

    setInfoBubbleContent(content: string): void {
        if (this.infoBubble) {
            this.infoBubble.setContent(content);
        }
    }

    setPadding(
        top: number,
        right: number,
        bottom: number,
        left: number
    ): HereMapsMap {
        this.map.getViewPort().setPadding(top, right, bottom, left);
        return this;
    }

    setMapPadding() {
        if (window.screen.width > 576) {
            this.setPadding(55, 100, 75, 510);
        } else {
            this.setPadding(55, 55, 500, 40);
        }
    }

    setView(newCenter: Geopoint, radius?: number, animate = false): void {
        if (radius !== undefined) {
            const mapBounds = this.getBoundsFromCenterAndRadius(
                newCenter,
                radius
            );
            this.map.getViewModel().setLookAtData(
                {
                    position: newCenter,
                    bounds: mapBounds,
                },
                animate
            );
        } else {
            this.map.getViewModel().setLookAtData(
                {
                    position: newCenter,
                },
                animate
            );
        }
    }

    fitViewToPoints(animate = false): void {
        if (this.fitToMapView) {
            const lngs: number[] = [];
            const lats: number[] = [];

            if (this.clusterLayer.points.length > 1) {
                this.clusterLayer.points.forEach((point: any) => {
                    lngs.push(point?.lng), lats.push(point?.lat);
                });

                const box = new H.geo.Rect(
                    Math.max(...lats),
                    Math.min(...lngs),
                    Math.min(...lats),
                    Math.max(...lngs)
                );

                this.map.getViewModel().setLookAtData(
                    {
                        bounds: box.getBoundingBox() ?? undefined,
                    },
                    animate
                );
            }
        }
    }

    screenToGeo(x: number, y: number): H.geo.Point | null {
        return this.map.screenToGeo(x, y);
    }

    private clusterTapListener(event: Event): void {
        const ref = (<any>event.target).getData();
        this.clusterTapListeners.forEach((cb) => {
            cb(ref);
        });
        event.stopPropagation();
    }

    private getBoundsFromCenterAndRadius(
        center: Geopoint,
        radius: number
    ): H.geo.Rect {
        const EARTH_RADIUS = 3959;
        const radians = (a: number) => (a / 180) * Math.PI;
        const degrees = (a: number) => 180 * (a / Math.PI);
        const latLngOnBearing = (
            start: Geopoint,
            distance: number,
            bearing: number
        ) => {
            const latRads = radians(start.lat);
            const lngRads = radians(start.lng);
            const bearingRads = radians(bearing);
            const lat = Math.asin(
                Math.sin(latRads) * Math.cos(distance / EARTH_RADIUS) +
                    Math.cos(latRads) *
                        Math.sin(distance / EARTH_RADIUS) *
                        Math.cos(bearingRads)
            );
            const lng =
                lngRads +
                Math.atan2(
                    Math.sin(bearingRads) *
                        Math.sin(distance / EARTH_RADIUS) *
                        Math.cos(latRads),
                    Math.cos(distance / EARTH_RADIUS) -
                        Math.sin(latRads) * Math.sin(lat)
                );
            return { lat: degrees(lat), lng: degrees(lng) };
        };
        const top = latLngOnBearing(center, radius, 0);
        const bottom = latLngOnBearing(center, radius, 180);
        const left = latLngOnBearing(center, radius, 270);
        const right = latLngOnBearing(center, radius, 90);
        return new H.geo.Rect(top.lat, left.lng, bottom.lat, right.lng);
    }

    private resizeListener() {
        if (this.resizeTimeoutFnId !== null) {
            clearTimeout(this.resizeTimeoutFnId);
        }
        // only resize after user is done resizing
        this.resizeTimeoutFnId = setTimeout(() => {
            this.map.getViewPort().resize();
            this.setMapPadding();
            if (this.fitToMapView) this.fitViewToPoints();
        }, 200);
    }
}
