import { DateTime } from 'luxon';
import IGeopointDto from '@/types/ApiDtos/IGeopointDto';
import { ITrailerAssessmentQuestionAndAnswer } from './ITrailerAssessmentQuestionAndAnswer';
import { TrailerAssessmentOutcome } from './TrailerAssessmentOutcome';

export default class TrailerAssessmentMeta {
    startTime: DateTime | undefined;
    location: IGeopointDto | undefined;
    questionAnswers: ITrailerAssessmentQuestionAndAnswer[] | undefined;
    outcome: TrailerAssessmentOutcome | undefined;
}
