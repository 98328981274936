import { hashCode } from '@/shared/helpers';

/**
 * Enum for the types of BannerAlerts we may have. Used for ordering banners and removal of categories of banners, usually one at a time.
 * The current implementation puts these
 */
export enum BannerAlertType {
    DEFAULT,
    SUCCESS,
    ERROR,
    WARNING,
    PERMISSION_BEG,
    PERSISTENT_ALERT,
}

/**
 * Enum for the types of icons a BannerAlert may have
 */
export enum BannerAlertIconType {
    CLOCK,
    EXCLAMATION,
    CHECK,
    WRENCH,
    CAMERA,
    LOCATION,
    CAMERA_AND_LOCATION,
}

/**
 * Represents a side of the trailer on which a QR code can be found
 */
export enum SelectedQRSide {
    FRONT = 0,
    BACK = 1,
    DRIVER = 2,
    PASSENGER = 3,
}

export class LocationBeggingModalOptions {
    private modalShown: boolean;

    constructor(options: { modalShown: boolean }) {
        this.modalShown = options.modalShown;
    }

    isModalShown(): boolean {
        return this.modalShown;
    }
}

/**
 * Represents a message to be displayed on an overhead banner modal.
 * @class
 * @param {string} baseMessage The base message template. Use {0}, {1}, etc to address variables by index in the [variables] array.
 * @param {Array<string>} variables An array of strings to be used in [baseMessage]. {0} is replaced by variables[0], {1} by variables[1], and so on.
 *
 * baseMessage can contain HTML formatting, just be sure to use v-html in components that display messages with HTML in them.
 */
export class BannerAlert {
    private baseMessageTitle: string;
    private baseMessage: string;
    private duration: number;
    private iconType: BannerAlertIconType;
    private messageHash: number;
    private type: BannerAlertType;
    private variables: Array<string>;

    constructor(alert: {
        type: BannerAlertType;
        baseMessageTitle: string;
        baseMessage: string;
        duration?: number;
        iconType: BannerAlertIconType;
        variables?: Array<string>;
    }) {
        this.type = alert.type ?? BannerAlertType.DEFAULT;
        this.baseMessageTitle = alert.baseMessageTitle;
        this.baseMessage = alert.baseMessage;
        this.duration = alert.duration ?? 0;
        this.iconType = alert.iconType;
        this.variables = alert.variables ?? [];
        this.messageHash = hashCode(
            this.getFormattedMessageTitle() + this.getFormattedMessage()
        );
    }

    getAlertType(): BannerAlertType {
        return this.type;
    }

    getDuration(): number {
        return this.duration;
    }

    formatMessageString(message: string): string {
        const messageArray = message.split(' ');
        let assembledMessage = '';

        messageArray.forEach((token) => {
            const tokenInsides = token.match(/\{(.*?)\}/);
            if (tokenInsides !== null) {
                const index = parseInt(tokenInsides[1]);
                const item = this.variables[index] as string;
                assembledMessage += token.replace(tokenInsides[0], item);
            } else assembledMessage += token;

            assembledMessage += ' ';
        });

        return assembledMessage.substring(0, assembledMessage.length - 1);
    }

    getFormattedMessageTitle(): string {
        return this.formatMessageString(this.baseMessageTitle);
    }

    getFormattedMessage(): string {
        return this.formatMessageString(this.baseMessage);
    }

    getIconType(): BannerAlertIconType {
        return this.iconType;
    }

    getMessageId(): number {
        return this.messageHash;
    }

    getPriority(): number {
        switch (this.type) {
            //It's assumed that Success banners are usually on timers, and will dismiss automatically on a delay. Prioritize them.
            case BannerAlertType.SUCCESS:
                return 6;
            case BannerAlertType.ERROR:
                return 5;
            case BannerAlertType.PERSISTENT_ALERT:
                return 4;
            case BannerAlertType.PERMISSION_BEG:
                return 3;
            case BannerAlertType.WARNING:
                return 2;
            case BannerAlertType.DEFAULT:
                return 1;
            default:
                return 0;
        }
    }
}

/**
 * Holds some data to be passed to the banner modal
 * @class
 * @param {number} minutes How long it has been since the last telemetry ping in minutes
 * @param {string} city The last city the telemetry ping was found in
 * @param {string} state The last state the telemetry ping was found in
 */
export class OutdatedLocationBannerData {
    private minutes: number;
    private city: string;
    private state: string;

    constructor(minutes: number, city: string, state: string) {
        this.minutes = minutes;
        this.city = city;
        this.state = state;
    }

    getMinutes(): number {
        return this.minutes;
    }

    getHours(): number {
        return (this.minutes - (this.minutes % 60)) / 60;
    }

    getCity(): string {
        return this.city;
    }

    getState(): string {
        return this.state;
    }

    shouldUseMinutes(): boolean {
        return this.minutes < 60;
    }
}

export function getPermissionAlert(iconType: BannerAlertIconType): BannerAlert {
    switch (iconType) {
        case BannerAlertIconType.CAMERA_AND_LOCATION:
            return new BannerAlert({
                type: BannerAlertType.PERMISSION_BEG,
                baseMessageTitle:
                    'Camera and Location access aren’t enabled on this device. ',
                baseMessage: '',
                iconType: BannerAlertIconType.CAMERA_AND_LOCATION,
            });
        case BannerAlertIconType.CAMERA:
            return new BannerAlert({
                type: BannerAlertType.PERMISSION_BEG,
                baseMessageTitle:
                    'Camera access isn’t enabled on this device. ',
                baseMessage:
                    'To access QR scanning, please enable camera in settings.',
                iconType: BannerAlertIconType.CAMERA,
            });
        default:
            return new BannerAlert({
                type: BannerAlertType.PERMISSION_BEG,
                baseMessageTitle:
                    'Location sharing isn’t enabled on this device. ',
                baseMessage:
                    'Although your truck location is active it is not as accurate.',
                iconType: BannerAlertIconType.LOCATION,
            });
    }
}
