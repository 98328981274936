import {
    AuthorizationTypes,
    IEleosAuthenticationResponseDto,
    UnauthorizedResponseTypes,
} from './ApiDtos/EleosAuthenticationResponseDto';

export default class EleosAuthenticationResponse {
    authorizationType: AuthorizationTypes;
    company: string;
    errorMessage: string;
    username: string;
    responseType: UnauthorizedResponseTypes;

    constructor(
        username: string,
        company: string,
        responseType: UnauthorizedResponseTypes,
        errorMessage: string,
        authorizationType: AuthorizationTypes
    ) {
        this.authorizationType = authorizationType;
        this.username = username;
        this.company = company;
        this.errorMessage = errorMessage;
        this.responseType = responseType;
    }

    static mapFromEleosAuthenticationResponseDto(
        dto: IEleosAuthenticationResponseDto
    ): EleosAuthenticationResponse {
        let errorMessage = '';
        errorMessage = dto?.errorMessage ?? errorMessage;
        errorMessage = dto?.error?.message ?? errorMessage;

        return new EleosAuthenticationResponse(
            dto.username,
            dto.company,
            dto.responseType,
            errorMessage,
            dto.authorizationType
        );
    }
}
