import { RouteLocationNormalized } from 'vue-router';
import { getters } from '@/store/getters';
import { state } from '@/store/state';
import { store } from '@/store';
import { ActionTypes } from '@/store/actionTypes';
import EleosAuthenticationResponse from '@/types/EleosAuthenticationResponse';
import FbaApiError from '@/types/FbaApiError';

export function isAuthorizationEnabled(): boolean {
    return !process.env.VUE_APP_TRAILER_SOLUTIONS_UNAUTHORIZED_ENVIRONMENTS?.split(
        ', '
    )?.includes(process.env.VUE_APP_ENV);
}

export function isPublicRoute(route: RouteLocationNormalized): boolean {
    return route.path === '/unauthorized' || route.path === '/pwaEntry';
}

export function canAccessProtectedRoute(
    route: RouteLocationNormalized
): boolean {
    const authorizedUser = getters.getUser(state);
    const routeUser = route?.params?.driverCode as string;
    const routeCompany = route?.params?.company as string;

    if (authorizedUser === undefined) {
        return false;
    }

    const { username, company } = authorizedUser;
    if (
        routeUser?.toUpperCase() === username.toUpperCase() &&
        routeCompany?.toUpperCase() === company.toUpperCase()
    ) {
        return true;
    } else {
        return false;
    }
}

export function driverAndCompanyFromQueryParams(
    route: RouteLocationNormalized
) {
    return {
        driverCode: route.query.driver,
        companyId: route.query.company,
    };
}

export async function getAuthorizeDriverResponseAndUpdateAlertBanner(
    company: string | undefined
) {
    const authorizationPromise = store.dispatch(ActionTypes.AUTHORIZE_DRIVER, {
        company: company,
    });

    const bannerPromise = store.dispatch(
        ActionTypes.UPDATE_PERSISTENT_ALERT_BANNER,
        undefined
    );

    const promiseResults = await Promise.allSettled([
        authorizationPromise,
        bannerPromise,
    ]);
    return (
        promiseResults[0] as PromiseFulfilledResult<
            FbaApiError | EleosAuthenticationResponse | null
        >
    ).value;
}
