export enum MutationTypes {
    INITIALIZE_STORE = 'INITIALIZE_STORE',
    SET_CURRENT_NAVIGATION_DESTINATION = 'SET_CURRENT_NAVIGATION_DESTINATION',
    SET_CUSTOMER_AT_CURRENT_LOCATION = 'SET_CUSTOMER_AT_CURRENT_LOCATION',
    SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
    SET_NEARBY_TRAILER_RESULTS = 'SET_NEARBY_TRAILER_RESULTS',
    SET_TRAILERS_AT_CURRENT_LOCATION = 'SET_TRAILERS_AT_CURRENT_LOCATION',
    SET_TRUCK = 'SET_TRUCK',
    SET_USER = 'SET_USER',
    SET_DRIVER = 'SET_DRIVER',
    SET_ACTIVE_LOAD = 'SET_ACTIVE_LOAD',
    SET_ERROR = 'SET_ERROR',
    SET_DEVICE_INFO = 'SET_DEVICE_INFO',
    SET_DEVICE_CAMERA_INFO = 'SET_DEVICE_CAMERA_INFO',
    SET_LAST_ACTIVE_TRAILER_ASSESSMENT_RESULT = 'SET_LAST_ACTIVE_TRAILER_ASSESSMENT_RESULT',
    SET_IS_RUNNING_IN_EXTERNAL_BROWSER = 'SET_IS_RUNNING_IN_EXTERNAL_BROWSER',
    UPDATE_TRAILER_ASSESSMENT = 'UPDATE_TRAILER_ASSESSMENT',
    UPDATE_TRAILER_ASSESSMENT_META = 'UPDATE_TRAILER_ASSESSMENT_META',
    CLEAR_TRAILER_ASSESSMENT = 'CLEAR_TRAILER_ASSESSMENT',
    UPDATE_SELECTED_TRAILER = 'UPDATE_SELECTED_TRAILER',
    CLEAR_SELECTED_TRAILER = 'CLEAR_SELECTED_TRAILER',
    UPDATE_TRAILER_SELECTION_METHOD = 'UPDATE_TRAILER_SELECTION_METHOD',
    CLEAR_TRAILER_SELECTION_METHOD = 'CLEAR_TRAILER_SELECTION_METHOD',
    CLEAR_ERROR = 'CLEAR_ERROR',
    SET_CURRENT_RESERVATION = 'SET_CURRENT_RESERVATION',
    SET_NETWORK_TIMEOUT = 'SET_NETWORK_TIMEOUT',
    UPDATE_DRIVER_PTA = 'UPDATE_DRIVER_PTA',
    SET_EXCLUDE_CUSTOMER = 'SET_EXCLUDE_CUSTOMER',
    SET_IS_LOADING = 'SET_IS_LOADING',
    RESET_STATE = 'RESET_STATE',
    ADD_BANNER_ALERT = 'ADD_BANNER_ALERT',
    REMOVE_BANNER_ALERT = 'REMOVE_BANNER_ALERT',
    REMOVE_BANNER_ALERTS_OF_TYPE = 'REMOVE_BANNER_ALERTS_OF_TYPE',
    CLEAR_ALL_BANNER_ALERTS = 'CLEAR_ALL_BANNER_ALERTS',
    SET_PINNED_LOCATION = 'SET_PINNED_LOCATION',
    SET_BANNER_ALERT_MAINTENANCE_DISPLAYED = 'SET_BANNER_ALERT_MAINTENANCE_DISPLAYED',
    SHOW_LOCATION_BEGGING_MODAL = 'SHOW_LOCATION_BEGGING_MODAL',
    SET_WORKFLOW_ORIGIN = 'SET_WORKFLOW_ORIGIN',
    SET_PERMISSIONS_STEPS_GUIDE_SHOWN = 'SET_PERMISSIONS_STEPS_GUIDE_SHOWN',
}
