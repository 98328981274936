import queries from '@/queries';
import { ActionTypes } from './actionTypes';
import Truck from '../types/Truck';
import { MutationTypes } from './mutationTypes';
import FbaApiError from '@/types/FbaApiError';
import { Mutations } from './mutations';
import { ActionContext, ActionTree, DispatchOptions } from 'vuex';
import { State, state } from './state';
import { Getters } from './getters';
import {
    isNullOrUndefined,
    shouldClearTypeOnSubtypeRemoval,
    getCurrentLocation,
    getReadableGeolocationPositionErrorCode,
    getDirectContactTitle,
    GenerateGuid,
} from '@/shared/helpers';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';
import { NearbyTrailerResultType } from '@/types/NearbyTrailerResultType';
import locationReverseGeocode from '@/libraries/heremaps/reverseGeocode';
import {
    cleanDuplicateTrailers,
    mergeSameCustomer,
} from '@/libraries/heremaps/utilsNearbyTrailer';
import Customer from '@/types/Customer';
import Geopoint from '@/types/Geopoint';
import Driver from '@/types/Driver';
import User from '@/types/User';
import { cloneDeep, get, set, values, pullAt, isEmpty, omit } from 'lodash';
import TrailerAssessmentType from '@/types/TrailerAssessmentType';
import TrailerAssessment from '@/types/TrailerAssessment';
import Trailer from '@/types/Trailer';
import TrailerAssessmentSubType from '@/types/TrailerAssessmentSubType';
import ITrailerAssessmentDto from '@/types/ApiDtos/ITrailerAssessmentDto';
import PostTrailerAssessmentResponse from '@/types/PostTrailerAssessmentResponse';
import TrailerReservation from '@/types/TrailerReservation';
import calculateRoute from '@/libraries/heremaps/calculateRoute';
import globalConstants from '@/shared/globalConstants';
import Address from '@/types/Address';
import IReserveTrailerResponseDto from '@/types/ApiDtos/IReserveTrailerResponseDto';
import { DateTime, Duration } from 'luxon';
import router from '@/router/index';
import { AssociationType } from '@/types/AssociationType';
import TrailerAssessmentMeta from '@/types/TrailerAssessmentMeta';
import { ITrailerAssessmentQuestionAndAnswer } from '@/types/ITrailerAssessmentQuestionAndAnswer';
import { TrailerAssessmentOutcome } from '@/types/TrailerAssessmentOutcome';
import FeatureFlags from '@/types/FeatureFlags';
import apmTransactions, {
    GeolocationInfoType,
} from '@/libraries/apm/transactions';
import {
    BannerAlert,
    BannerAlertType,
    BannerAlertIconType,
    OutdatedLocationBannerData,
    LocationBeggingModalOptions,
    getPermissionAlert,
    SelectedQRSide,
} from './types';
import DriverFeedback from '@/types/DriverFeedback';
import EleosAuthenticationResponse from '@/types/EleosAuthenticationResponse';
import PersistentAlert from '@/types/PersistentAlert';
import { AuthorizationTypes } from '@/types/ApiDtos/EleosAuthenticationResponseDto';
import { WorkflowTypes } from '@/types/WorkflowTypes';
import DriverTrailerAssociationResponseDto from '@/queries/trailerAssociations';
import RouteSummary from '@/types/RouteSummary';
import { TrailerSelectionMethod } from '@/types/TrailerSelectionMethod';

// Getting more typescript stuff into this thing: https://stackoverflow.com/questions/67605554/how-to-use-other-store-module-getters-actions-mutations-with-vuex-4-and-typescri
type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>;
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>;
    };
    dispatch<K extends keyof Actions>(
        key: K,
        payload?: Parameters<Actions[K]>[1],
        options?: DispatchOptions
    ): ReturnType<Actions[K]>;
} & Omit<ActionContext<State, State>, 'commit' | 'getters' | 'dispatch'>;

export interface Actions {
    [ActionTypes.CLEAR_TRAILER_ASSESSMENT]({
        commit,
    }: AugmentedActionContext): void;
    [ActionTypes.AUTHORIZE_DRIVER](
        { commit, getters }: AugmentedActionContext,
        payload: { company: string | undefined } | undefined
    ): Promise<EleosAuthenticationResponse | FbaApiError | null>;
    [ActionTypes.CREATE_NAVIGATION_DESTINATION](
        { commit, dispatch, getters }: AugmentedActionContext,
        payload: {
            name: string;
            city: string;
            state: string;
            zip: string;
            destination: Geopoint;
            trailerId?: string;
            minutesToReserveFor?: number;
            minutesToDestination?: number | undefined;
            milesToDestination?: number | undefined;
        }
    ): Promise<IReserveTrailerResponseDto | FbaApiError>;
    [ActionTypes.FETCH_CURRENT_NAVIGATION_DESTINATION]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void>;
    [ActionTypes.FETCH_CUSTOMER_AND_TRAILERS_AT_CURRENT_LOCATION]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void>;
    [ActionTypes.FETCH_DRIVER](
        { commit }: AugmentedActionContext,
        payload: { company: string; driverCode: string }
    ): Promise<void>;
    [ActionTypes.FETCH_FEATURE_FLAGS](
        { commit }: AugmentedActionContext,
        payload: {
            driverCode: string;
            company: string;
        }
    ): Promise<void>;
    [ActionTypes.FETCH_TRUCK](
        { commit, dispatch, getters }: AugmentedActionContext,
        payload: { company: string; id: string }
    ): Promise<void>;
    [ActionTypes.FETCH_NEARBY_TRAILER_RESULTS]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void>;
    [ActionTypes.FETCH_ACTIVE_LOAD]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void | FbaApiError>;
    [ActionTypes.REMOVE_NAVIGATION_DESTINATION]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void | FbaApiError>;
    [ActionTypes.REFRESH_TRUCK]({
        commit,
        dispatch,
        getters,
    }: AugmentedActionContext): Promise<void>;
    [ActionTypes.FETCH_LAST_ACTIVE_TRAILER_ASSESSMENT](
        { commit }: AugmentedActionContext,
        payload: { trailerNumber: string; trailerCompany: string }
    ): Promise<void>;
    [ActionTypes.BEGIN_TRAILER_ASSESSMENT](ctx: AugmentedActionContext): void;
    [ActionTypes.POST_TRAILER_ASSESSMENT](
        { commit, getters }: AugmentedActionContext,
        payload: {
            trailerNumber: string;
            trailerCompany: string;
            eventName: AssociationType;
            damageSignatureRecordedTimestamp?: string | null;
        }
    ): Promise<PostTrailerAssessmentResponse | FbaApiError>;
    [ActionTypes.CLEAR_QUERY_PARAM_AND_ROUTE](
        ctx: AugmentedActionContext,
        options: {
            routeToName: string;
            omitParam: string;
        }
    ): void;
    [ActionTypes.POST_ATTACH_DRIVER_TRAILER](
        { commit, getters }: AugmentedActionContext,
        payload: {
            trailerNumber: string;
            trailerCompany: string;
            driverLocation: Geopoint;
            trailerQrId: string | undefined;
            trailerSelectionMethod: TrailerSelectionMethod | undefined;
        }
    ): Promise<DriverTrailerAssociationResponseDto | FbaApiError | undefined>;
    [ActionTypes.POST_DETACH_DRIVER_TRAILER](
        { commit, getters }: AugmentedActionContext,
        payload: { trailerNumber: string; trailerCompany: string }
    ): Promise<DriverTrailerAssociationResponseDto | FbaApiError | undefined>;
    [ActionTypes.UPDATE_TRAILER_ASSESSMENT](
        { commit, getters }: AugmentedActionContext,
        payload: TrailerAssessmentType
    ): void;
    [ActionTypes.UPDATE_TRAILER_ASSESSMENT_QUESTION](
        { commit, getters }: AugmentedActionContext,
        payload: ITrailerAssessmentQuestionAndAnswer
    ): void;
    [ActionTypes.UPDATE_TRAILER_ASSESSMENT_OUTCOME](
        { commit, getters }: AugmentedActionContext,
        payload: TrailerAssessmentOutcome
    ): void;
    [ActionTypes.REMOVE_TRAILER_ASSESSMENT](
        { commit, getters }: AugmentedActionContext,
        payload: string
    ): void;
    [ActionTypes.SAVE_PARTIAL_TRAILER_ASSESSMENT](
        { commit, getters }: AugmentedActionContext,
        payload: TrailerAssessmentSubType
    ): void;
    [ActionTypes.REMOVE_PARTIAL_TRAILER_ASSESSMENT](
        { commit, getters }: AugmentedActionContext,
        payload: TrailerAssessmentSubType
    ): void;
    [ActionTypes.SET_SELECTED_TRAILER](
        { commit }: AugmentedActionContext,
        payload: {
            trailer: Trailer;
            trailerSelectionMethod: TrailerSelectionMethod;
        }
    ): void;
    [ActionTypes.SET_NETWORK_TIMEOUT](
        { commit }: AugmentedActionContext,
        payload: boolean
    ): void;
    [ActionTypes.CLEAR_ERROR]({ commit }: AugmentedActionContext): void;
    [ActionTypes.UPDATE_TRAILER_RESULTS_WITH_ROUTE_INFO]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void>;
    [ActionTypes.UPDATE_DRIVER_PTA](
        ctx: AugmentedActionContext,
        payload: DateTime
    ): Promise<void | FbaApiError>;
    [ActionTypes.SET_LOCATION_OUTDATED_WARNING_BANNER](
        { commit }: AugmentedActionContext,
        payload: OutdatedLocationBannerData
    ): void;
    [ActionTypes.SET_PERMISSION_DENIED_WARNING_BANNER](
        { commit }: AugmentedActionContext,
        payload: {
            alertCamera: boolean;
            alertLocation: boolean;
        }
    ): void;
    [ActionTypes.CLEAR_BANNER_ALERT](
        { commit }: AugmentedActionContext,
        payload: number
    ): void;
    [ActionTypes.SUBMIT_DRIVER_FEEDBACK](
        { dispatch }: AugmentedActionContext,
        payload: DriverFeedback
    ): Promise<boolean | void | FbaApiError>;
    [ActionTypes.REPORT_QR_UNASSOCIATED](
        { getters }: AugmentedActionContext,
        payload: {
            trailerNumber: string;
            trailerCompany: string;
            scannedQrId: string;
            selectedQRSide: SelectedQRSide;
        }
    ): Promise<boolean | void | FbaApiError>;
    [ActionTypes.HANDLE_LOCATION_ACCESS_ERROR](
        { commit, dispatch, getters }: AugmentedActionContext,
        payload: GeolocationPositionError
    ): void;
    [ActionTypes.BEGIN_WATCHING_DEVICE_LOCATION]({
        commit,
        getters,
    }: AugmentedActionContext): void;
    [ActionTypes.STOP_WATCHING_DEVICE_LOCATION]({
        getters,
    }: AugmentedActionContext): void;
    [ActionTypes.REFRESH_PINNED_LOCATION](
        { commit, getters }: AugmentedActionContext,
        payload: {
            truck: Truck | null | undefined;
            timeoutOverride?: number | undefined;
        }
    ): void;
    [ActionTypes.SET_FEEDBACK_SUCCESS_BANNER]({
        commit,
    }: AugmentedActionContext): void;
    [ActionTypes.SET_FEEDBACK_ERROR_BANNER]({
        commit,
    }: AugmentedActionContext): void;
    [ActionTypes.SHOW_LOCATION_BEGGING_MODAL](
        { commit }: AugmentedActionContext,
        payload: LocationBeggingModalOptions
    ): void;
    [ActionTypes.UPDATE_PERSISTENT_ALERT_BANNER]({
        commit,
        getters,
    }: AugmentedActionContext): Promise<void>;
    [ActionTypes.SET_WORKFLOW_ORIGIN](
        { commit }: AugmentedActionContext,
        payload: WorkflowTypes | undefined
    ): void;
    [ActionTypes.SET_DETACH_SUCCESS_BANNER]({
        commit,
    }: AugmentedActionContext): void;
    [ActionTypes.SET_DETACH_ERROR_BANNER]({
        commit,
        getters,
    }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, State> & Actions = {
    async [ActionTypes.AUTHORIZE_DRIVER](
        { commit },
        payload: { company: string | undefined } | undefined
    ): Promise<EleosAuthenticationResponse | FbaApiError | null> {
        const authorizationResult = await queries.authorizeDriverCookie(
            payload?.company
        );

        if (authorizationResult instanceof EleosAuthenticationResponse) {
            commit(
                MutationTypes.SET_USER,
                new User(
                    authorizationResult.company,
                    authorizationResult.username
                )
            );
            if (
                authorizationResult.authorizationType !==
                AuthorizationTypes.EleosToken
            ) {
                /* If the user was authorized via eleos token, that indicates that they are
                 * running in the embedded browser. Any other method indicates that they are
                 * in the external browser or PWA.
                 */
                commit(MutationTypes.SET_IS_RUNNING_IN_EXTERNAL_BROWSER, true);
            } else {
                commit(MutationTypes.SET_IS_RUNNING_IN_EXTERNAL_BROWSER, false);
            }
        } else {
            commit(MutationTypes.SET_IS_RUNNING_IN_EXTERNAL_BROWSER, false);
        }

        return authorizationResult as EleosAuthenticationResponse;
    },
    [ActionTypes.CLEAR_TRAILER_ASSESSMENT]({ commit }): void {
        commit(MutationTypes.CLEAR_TRAILER_ASSESSMENT, undefined);
    },
    async [ActionTypes.CREATE_NAVIGATION_DESTINATION](
        { commit, dispatch, getters },
        payload: {
            name: string;
            city: string;
            state: string;
            zip: string;
            destination: Geopoint;
            trailerId?: string;
            minutesToReserveFor?: number;
            minutesToDestination?: number | undefined;
            milesToDestination?: number | undefined;
        }
    ): Promise<IReserveTrailerResponseDto | FbaApiError> {
        if (
            getters.getCompany !== undefined &&
            getters.getDriverCode !== undefined &&
            getters.getTruck !== undefined
        ) {
            const removeResult = await dispatch(
                ActionTypes.REMOVE_NAVIGATION_DESTINATION
            );
            if (removeResult instanceof FbaApiError) {
                return removeResult;
            }

            const eventId = GenerateGuid();
            const createResult = await queries.createNavigationTask(
                eventId ?? '',
                getters.getCompany,
                getters.getDriverCode,
                getters.getTruck?.truckId,
                payload.name,
                payload.city,
                payload.state,
                payload.zip,
                payload.destination,
                payload.trailerId,
                payload.minutesToReserveFor,
                getters.getDriver?.id
            );
            if (createResult instanceof FbaApiError) {
                console.error(createResult as FbaApiError);
                commit(MutationTypes.SET_ERROR, createResult as FbaApiError);
                return createResult;
            } else {
                if (
                    !isNullOrUndefined(payload.trailerId) &&
                    createResult.didSucceed
                ) {
                    const reservation = new TrailerReservation(
                        createResult.expiresAt, // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        payload.trailerId!,
                        getters.getDriver?.id,
                        payload.destination,
                        getters.getCustomerAtCurrentLocation?.locationId //TDK review.
                    );

                    commit(MutationTypes.SET_CURRENT_RESERVATION, reservation);

                    apmTransactions.instrumentTrailerReservationCreation(
                        eventId ?? '',
                        reservation,
                        payload.minutesToDestination,
                        payload.milesToDestination
                    );

                    await dispatch(ActionTypes.FETCH_NEARBY_TRAILER_RESULTS);

                    return createResult;
                }
                commit(
                    MutationTypes.SET_CURRENT_NAVIGATION_DESTINATION,
                    payload.name
                );
                return createResult;
            }
        }
        return {
            didSucceed: false,
            errorMessage: 'invalid parameters',
        } as IReserveTrailerResponseDto;
    },
    async [ActionTypes.FETCH_CURRENT_NAVIGATION_DESTINATION]({
        commit,
        getters,
    }): Promise<void> {
        if (
            getters.getCompany !== undefined &&
            getters.getDriverCode !== undefined
        ) {
            const currentNavDestination =
                await queries.getCurrentNavigationDestination(
                    getters.getCompany,
                    getters.getDriverCode
                );
            if (currentNavDestination instanceof FbaApiError) {
                console.error(currentNavDestination);
            } else {
                commit(
                    MutationTypes.SET_CURRENT_NAVIGATION_DESTINATION,
                    currentNavDestination
                );
            }
        }
    },
    async [ActionTypes.FETCH_CUSTOMER_AND_TRAILERS_AT_CURRENT_LOCATION]({
        commit,
        getters,
    }): Promise<void> {
        const truck = getters.getTruck;
        if (isNullOrUndefined(truck)) {
            console.error('No truck found in store!');
        } else {
            commit(MutationTypes.SET_NEARBY_TRAILER_RESULTS, undefined);

            let reportedLocation =
                getters.getPinnedBestAvailableLocation ?? new Geopoint(0, 0);

            const customerResult = await queries.getCustomerByLocation(
                (truck as Truck).company,
                reportedLocation
            );
            if (customerResult instanceof FbaApiError) {
                console.error(customerResult as FbaApiError);
                commit(MutationTypes.SET_ERROR, customerResult as FbaApiError);
            } else {
                commit(
                    MutationTypes.SET_CUSTOMER_AT_CURRENT_LOCATION,
                    customerResult
                );
                if (customerResult instanceof Customer) {
                    reportedLocation =
                        getters.getPinnedBestAvailableLocation ??
                        new Geopoint(0, 0);

                    const trailersAtCurrentLocation =
                        await queries.getTrailersAtCustomer(
                            customerResult.company,
                            customerResult.code,
                            reportedLocation
                        );
                    if (trailersAtCurrentLocation instanceof FbaApiError) {
                        console.error(trailersAtCurrentLocation);
                        commit(
                            MutationTypes.SET_ERROR,
                            trailersAtCurrentLocation as FbaApiError
                        );
                    } else {
                        const driver = getters.getDriver;
                        const reservedTrailers =
                            await queries.getReservedTrailerByDriverId(
                                driver?.driverCode || '',
                                driver?.company || '',
                                reportedLocation
                            );
                        if (
                            !(reservedTrailers instanceof FbaApiError) &&
                            reservedTrailers !== null
                        ) {
                            if (
                                reservedTrailers[0]?.trailers !== undefined &&
                                reservedTrailers[0].trailers.length > 0
                            ) {
                                //If more than one trailer is reserved (on site or externally), we need a better way to identify the most relevant.  Ditto in Find flow.
                                const reservation =
                                    reservedTrailers[0].trailers[0].reservation;
                                if (reservation !== undefined) {
                                    let reservationCustomerLocationId =
                                        undefined as string | undefined;
                                    if (
                                        reservation.trailerLocation !==
                                        undefined
                                    ) {
                                        const reservationCustomerLocation =
                                            await queries.getCustomerByLocation(
                                                (truck as Truck).company,
                                                reservation.trailerLocation
                                            );

                                        if (
                                            reservationCustomerLocation instanceof
                                            Customer
                                        ) {
                                            reservationCustomerLocationId =
                                                reservationCustomerLocation.locationId;
                                        }
                                    }

                                    commit(
                                        MutationTypes.SET_CURRENT_RESERVATION,
                                        new TrailerReservation(
                                            reservation.expiresAt?.toISO() ??
                                                '',
                                            reservation.trailerId ?? '',
                                            reservation.driverId,
                                            reservation.trailerLocation,
                                            reservationCustomerLocationId
                                        )
                                    );
                                }
                            }

                            const reservedTrailersOffsite =
                                [] as NearbyTrailerResult[];
                            reservedTrailers.forEach((trl) => {
                                if (
                                    trl.locationId === customerResult.locationId
                                ) {
                                    trailersAtCurrentLocation.unshift(trl);
                                } else {
                                    reservedTrailersOffsite.unshift(trl);
                                }
                            });

                            if (reservedTrailersOffsite.length > 0) {
                                commit(
                                    MutationTypes.SET_NEARBY_TRAILER_RESULTS,
                                    reservedTrailersOffsite
                                );
                            }
                        }
                        commit(
                            MutationTypes.SET_TRAILERS_AT_CURRENT_LOCATION,
                            trailersAtCurrentLocation
                        );
                    }
                } else {
                    commit(MutationTypes.SET_TRAILERS_AT_CURRENT_LOCATION, []);
                }
            }
        }
    },
    async [ActionTypes.FETCH_TRUCK](
        { commit, dispatch, getters },
        payload: { company: string; id: string }
    ): Promise<void> {
        const currentTruck = getters.getTruck;
        if (
            currentTruck instanceof Truck &&
            (<Truck>currentTruck).truckId === payload.id &&
            (<Truck>currentTruck).company === payload.company
        ) {
            await dispatch(ActionTypes.REFRESH_PINNED_LOCATION, {
                truck: currentTruck,
            });
            return;
        }
        const apiResult = await queries.getTruckById(
            payload.company,
            payload.id
        );
        let fetchedTruck = null as Truck | null;

        if (apiResult instanceof Truck) {
            const truckAddress = await locationReverseGeocode(
                apiResult.location
            );
            if (truckAddress instanceof Address) {
                apiResult.setAddress(truckAddress);
            }
            fetchedTruck = apiResult;
        } else {
            console.error(apiResult as FbaApiError);
            commit(MutationTypes.SET_ERROR, apiResult as FbaApiError);
            if (
                apiResult?.reasonCode ===
                globalConstants.reasonCodes.truckNotFound
            )
                fetchedTruck = null;
        }

        await dispatch(ActionTypes.REFRESH_PINNED_LOCATION, {
            truck: fetchedTruck,
        });

        commit(MutationTypes.SET_TRUCK, fetchedTruck);
    },
    async [ActionTypes.FETCH_DRIVER](
        { commit, getters },
        payload: { company: string; driverCode: string }
    ): Promise<void> {
        const currentDriver = getters.getDriver;
        if (
            currentDriver instanceof Driver &&
            (<Driver>currentDriver).driverCode === payload.driverCode &&
            (<Driver>currentDriver).company === payload.company
        ) {
            return;
        }

        const apiResult = await queries.getDriverByDriverOrTruckCodeAndCompany(
            payload.company,
            payload.driverCode
        );

        if (apiResult instanceof Driver) {
            commit(MutationTypes.SET_DRIVER, apiResult as Driver);
            apmTransactions.setUserDetails();
        } else {
            console.error(apiResult as FbaApiError);
            commit(MutationTypes.SET_ERROR, apiResult as FbaApiError);

            if (
                apiResult?.reasonCode ===
                globalConstants.reasonCodes.driverNotFound
            )
                commit(MutationTypes.SET_DRIVER, null);
        }
    },
    async [ActionTypes.FETCH_FEATURE_FLAGS](
        { commit },
        payload: { driverCode: string; company: string }
    ): Promise<void> {
        const featureFlags = await queries.getFeatureFlags(payload);

        if (featureFlags instanceof FbaApiError) {
            console.error(featureFlags);
            commit(MutationTypes.SET_ERROR, featureFlags as FbaApiError);
        }

        commit(MutationTypes.SET_FEATURE_FLAGS, featureFlags as FeatureFlags);
    },
    async [ActionTypes.FETCH_NEARBY_TRAILER_RESULTS]({
        commit,
        getters,
        dispatch,
    }): Promise<void> {
        const truck = getters.getTruck;
        const driver = getters.getDriver;
        const excludeCustomer = getters.getExcludeCustomer;
        const reportedLocation =
            getters.getPinnedBestAvailableLocation ?? new Geopoint(0, 0);

        if (isNullOrUndefined(truck)) {
            console.error('No truck found in store!');
            return;
        } else if (isNullOrUndefined(driver)) {
            console.error('No driver found in store!');
            return;
        }

        commit(MutationTypes.SET_NEARBY_TRAILER_RESULTS, undefined);

        const reservedTrailers = await queries.getReservedTrailerByDriverId(
            driver.driverCode,
            driver.company,
            reportedLocation
        );

        if (reservedTrailers instanceof FbaApiError) {
            console.error(reservedTrailers);
            commit(MutationTypes.SET_ERROR, reservedTrailers);

            return;
        }

        const reservedTrailer = reservedTrailers[0]?.trailers[0];
        const reservedTrailerNumber = reservedTrailer?.trailerNumber || '';
        const reservation = reservedTrailer?.reservation;
        if (reservation !== undefined) {
            reservation.trailerLocationId = reservedTrailers[0].locationId;
        }

        if (reservation?.isActive) {
            commit(MutationTypes.SET_CURRENT_RESERVATION, reservation);
        } else {
            commit(MutationTypes.SET_CURRENT_RESERVATION, null);
        }

        const driverHasTrailerReservation =
            !isEmpty(reservedTrailerNumber) &&
            !isNullOrUndefined(reservedTrailerNumber);

        let results = await queries.getNearbyTrailers(
            (truck as Truck).company,
            (truck as Truck).truckId,
            globalConstants.nearbyResultListCount,
            excludeCustomer,
            getters.getPinnedBestAvailableLocation
        );
        if (results instanceof FbaApiError) {
            console.error(results as FbaApiError);
            commit(MutationTypes.SET_ERROR, results as FbaApiError);
        } else {
            results = (reservedTrailers as Array<NearbyTrailerResult>).concat(
                results.filter(
                    (result) =>
                        result.type !=
                        NearbyTrailerResultType.noResultsAtCustomerLocation
                ) as Array<NearbyTrailerResult>
            );
            const reverseGeocode = async (result: NearbyTrailerResult) => {
                const address = await locationReverseGeocode(result.location);
                if (address instanceof FbaApiError) {
                    console.error(address as FbaApiError);
                } else {
                    result.locationAddress = address;
                }
            };
            const promises: Promise<void>[] = [];
            (results as NearbyTrailerResult[]).forEach((result) => {
                if (result.type === NearbyTrailerResultType.standaloneTrailer)
                    promises.push(reverseGeocode(result));
            });
            await Promise.all(promises);

            cleanDuplicateTrailers(results);
            if (driverHasTrailerReservation) {
                mergeSameCustomer(results, reservedTrailerNumber);
            }
            results = results
                .filter((oResult) => oResult.trailers.length)
                .slice(
                    0,
                    globalConstants.nearbyResultListCount +
                        (driverHasTrailerReservation ? 1 : 0)
                );

            commit(
                MutationTypes.SET_NEARBY_TRAILER_RESULTS,
                results as NearbyTrailerResult[]
            );
            await dispatch(ActionTypes.UPDATE_TRAILER_RESULTS_WITH_ROUTE_INFO);
        }
    },
    async [ActionTypes.FETCH_ACTIVE_LOAD]({
        commit,
        getters,
    }): Promise<void | FbaApiError> {
        const driver = getters.getDriver;

        if (isNullOrUndefined(driver)) {
            console.error('No driver found in store!');

            return;
        }

        const apiResult = await queries.getActiveLoadByLoadNumber(
            driver.dispatch.loadNumber
        );

        if (apiResult instanceof FbaApiError) {
            console.error(apiResult as FbaApiError);
            commit(MutationTypes.SET_ERROR, apiResult);

            return;
        }

        commit(MutationTypes.SET_ACTIVE_LOAD, apiResult);
    },
    async [ActionTypes.REMOVE_NAVIGATION_DESTINATION]({
        commit,
        getters,
    }): Promise<void | FbaApiError> {
        if (
            getters.getCompany !== undefined &&
            getters.getDriverCode !== undefined
        ) {
            commit(MutationTypes.SET_CURRENT_NAVIGATION_DESTINATION, undefined);
            const result = await queries.deleteActiveNavigationTask(
                getters.getCompany,
                getters.getDriverCode
            );
            if (result instanceof FbaApiError) {
                console.error(result);
                return result;
            } else {
                commit(MutationTypes.SET_CURRENT_NAVIGATION_DESTINATION, null);
            }
        }
    },
    async [ActionTypes.REFRESH_TRUCK]({
        commit,
        dispatch,
        getters,
    }): Promise<void> {
        const truck = getters.getTruck;
        if (truck?.company && truck?.truckId) {
            const truckResult = await queries.getTruckById(
                truck.company,
                truck.truckId
            );
            if (truckResult instanceof Truck) {
                const refreshLocationPromise = dispatch(
                    ActionTypes.REFRESH_PINNED_LOCATION,
                    { truck: truckResult }
                );
                const truckAddressPromise = locationReverseGeocode(
                    truckResult.location
                ).then((address) => {
                    if (address instanceof Address) {
                        truckResult.setAddress(address);
                    }
                });
                await Promise.allSettled([
                    truckAddressPromise,
                    refreshLocationPromise,
                ]);

                commit(MutationTypes.SET_TRUCK, truckResult);
            } else {
                console.error(truckResult as FbaApiError);
                commit(MutationTypes.SET_ERROR, truckResult as FbaApiError);
            }
        }
    },

    async [ActionTypes.FETCH_LAST_ACTIVE_TRAILER_ASSESSMENT](
        { commit },
        payload: { trailerNumber: string; trailerCompany: string }
    ): Promise<void> {
        if (!payload.trailerNumber || !payload.trailerCompany) return;

        const assessmentResponse = await queries.getActiveTrailerAssessment(
            payload.trailerNumber,
            payload.trailerCompany
        );

        if (assessmentResponse instanceof FbaApiError) {
            commit(MutationTypes.SET_ERROR, assessmentResponse as FbaApiError);
        } else {
            commit(
                MutationTypes.SET_LAST_ACTIVE_TRAILER_ASSESSMENT_RESULT,
                assessmentResponse
            );
        }
    },

    async [ActionTypes.BEGIN_TRAILER_ASSESSMENT]({
        commit,
        dispatch,
        getters,
    }) {
        let assessmentMeta = getters.trailerAssessmentMeta;
        const alreadyBegun = !!assessmentMeta?.startTime;

        if (alreadyBegun) {
            return;
        }

        if (assessmentMeta === undefined) {
            assessmentMeta = new TrailerAssessmentMeta();
        }

        assessmentMeta.startTime = DateTime.now();

        await dispatch(ActionTypes.REFRESH_PINNED_LOCATION, {
            truck: getters.getTruck,
        });
        assessmentMeta.location = Geopoint.mapToIGeopointDto(
            getters.getPinnedBestAvailableLocation
        );

        commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT_META, assessmentMeta);
    },

    [ActionTypes.UPDATE_TRAILER_ASSESSMENT_QUESTION](
        { commit, getters },
        payload: ITrailerAssessmentQuestionAndAnswer
    ): void {
        const assessmentMeta =
            getters.trailerAssessmentMeta ?? new TrailerAssessmentMeta();
        let questions = assessmentMeta?.questionAnswers ?? [];
        questions = questions.filter((qna) => qna.question != payload.question);
        questions.push(payload);
        assessmentMeta.questionAnswers = questions;

        commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT_META, assessmentMeta);
    },
    [ActionTypes.UPDATE_TRAILER_ASSESSMENT_OUTCOME](
        { commit, getters },
        payload: TrailerAssessmentOutcome
    ): void {
        const assessmentMeta =
            getters.trailerAssessmentMeta ?? new TrailerAssessmentMeta();
        assessmentMeta.outcome = payload;
        commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT_META, assessmentMeta);
    },

    async [ActionTypes.POST_TRAILER_ASSESSMENT](
        { commit, getters },
        payload: {
            trailerNumber: string;
            trailerCompany: string;
            eventName: AssociationType;
            damageSignatureRecordedTimestamp?: string | null;
            trailerSelectionMethod?: TrailerSelectionMethod | undefined;
        }
    ): Promise<PostTrailerAssessmentResponse | FbaApiError> {
        const assessment = getters.trailerAssessment;
        const assessmentMeta = getters.trailerAssessmentMeta;
        const driver = getters.getDriver;

        if (
            !assessment ||
            !assessmentMeta ||
            !payload.trailerNumber ||
            !payload.trailerCompany ||
            !driver
        ) {
            return {
                missingArguments: true,
                didSucceed: false,
            } as PostTrailerAssessmentResponse;
        }

        const assessmentDto: ITrailerAssessmentDto = {
            driver: {
                code: driver.driverCode,
                dotNumber: null,
                mcNumber: null,
                carrierName: driver.company,
                mobileNumber: driver.phoneNumbers[0]?.number,
                firstName: driver.firstName,
                lastName: driver.lastName,
            },
            trailer: {
                id: payload.trailerNumber,
                company: payload.trailerCompany,
            },
            location: assessmentMeta?.location ?? { lat: 0, lon: 0 },
            assessmentTypes: values(assessment),
            startTime: (assessmentMeta?.startTime ?? DateTime.now()).toISO(),
            endTime: DateTime.now().toISO(),
            questionAnswers: assessmentMeta?.questionAnswers ?? [],
            assessmentOutcome: TrailerAssessmentOutcome.UNSPECIFIED,
            eventName: payload.eventName,
            damageSignatureRecordedTimestamp:
                payload.damageSignatureRecordedTimestamp,
            source: 'Trailer Solutions Driver Assessment',
        };

        if (payload.eventName === AssociationType.ATTACH) {
            assessmentDto.assessmentOutcome =
                assessmentMeta?.outcome ?? TrailerAssessmentOutcome.UNSPECIFIED;
        }

        const response = await queries.postTrailerAssessment(assessmentDto);

        commit(MutationTypes.CLEAR_TRAILER_ASSESSMENT, undefined);
        commit(MutationTypes.CLEAR_TRAILER_SELECTION_METHOD, undefined);

        if (response instanceof FbaApiError) {
            return response;
        } else {
            return {
                didSucceed: response.isSuccessful,
            } as PostTrailerAssessmentResponse;
        }
    },

    [ActionTypes.UPDATE_TRAILER_ASSESSMENT](
        { commit, getters },
        damage: TrailerAssessmentType
    ): void {
        const assessment =
            cloneDeep(getters.trailerAssessment) || new TrailerAssessment();
        const type = get(assessment, damage.type);

        if (
            isNullOrUndefined(type.typeHasDamage) ||
            type.typeHasDamage !== damage.typeHasDamage
        ) {
            set(assessment, damage.type, damage);
            commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT, assessment);
        }
    },

    [ActionTypes.REMOVE_TRAILER_ASSESSMENT](
        { commit, getters },
        type: string
    ): void {
        const assessment =
            cloneDeep(getters.trailerAssessment) || new TrailerAssessment();
        const damage = new TrailerAssessmentType(
            type as keyof TrailerAssessment
        );

        if (!isNullOrUndefined(damage)) {
            set(assessment, damage.type, damage);
            commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT, assessment);
        }
    },

    [ActionTypes.SAVE_PARTIAL_TRAILER_ASSESSMENT](
        { commit, getters },
        damage: TrailerAssessmentSubType
    ): void {
        const assessment = cloneDeep(getters.trailerAssessment);
        if (!assessment) {
            console.warn('No assessment to store subtype on');
            return;
        }
        const subTypes = get(assessment, damage.type).subTypes;

        if (
            !subTypes.some(
                (sub: TrailerAssessmentSubType) =>
                    sub.subType === damage.subType
            )
        ) {
            subTypes.push(damage);
        } else {
            subTypes[
                subTypes.findIndex(
                    (sub: TrailerAssessmentSubType) =>
                        sub.subType === damage.subType
                )
            ] = damage;
        }

        assessment[damage.type].subTypes = subTypes;
        commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT, assessment);
    },

    [ActionTypes.REMOVE_PARTIAL_TRAILER_ASSESSMENT](
        { commit, dispatch, getters },
        damageUpdate: TrailerAssessmentSubType
    ): void {
        const assessment = cloneDeep(getters.trailerAssessment);
        if (!assessment) {
            console.warn('No assessment available');
            return;
        }
        const subTypes = get(assessment, damageUpdate.type).subTypes;
        const removeIndex = subTypes.findIndex(
            (sub: TrailerAssessmentSubType) =>
                sub.subType === damageUpdate.subType
        );

        if (removeIndex === -1) {
            return;
        } else if (shouldClearTypeOnSubtypeRemoval(assessment, damageUpdate)) {
            dispatch(
                ActionTypes.UPDATE_TRAILER_ASSESSMENT,
                new TrailerAssessmentType(damageUpdate.type)
            );
        } else {
            pullAt(subTypes, removeIndex);
            assessment[damageUpdate.type].subTypes = subTypes;
            commit(MutationTypes.UPDATE_TRAILER_ASSESSMENT, assessment);
        }
    },

    [ActionTypes.CLEAR_QUERY_PARAM_AND_ROUTE](
        _,
        options: { routeToName: string; omitParam: string }
    ): void {
        router.push({
            name: options.routeToName,
            query: omit(router.currentRoute.value.query, options.omitParam),
        });
    },

    async [ActionTypes.POST_ATTACH_DRIVER_TRAILER](
        { dispatch, getters },
        payload: {
            trailerNumber: string;
            trailerCompany: string;
            driverLocation: Geopoint;
            trailerQrId: string | undefined;
            trailerSelectionMethod: TrailerSelectionMethod | undefined;
        }
    ): Promise<DriverTrailerAssociationResponseDto | FbaApiError | undefined> {
        const truck = getters.getTruck;
        const driver = getters.getDriver;

        if (
            !payload.trailerNumber ||
            !payload.trailerCompany ||
            !driver ||
            !truck
        ) {
            return;
        }

        const trailerCompositeId = `${payload.trailerNumber}|${payload.trailerCompany}`;
        const trailer = isNullOrUndefined(payload.trailerQrId)
            ? { compositeId: trailerCompositeId }
            : { scannedQrId: payload.trailerQrId };

        const actualTrailer = await queries.getTrailerById(
            payload.trailerNumber,
            payload.trailerCompany
        );
        const actualTrailerLocationId =
            getters.getCustomerAtCurrentLocation?.locationId;
        const trailerReservation = getters.getCurrentTrailerReservation;
        const trailerCodeAndCompany = trailerReservation?.trailerId?.split(
            '|'
        ) ?? ['', ''];

        const reservedTrailer =
            trailerReservation?.trailerId &&
            trailerReservation.trailerId != trailerCompositeId
                ? await queries.getTrailerById(
                      trailerCodeAndCompany[0],
                      trailerCodeAndCompany[1]
                  )
                : actualTrailer;

        const response = await queries.postAttach({
            trailer,
            driver: {
                compositeId: driver.id,
                location: {
                    latitude: payload.driverLocation.lat,
                    longitude: payload.driverLocation.lng,
                },
            },
            truck: { compositeId: truck.id },
            telemetryInfo: {
                attachingTrailer:
                    actualTrailer instanceof Trailer
                        ? actualTrailer
                        : undefined,
                attachingTrailerLocationId: actualTrailerLocationId,
                attachingTrailerSelectionMethod: payload.trailerSelectionMethod,
                trailerReservation: trailerReservation,
                reservedTrailer:
                    reservedTrailer instanceof Trailer
                        ? reservedTrailer
                        : undefined,
            },
        });

        if (response instanceof FbaApiError) {
            return response;
        }

        await dispatch(ActionTypes.REMOVE_NAVIGATION_DESTINATION);

        return response;
    },
    async [ActionTypes.POST_DETACH_DRIVER_TRAILER](
        { getters },
        payload: { trailerNumber: string; trailerCompany: string }
    ): Promise<DriverTrailerAssociationResponseDto | FbaApiError | undefined> {
        const truck = getters.getTruck;
        const driver = getters.getDriver;

        if (
            !payload.trailerNumber ||
            !payload.trailerCompany ||
            !driver ||
            !truck
        ) {
            return;
        }

        const trailerCompositeId = `${payload.trailerNumber}|${payload.trailerCompany}`;
        const response = await queries.postDetach({
            trailer: { compositeId: trailerCompositeId },
            driver: { compositeId: driver.id },
            truck: { compositeId: truck.id },
        });

        return response;
    },
    [ActionTypes.SET_SELECTED_TRAILER](
        { commit },
        payload: {
            trailer: Trailer;
            trailerSelectionMethod: TrailerSelectionMethod;
        }
    ): void {
        commit(MutationTypes.UPDATE_SELECTED_TRAILER, payload.trailer);
        commit(
            MutationTypes.UPDATE_TRAILER_SELECTION_METHOD,
            payload.trailerSelectionMethod
        );
    },
    [ActionTypes.SET_NETWORK_TIMEOUT]({ commit }, payload: boolean): void {
        commit(MutationTypes.SET_NETWORK_TIMEOUT, payload);
    },
    async [ActionTypes.UPDATE_TRAILER_RESULTS_WITH_ROUTE_INFO]({
        commit,
        getters,
    }): Promise<void> {
        const results = getters.getNearbyTrailerResults;
        const truck = getters.getTruck;

        if (
            results !== undefined &&
            truck !== undefined &&
            results.length > 0
        ) {
            const bestAvailableLocation: Geopoint =
                getters.getPinnedBestAvailableLocation ?? new Geopoint(0, 0);

            const routeRequestArray: Promise<NearbyTrailerResult>[] =
                results?.map(async (result) => {
                    const route = await calculateRoute(
                        result.location,
                        bestAvailableLocation
                    );
                    if (route && route instanceof RouteSummary) {
                        result.route = route;
                    }

                    return result as NearbyTrailerResult;
                });

            const newResults = await Promise.all(routeRequestArray);

            commit(
                MutationTypes.SET_NEARBY_TRAILER_RESULTS,
                newResults as NearbyTrailerResult[]
            );
        }
    },
    async [ActionTypes.UPDATE_DRIVER_PTA]({ commit, getters }, payload) {
        const driver = getters.getDriver;
        const result = await queries.updateDriverPta(driver?.id || '', payload);

        if (result instanceof FbaApiError) {
            commit(MutationTypes.SET_ERROR, result);

            return;
        }

        if (!result.isSuccessful) {
            console.error(
                'Failed to update driver PTA with unspecified reason'
            );

            return;
        }

        const newPta = DateTime.fromISO(result.pta);

        if (!newPta.isValid) {
            console.error(`API returned non-ISO PTA DateTime "${result.pta}"`);

            return;
        }

        commit(MutationTypes.UPDATE_DRIVER_PTA, newPta);
    },
    [ActionTypes.CLEAR_ERROR]({ commit }): void {
        commit(MutationTypes.CLEAR_ERROR, undefined);
    },
    [ActionTypes.SET_LOCATION_OUTDATED_WARNING_BANNER](
        { commit },
        payload
    ): void {
        let baseMessageTitle = '';
        let baseMessage = '';
        const variables = [payload.getCity(), payload.getState()];

        if (payload.shouldUseMinutes() === true) {
            if (payload.getMinutes() === 1) {
                baseMessageTitle = 'Your location may not be up to date.';
                baseMessage =
                    "We'll display your truck's last location in {0}, {1} as of {2} minute ago.";
            } else {
                baseMessageTitle = 'Your location may not be up to date.';
                baseMessage =
                    "We'll display your truck's last location in {0}, {1} as of {2} minutes ago.";
            }

            variables.push(payload.getMinutes().toString());
        } else {
            if (payload.getHours() === 1) {
                baseMessageTitle = 'Your location may not be up to date.';
                baseMessage =
                    "We'll display your truck's last location in {0}, {1} as of {2} hour ago.";
            } else {
                baseMessageTitle = 'Your location may not be up to date.';
                baseMessage =
                    "We'll display your truck's last location in {0}, {1} as of {2} hours ago.";
            }

            variables.push(payload.getHours().toString());
        }

        const bannerAlert = new BannerAlert({
            type: BannerAlertType.WARNING,
            baseMessageTitle: baseMessageTitle,
            baseMessage: baseMessage,
            variables,
            iconType: BannerAlertIconType.CLOCK,
        });
        commit(MutationTypes.ADD_BANNER_ALERT, bannerAlert);
    },
    [ActionTypes.SET_PERMISSION_DENIED_WARNING_BANNER](
        { commit },
        payload
    ): void {
        let iconType = undefined;
        const { alertCamera, alertLocation } = payload;

        if (alertCamera && alertLocation) {
            iconType = BannerAlertIconType.CAMERA_AND_LOCATION;
        } else if (alertCamera) {
            iconType = BannerAlertIconType.CAMERA;
        } else if (alertLocation) {
            iconType = BannerAlertIconType.LOCATION;
        }

        if (!isNullOrUndefined(iconType)) {
            commit(
                MutationTypes.REMOVE_BANNER_ALERTS_OF_TYPE,
                BannerAlertType.PERMISSION_BEG
            );

            commit(
                MutationTypes.ADD_BANNER_ALERT,
                getPermissionAlert(iconType)
            );
        }
    },
    async [ActionTypes.UPDATE_PERSISTENT_ALERT_BANNER]({
        commit,
        getters,
    }): Promise<void> {
        const alertResponse = await queries.getPersistentAlert();

        if (
            !(alertResponse instanceof PersistentAlert) ||
            alertResponse.effectiveDate === undefined ||
            alertResponse.keepAlertActiveUntil === undefined
        ) {
            return;
        }

        const now = DateTime.now();
        const alertIsActive =
            alertResponse.effectiveDate < now &&
            now < alertResponse.keepAlertActiveUntil;
        if (!alertIsActive) {
            commit(
                MutationTypes.REMOVE_BANNER_ALERTS_OF_TYPE,
                BannerAlertType.PERSISTENT_ALERT
            );
            return;
        }

        if (getters.getBannerAlertMaintenanceDisplayed) {
            return;
        }

        const bannerAlert = new BannerAlert({
            type: BannerAlertType.PERSISTENT_ALERT,
            baseMessageTitle: alertResponse.alertMessage ?? '',
            baseMessage: alertResponse.alertMessageDetails ?? '',
            iconType: BannerAlertIconType.WRENCH,
        });
        commit(MutationTypes.ADD_BANNER_ALERT, bannerAlert);
        commit(MutationTypes.SET_BANNER_ALERT_MAINTENANCE_DISPLAYED, true);
    },
    [ActionTypes.SHOW_LOCATION_BEGGING_MODAL](
        { commit },
        payload: LocationBeggingModalOptions
    ): void {
        commit(MutationTypes.SHOW_LOCATION_BEGGING_MODAL, payload);
    },
    [ActionTypes.CLEAR_BANNER_ALERT]({ commit }, payload): void {
        commit(MutationTypes.REMOVE_BANNER_ALERT, payload);
    },
    async [ActionTypes.SUBMIT_DRIVER_FEEDBACK](
        { dispatch },
        payload: DriverFeedback
    ) {
        const result = await queries.postDriverFeedback(payload);

        if (result instanceof FbaApiError || !result) {
            dispatch(ActionTypes.SET_FEEDBACK_ERROR_BANNER, undefined);
        } else {
            dispatch(ActionTypes.SET_FEEDBACK_SUCCESS_BANNER, undefined);
        }
    },
    async [ActionTypes.REPORT_QR_UNASSOCIATED](
        { getters },
        payload
    ): Promise<boolean | void | FbaApiError> {
        const driver = getters.getDriver;
        const deviceInfo = getters.getDeviceInfo;
        const currentLocation = getters.getPinnedBestAvailableLocation;

        if (!driver || !deviceInfo || !currentLocation) {
            return;
        }

        const response = await queries.postReportQrUnassociated({
            scannedQrId: payload.scannedQrId,
            trailerNumber: payload.trailerNumber,
            trailerCompany: payload.trailerCompany,
            driverCode: driver.driverCode,
            driverCompany: driver.company,
            isDeviceLocationShared: !!deviceInfo.isGeoLocationShared,
            reportedLocation: {
                latitude: currentLocation.lat,
                longitude: currentLocation.lng,
            },
            selectedQRSide: payload.selectedQRSide,
        });

        if (response instanceof FbaApiError) {
            return response;
        }

        return response.IsSuccessful;
    },
    async [ActionTypes.HANDLE_LOCATION_ACCESS_ERROR](
        { commit, dispatch, getters },
        error: GeolocationPositionError
    ) {
        if (error.code == error.PERMISSION_DENIED) {
            const deviceInfo = getters.getDeviceInfo;
            if (deviceInfo.isGeoLocationShared !== false) {
                dispatch(
                    ActionTypes.SET_PERMISSION_DENIED_WARNING_BANNER,
                    undefined
                );
                apmTransactions.instrumentGeolocationInfo(
                    GeolocationInfoType.DeviceGeolocationAccessDenied,
                    'Geolocation Access Denied'
                );
            }
            deviceInfo.isGeoLocationShared = false;
            commit(MutationTypes.SET_DEVICE_INFO, deviceInfo);
        } else {
            const message = `geolocation error code = ${getReadableGeolocationPositionErrorCode(
                error.code
            )}, message = ${error.message}`;
            apmTransactions.instrumentGeolocationInfo(
                GeolocationInfoType.DeviceGeolocationError,
                message
            );
        }
    },
    async [ActionTypes.BEGIN_WATCHING_DEVICE_LOCATION]({ commit, getters }) {
        if (getters.driverDeviceLocationFeatureEnabled) {
            const deviceLocationRefreshInterval = 15000;
            const highAccuracyOptions = {
                enableHighAccuracy: true,
                timeout: 60000, //At most wait 60 seconds, before allowing the watcher to continue with a fresh call to the navigator API.
                maximumAge: deviceLocationRefreshInterval, //Don't accept cached values older than 15 seconds.
            } as PositionOptions;

            const onSuccess = function (pos: GeolocationPosition) {
                const deviceInfo = getters.getDeviceInfo;
                if (deviceInfo.isGeoLocationShared !== true) {
                    apmTransactions.instrumentGeolocationInfo(
                        GeolocationInfoType.DeviceGeolocationAccessAllowed,
                        'Geolocation Permission Allowed (Using High Precision Mode).'
                    );
                }

                deviceInfo.isGeoLocationShared = true;
                deviceInfo.watchedDeviceLocationGeopoint =
                    Geopoint.mapFromGeolocationPosition(pos);
                deviceInfo.watchedDeviceLocationUpdateTime = DateTime.now();
                commit(MutationTypes.SET_DEVICE_INFO, deviceInfo);
            };

            const onError = function (error: GeolocationPositionError) {
                if (error.code == error.PERMISSION_DENIED) {
                    const deviceInfo = getters.getDeviceInfo;
                    if (deviceInfo.isGeoLocationShared !== false) {
                        apmTransactions.instrumentGeolocationInfo(
                            GeolocationInfoType.DeviceGeolocationAccessDenied,
                            'Geolocation Access Denied'
                        );
                    }
                    deviceInfo.isGeoLocationShared = false;
                    commit(MutationTypes.SET_DEVICE_INFO, deviceInfo);
                } else {
                    const message = `geolocation error code = ${getReadableGeolocationPositionErrorCode(
                        error.code
                    )}, message = ${error.message}`;
                    apmTransactions.instrumentGeolocationInfo(
                        GeolocationInfoType.DeviceGeolocationError,
                        message
                    );
                }
            };

            const deviceInfo = getters.getDeviceInfo;
            deviceInfo.watchDeviceLocation(
                onSuccess,
                onError,
                highAccuracyOptions,
                deviceLocationRefreshInterval
            );
        }
    },
    [ActionTypes.STOP_WATCHING_DEVICE_LOCATION]({ getters }) {
        if (getters.getDeviceInfo != undefined) {
            getters.getDeviceInfo.stopWatchingDeviceLocation();
        }
    },
    async [ActionTypes.REFRESH_PINNED_LOCATION](
        { commit, getters },
        payload: {
            truck: Truck | null | undefined;
            timeoutOverride?: number | undefined;
        }
    ) {
        const fallBackToTruckLocationFunc = () => {
            const geopoint = new Geopoint(
                payload?.truck?.location?.lat || 0,
                payload?.truck?.location?.lng || 0
            );
            apmTransactions.instrumentGeolocationInfo(
                GeolocationInfoType.TruckGeolocationUsed,
                getters.driverDeviceLocationFeatureEnabled
                    ? 'Fell back to truck geopoint.'
                    : 'Used truck location per feature flags.',
                undefined,
                geopoint
            );
            return geopoint;
        };

        if (!getters.driverDeviceLocationFeatureEnabled) {
            commit(
                MutationTypes.SET_PINNED_LOCATION,
                fallBackToTruckLocationFunc()
            );
            return;
        }

        const maximumDeviceLocationAgeInMillis = 60 * 1000;

        const deviceInfo = getters.getDeviceInfo;
        if (
            deviceInfo.watchedDeviceLocationGeopoint !== undefined &&
            deviceInfo.watchedDeviceLocationUpdateTime !== undefined
        ) {
            const locationValidUntilTime =
                deviceInfo.watchedDeviceLocationUpdateTime.plus(
                    maximumDeviceLocationAgeInMillis
                );
            if (DateTime.now() < locationValidUntilTime) {
                apmTransactions.instrumentGeolocationInfo(
                    GeolocationInfoType.DeviceGeolocationUsed,
                    'Used already-fetched device geolocation from the LocationWatcher.',
                    undefined,
                    deviceInfo.watchedDeviceLocationGeopoint
                );
                commit(
                    MutationTypes.SET_PINNED_LOCATION,
                    deviceInfo.watchedDeviceLocationGeopoint
                );
                return;
            }
        }

        const highAccuracyOptions = {
            enableHighAccuracy: true,
            timeout: 15000, //At most, wait 15 seconds for a realtime position, else fall back to truck location.
            maximumAge: maximumDeviceLocationAgeInMillis, //Accept a cached value if necessary, at most a minute old.
        } as PositionOptions;

        //Try to get a high accuracy result within 15 seconds.
        const bestAvailableLocation = await getCurrentLocation(
            highAccuracyOptions
        )
            .then((pos: GeolocationPosition) => {
                apmTransactions.instrumentGeolocationInfo(
                    GeolocationInfoType.DeviceGeolocationUsed,
                    `Waited for and used ${
                        highAccuracyOptions?.enableHighAccuracy ? 'high' : 'low'
                    } accuracy device geolocation.`,
                    pos
                );
                const deviceInfo = getters.getDeviceInfo;
                deviceInfo.isGeoLocationShared = true;
                deviceInfo.watchedDeviceLocationGeopoint =
                    Geopoint.mapFromGeolocationPosition(pos);
                deviceInfo.watchedDeviceLocationUpdateTime = DateTime.now();
                commit(MutationTypes.SET_DEVICE_INFO, deviceInfo);

                return Geopoint.mapFromGeolocationPosition(pos);
            })
            .catch((error: GeolocationPositionError) => {
                if (error.code == error.PERMISSION_DENIED) {
                    const deviceInfo = getters.getDeviceInfo;
                    if (deviceInfo.isGeoLocationShared !== false) {
                        apmTransactions.instrumentGeolocationInfo(
                            GeolocationInfoType.DeviceGeolocationAccessDenied,
                            'Geolocation Access Denied'
                        );
                    }
                    deviceInfo.isGeoLocationShared = false;
                    commit(MutationTypes.SET_DEVICE_INFO, deviceInfo);
                } else {
                    const message = `geolocation error code = ${getReadableGeolocationPositionErrorCode(
                        error.code
                    )}, message = ${error.message}`;
                    apmTransactions.instrumentGeolocationInfo(
                        GeolocationInfoType.DeviceGeolocationError,
                        message
                    );
                }

                return fallBackToTruckLocationFunc();
            });

        commit(MutationTypes.SET_PINNED_LOCATION, bestAvailableLocation);
    },
    [ActionTypes.SET_FEEDBACK_SUCCESS_BANNER]({ commit }): void {
        const baseMessageTitle = 'Sent!';
        const baseMessage = 'Thank you for sharing your feedback!';

        const bannerAlert = new BannerAlert({
            type: BannerAlertType.SUCCESS,
            baseMessageTitle: baseMessageTitle,
            baseMessage: baseMessage,
            duration: 3000,
            iconType: BannerAlertIconType.CHECK,
        });
        commit(MutationTypes.ADD_BANNER_ALERT, bannerAlert);
    },
    [ActionTypes.SET_FEEDBACK_ERROR_BANNER]({ commit }): void {
        const baseMessageTitle = 'Oops!';
        const baseMessage = 'Something went wrong, please try again.';

        const bannerAlert = new BannerAlert({
            type: BannerAlertType.ERROR,
            baseMessageTitle: baseMessageTitle,
            baseMessage: baseMessage,
            iconType: BannerAlertIconType.EXCLAMATION,
        });
        commit(MutationTypes.ADD_BANNER_ALERT, bannerAlert);
    },
    [ActionTypes.SET_WORKFLOW_ORIGIN]({ commit }, payload): void {
        commit(MutationTypes.SET_WORKFLOW_ORIGIN, payload);
    },
    [ActionTypes.SET_DETACH_SUCCESS_BANNER]({ commit }): void {
        const baseMessageTitle = 'Success!';
        const baseMessage = 'You are now detached.';

        const bannerAlert = new BannerAlert({
            type: BannerAlertType.SUCCESS,
            baseMessageTitle: baseMessageTitle,
            baseMessage: baseMessage,
            duration: 3000,
            iconType: BannerAlertIconType.CHECK,
        });
        commit(MutationTypes.ADD_BANNER_ALERT, bannerAlert);
    },
    [ActionTypes.SET_DETACH_ERROR_BANNER]({ commit, getters }): void {
        const driver = getters.getDriver;
        const contactTitle = getDirectContactTitle(driver?.company);
        const baseMessageTitle = 'Detach failed.';
        const baseMessage = `Please contact your ${contactTitle} for further assistance, or try again later.`;

        const bannerAlert = new BannerAlert({
            type: BannerAlertType.ERROR,
            baseMessageTitle: baseMessageTitle,
            baseMessage: baseMessage,
            iconType: BannerAlertIconType.EXCLAMATION,
        });
        commit(MutationTypes.ADD_BANNER_ALERT, bannerAlert);
    },
};
