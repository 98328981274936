import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import ITrailerAssessmentDto from '@/types/ApiDtos/ITrailerAssessmentDto';
import FbaApiError from '@/types/FbaApiError';

export default async function postTrailerAssessment(
    assessmentDto: ITrailerAssessmentDto
): Promise<{ isSuccessful: boolean; assessmentId: number } | FbaApiError> {
    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/trailerAssessment`,
            data: assessmentDto,
        });

        return response.data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
