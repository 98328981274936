import ITrailerAssessmentResponseDto from './ApiDtos/ITrailerAssessmentResponseDto';
import AssessmentDriver from './AssessmentDriverResult';
import TrailerAssessment from './TrailerAssessment';

export default class LastTrailerAssessmentResult {
    id: string;
    trailerAssessment: TrailerAssessment;
    assessmentDriver: AssessmentDriver;
    eventName: string;

    constructor(
        id: string,
        trailerAssessment: TrailerAssessment,
        assessmentDriver: AssessmentDriver,
        eventName: string
    ) {
        this.id = id;
        this.trailerAssessment = trailerAssessment;
        this.assessmentDriver = assessmentDriver;
        this.eventName = eventName;
    }

    static mapFromTrailerAssessmentResponseDto(
        dto: ITrailerAssessmentResponseDto
    ): LastTrailerAssessmentResult {
        return new LastTrailerAssessmentResult(
            dto.id,
            TrailerAssessment.mapFromTrailerAssessmentResponseDto(dto),
            new AssessmentDriver(
                dto.driver?.driverFirstName,
                dto.driver?.driverLastName,
                dto.driver?.driverMobileNumber
            ),
            dto.eventName
        );
    }
}
