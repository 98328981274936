import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import NearbyTrailerResult from '../types/NearbyTrailerResult';
import type FbaApiError from '@/types/FbaApiError';
import Geopoint from '@/types/Geopoint';

export default async function getReservedTrailerByDriverId(
    driverCode: string,
    company: string,
    location?: Geopoint
): Promise<NearbyTrailerResult[] | FbaApiError> {
    try {
        const response = await axios({
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/reservedTrailerForDriver/${driverCode}/${company}`,
            params: {
                ...(location?.lat ? { lat: location?.lat } : {}),
                ...(location?.lng ? { lon: location?.lng } : {}),
            },
        });

        return response.data.map(
            NearbyTrailerResult.mapFromIReservedTrailerDto
        );
    } catch (error) {
        return apiErrorHandler(error);
    }
}
