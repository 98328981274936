export default class Address {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;

    constructor(
        line1: string,
        line2: string,
        city: string,
        state: string,
        zip: string
    ) {
        this.line1 = line1;
        this.line2 = line2;
        this.city = city;
        this.state = state;
        this.zip = zip;
    }
}
