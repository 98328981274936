import { UnauthorizedResponseTypes } from '@/types/ApiDtos/EleosAuthenticationResponseDto';

export default class FbaApiError {
    name: string;
    message: string;
    statusCode: number;
    reasonCode: number;
    unauthorizedResponseTypes: UnauthorizedResponseTypes | undefined =
        undefined;

    constructor(message = 'Unknown Error', reasonCode = -1, statusCode = -1) {
        this.name = 'FbaApiError';
        this.message = message;
        this.statusCode = statusCode;
        this.reasonCode = reasonCode;
    }
}
