import TrailerAssessment from './TrailerAssessment';
import TrailerAssessmentSubType from './TrailerAssessmentSubType';

export default class TrailerAssessmentType {
    type: keyof TrailerAssessment;
    typeHasDamage?: boolean | undefined;
    subTypes: TrailerAssessmentSubType[];

    constructor(type: keyof TrailerAssessment, typHasDamage?: boolean) {
        this.type = type;
        this.typeHasDamage = typHasDamage;
        this.subTypes = [];
    }
}
