import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import globalConstants from '@/shared/globalConstants';
import INearbyTrailerResultDto from '@/types/ApiDtos/INearbyTrailerResultDto';
import FbaApiError from '@/types/FbaApiError';
import Geopoint from '@/types/Geopoint';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';

export default async function getNearbyTrailers(
    company: string,
    truckNumber: string,
    count = globalConstants.nearbyResultListCount,
    excludeCustomer: string | null | undefined,
    truckLocationOverride: Geopoint | undefined
): Promise<Array<NearbyTrailerResult> | FbaApiError> {
    try {
        const locationQueryParam = truckLocationOverride
            ? `&reportedLat=${truckLocationOverride.lat}&reportedLon=${truckLocationOverride.lng}`
            : '';

        const excludeCustomerQueryParam = excludeCustomer
            ? `&excludecustomer=${excludeCustomer}`
            : '';

        const url =
            `${process.env.VUE_APP_EXT_API_URL}api/trailers/availableneartruck/${company}/${truckNumber}`
                .concat(`?limit=${count}`)
                .concat(excludeCustomerQueryParam)
                .concat(locationQueryParam);

        const response = await axios({
            maxBodyLength: Infinity,
            method: 'get',
            url: url,
        });
        return response.data.results.map((dto: INearbyTrailerResultDto) =>
            NearbyTrailerResult.mapFromINearbyTrailerResultDto(dto)
        );
    } catch (error) {
        return apiErrorHandler(error);
    }
}
