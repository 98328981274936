import { DateTime } from 'luxon';
import INearbyTrailerDto from './ApiDtos/INearbyTrailerDto';
import IReservedTrailerDto from './ApiDtos/IReservedTrailerDto';
import { TrailerAvailability } from './TrailerAvailability';
import TrailerReservation from './TrailerReservation';
import Geopoint from './Geopoint';
import ITrailerQrDto from './ApiDtos/ITrailerQrDto';
import globalConstants from '@/shared/globalConstants';

export default class Trailer {
    availability: TrailerAvailability;
    company: string;
    id: string;
    trailerNumber: string;
    sourceLastReported: DateTime;
    status: string;
    cargoStatus?: number;
    reservation?: TrailerReservation;
    location?: Geopoint;
    locationConfidenceRating?: number;
    licensePlateNumber?: string;
    vehicleIdentificationNumber?: string;
    trailerQr?: string;
    type: string;

    constructor(
        availability: TrailerAvailability,
        company: string,
        id: string,
        trailerNumber: string,
        sourceLastReported: string,
        status: string,
        type: string,
        cargoStatus?: number,
        reservation?: TrailerReservation,
        location?: Geopoint,
        locationConfidenceRating?: number,
        licensePlateNumber?: string,
        vehicleIdentificationNumber?: string
    ) {
        this.availability = availability;
        this.company = company;
        this.id = id;
        this.trailerNumber = trailerNumber;
        this.status = status;
        this.sourceLastReported = DateTime.fromISO(sourceLastReported);

        this.cargoStatus = cargoStatus;
        this.location = location;
        this.locationConfidenceRating = locationConfidenceRating;
        this.licensePlateNumber = licensePlateNumber;
        this.vehicleIdentificationNumber = vehicleIdentificationNumber;
        this.reservation = reservation;
        this.type = type;
    }

    static mapFromINearbyTrailerDto(dto: INearbyTrailerDto) {
        return new Trailer(
            dto.availability,
            dto.company,
            dto.id,
            dto.trailerNumber,
            dto.sourceLastReported,
            dto.status,
            dto.type,
            dto.cargoStatus
        );
    }

    static mapFromIReservedTrailerDto(dto: IReservedTrailerDto) {
        return new Trailer(
            TrailerAvailability.noTag,
            dto.company,
            dto.id,
            dto.trailerNumber,
            dto.sourceLastReported,
            dto.status,
            dto.type,
            dto.cargoStatus,
            new TrailerReservation(
                dto.reservation.expiresAt,
                dto.id,
                dto.reservation.driverId,
                {
                    lat: dto.reservation?.trailerLocation?.latitude,
                    lng: dto.reservation?.trailerLocation?.longitude,
                } as Geopoint
            )
        );
    }

    static mapFromITrailerQrDto(dto: ITrailerQrDto) {
        return new Trailer(
            TrailerAvailability.noTag,
            dto.trailerCompany,
            dto.trailerId + '|' + dto.trailerCompany,
            dto.trailerId,
            '',
            dto.trailerStatus,
            ''
        );
    }
}
