import FbaApiError from '@/types/FbaApiError';
import axios from '@/libraries/axios/axiosConfig';
import Trailer from '@/types/Trailer';
import apiErrorHandler from '@/libraries/apiErrorHandler';
import Geopoint from '@/types/Geopoint';
import apmTransactions from '@/libraries/apm/transactions';

export default async function getTrailerById(
    id: string,
    company: string
): Promise<Trailer | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/getTrailerById/${id}/${company}`,
        });
        if (response?.data?.trailerId) {
            const { data } = response;

            return new Trailer(
                data.availability,
                data.trailerCompany,
                `${data.trailerId}|${data.trailerCompany}`,
                data.trailerId,
                '',
                data.status,
                data.type,
                data.cargoStatus,
                data.reservation,
                { lat: data.latitude, lng: data.longitude } as Geopoint,
                data.locationConfidenceRating,
                data.licensePlateNumber,
                data.vehicleIdentificationNumber
            );
        } else {
            const { data } = response;

            if (data?.isFound === false) {
                const compositeId = id + '|' + company;

                apmTransactions.instrumentTrailerNotFound(
                    data.reasonCode,
                    data.message,
                    compositeId,
                    'trailerId'
                );
            }

            return data as FbaApiError;
        }
    } catch (error) {
        return apiErrorHandler(error);
    }
}
