import { isNullOrUndefined } from '@/shared/helpers';
import Address from '@/types/Address';
import FbaApiError from '@/types/FbaApiError';
import Geopoint from '@/types/Geopoint';

/* eslint-disable @typescript-eslint/no-explicit-any */
let mapService: any;

if (!isNullOrUndefined((<any>window)?.H?.service?.Platform)) {
    const mapPlatform = new (<any>window).H.service.Platform({
        apikey: `${process.env.VUE_APP_ELEOS_DRIVER_FRONTEND_HERE_MAPS_API_KEY}`,
    });

    mapService = mapPlatform.getSearchService();
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export default async function locationReverseGeocode(
    location: Geopoint
): Promise<Address | FbaApiError> {
    const response = await mapService.reverseGeocode({
        at: `${location.lat},${location.lng}`,
    });
    if (response.items.length > 0) {
        const address = response.items[0].address;
        return new Address(
            address.label,
            '',
            address.city,
            address.stateCode,
            address.postalCode
        );
    } else {
        return new FbaApiError(
            `Unable to geocode location (${location.lat}, ${location.lng}) with HERE maps`
        );
    }
}
