import { DateTime } from 'luxon';
import Address from './Address';
import CustomerDto from './ApiDtos/ICustomerDto';

export default class Customer {
    address: Address;
    code: string;
    company: string;
    lastActiveDate: DateTime;
    name: string;
    locationId: string;

    constructor(
        address: Address,
        code: string,
        company: string,
        lastActiveDate: DateTime,
        name: string,
        locationId: string
    ) {
        this.address = address;
        this.code = code;
        this.company = company;
        this.lastActiveDate = lastActiveDate;
        this.name = name;
        this.locationId = locationId;
    }

    static mapFromCustomerDto(dto: CustomerDto): Customer {
        const address = dto.address as Address;
        const lastActiveDate = DateTime.fromISO(dto.lastActiveDate);

        return new Customer(
            address,
            dto.code,
            dto.company,
            lastActiveDate,
            dto.name,
            dto.locationId
        );
    }
}
