import { computed, watch } from 'vue';
import apmTransactions from '@/libraries/apm/transactions';
import { store } from '@/store';
import { MutationTypes } from '@/store/mutationTypes';
import { isNullOrUndefined } from '@/shared/helpers';
import { usePermission } from '@vueuse/core';

export default function useCameraPermission() {
    const cameraPermission = usePermission('camera');

    const isCameraPermissionGranted = computed(
        (): boolean => cameraPermission.value === 'granted'
    );

    const isCameraPermissionPrompt = computed(
        (): boolean => cameraPermission.value === 'prompt'
    );

    const isCameraPermissionDenied = computed(
        (): boolean => cameraPermission.value === 'denied'
    );

    const setHasAccessedCameraView = (hasAccessed: boolean): void => {
        const { isCameraShared } = store.getters.getDeviceCameraInfo;

        store?.commit(MutationTypes.SET_DEVICE_CAMERA_INFO, {
            hasAccessedCamera: hasAccessed,
            isCameraShared,
        });
    };

    const setIsCameraShared = (isShared: boolean): void => {
        const { hasAccessedCamera } = store.getters.getDeviceCameraInfo;

        store?.commit(MutationTypes.SET_DEVICE_CAMERA_INFO, {
            hasAccessedCamera,
            isCameraShared: isShared,
        });
    };

    const shouldAlertOnCameraPermission = (): boolean => {
        const { isCameraShared, hasAccessedCamera } =
            store.getters.getDeviceCameraInfo;
        const isAlertableCameraPermission =
            !isCameraPermissionGranted.value || !isCameraShared;

        return hasAccessedCamera === true && isAlertableCameraPermission;
    };

    const watchCameraAndLogPermissionsStatus = () => {
        watch(cameraPermission, () => {
            if (!isNullOrUndefined(cameraPermission.value)) {
                apmTransactions.instrumentCameraPermissionUpdate(
                    cameraPermission.value as string
                );
            }
        });
    };

    return {
        cameraPermission,
        isCameraPermissionGranted,
        isCameraPermissionPrompt,
        isCameraPermissionDenied,
        watchCameraAndLogPermissionsStatus,
        shouldAlertOnCameraPermission,
        setHasAccessedCameraView,
        setIsCameraShared,
    };
}
