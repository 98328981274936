import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import FbaApiError from '@/types/FbaApiError';
import FeatureFlags from '@/types/FeatureFlags';

export default async function getFeatureFlags(payload: {
    driverCode: string | null;
    company: string | null;
}): Promise<FeatureFlags | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/featureFlags/trailerSolutions`,
            params: {
                driverIdentifier: payload.driverCode,
                company: payload.company,
            },
        });

        return FeatureFlags.mapFromFeatureFlagResponseDto(response.data);
    } catch (error) {
        return apiErrorHandler(error);
    }
}
