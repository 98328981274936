export enum BrowserType {
    SAFARI = 'SAFARI',
    CHROME = 'CHROME',
    UNKNOWN = 'UNKNOWN',
}

export enum OperatingSystemType {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    UNKNOWN = 'UNKNOWN',
}

export enum PermissionsMessagingTypes {
    ANDROID_IN_BROWSER = 'ANDROID_IN_BROWSER',
    ANDROID_IN_PWA = 'ANDROID_IN_PWA',
    IOS_CHROME = 'IOS_CHROME',
    IOS_SAFARI = 'IOS_SAFARI',
    DEFAULT = 'DEFAULT',
}

export class UserAgentDetails {
    browserType = BrowserType.UNKNOWN as BrowserType;
    operatingSystemType = OperatingSystemType.UNKNOWN as OperatingSystemType;
    isRunningPwa = undefined as boolean | undefined;

    constructor(
        browserType: BrowserType,
        operatingSystemType: OperatingSystemType,
        isRunningPwa: boolean
    ) {
        this.browserType = browserType;
        this.operatingSystemType = operatingSystemType;
        this.isRunningPwa = isRunningPwa;
    }

    isAndroidInBrowser(): boolean {
        return (
            this.operatingSystemType === OperatingSystemType.ANDROID &&
            !this.isRunningPwa
        );
    }

    isAndroidInPwa(): boolean {
        return (
            this.operatingSystemType === OperatingSystemType.ANDROID &&
            this.isRunningPwa === true
        );
    }

    isIOSinChrome(): boolean {
        return (
            this.operatingSystemType === OperatingSystemType.IOS &&
            this.browserType === BrowserType.CHROME
        );
    }

    isIOSinSafari(): boolean {
        return (
            this.operatingSystemType === OperatingSystemType.IOS &&
            this.browserType !== BrowserType.CHROME
        );
    }
}
