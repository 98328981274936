import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import { isNullOrUndefined } from '@/shared/helpers';
import ITrailerAssessmentResponseDto from '@/types/ApiDtos/ITrailerAssessmentResponseDto';
import FbaApiError from '@/types/FbaApiError';
import LastTrailerAssessmentResult from '@/types/LastTrailerAssessmentResult';

export default async function getActiveTrailerAssessment(
    trailerId: string,
    trailerCompany: string
): Promise<LastTrailerAssessmentResult | null | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/lastActiveTrailerAssessment/${trailerId}/${trailerCompany}`,
        });

        const { data } = response;
        if (!isNullOrUndefined(data?.trailerAssessment))
            return LastTrailerAssessmentResult.mapFromTrailerAssessmentResponseDto(
                data?.trailerAssessment as ITrailerAssessmentResponseDto
            );
        else return null;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
