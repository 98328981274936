import { DateTime } from 'luxon';
import IPersistentAlertDto from './ApiDtos/IPersistentAlertDto';
import { KnxApp } from './KnxApp';

export default class PersistentAlert {
    id: string | undefined;
    processTimestamp: DateTime | undefined;
    knxApp: KnxApp = KnxApp.Unspecified;
    alertMessage: string | undefined;
    alertMessageDetails: string | undefined;
    effectiveDate: DateTime | undefined;
    keepAlertActiveUntil: DateTime | undefined;

    static mapFromIPersistentAlertDto(
        alertDto: IPersistentAlertDto
    ): PersistentAlert {
        const alert = new PersistentAlert();
        alert.id = alertDto?.id;
        alert.processTimestamp = DateTime.fromISO(alertDto?.processTimestamp);
        alert.knxApp = (alertDto?.knxApp ?? KnxApp.Unspecified) as KnxApp;
        alert.alertMessage = alertDto?.alertMessage;
        alert.alertMessageDetails = alertDto?.alertMessageDetails;
        alert.effectiveDate = DateTime.fromISO(alertDto?.effectiveDate);
        alert.keepAlertActiveUntil = DateTime.fromISO(
            alertDto?.keepAlertActiveUntil
        );

        return alert;
    }
}
