import {
    BrowserType,
    UserAgentDetails,
    OperatingSystemType,
    PermissionsMessagingTypes,
} from '@/types/UserAgentDetails';

const iOSDevices = ['iPhone', 'iPod', 'iPad'];

export default function useUserAgentDetails() {
    const getUserAgentDetails = (): UserAgentDetails => {
        let osType = OperatingSystemType.UNKNOWN;
        let browserType = BrowserType.UNKNOWN;

        const { userAgent } = navigator;
        const androidMatch = /Android/.test(userAgent);
        const iOSMatch = iOSDevices.some((iOSDevice: string) =>
            new RegExp(iOSDevice).test(userAgent)
        );

        if (androidMatch) {
            osType = OperatingSystemType.ANDROID;
        } else if (iOSMatch) {
            osType = OperatingSystemType.IOS;
            browserType = getBrowserTypeForiOS(userAgent);
        }

        const isRunningPwa = window.matchMedia(
            '(display-mode: standalone)'
        ).matches;

        return new UserAgentDetails(browserType, osType, isRunningPwa);
    };

    const getBrowserTypeForiOS = (userAgent: string): BrowserType => {
        const chromeMatch = /CriOS/.test(userAgent);

        if (chromeMatch) {
            return BrowserType.CHROME;
        } else {
            return BrowserType.SAFARI;
        }
    };

    const getSystemMessagingType = () => {
        const uaDetails = getUserAgentDetails();

        if (uaDetails.isAndroidInBrowser()) {
            return PermissionsMessagingTypes.ANDROID_IN_BROWSER;
        } else if (uaDetails.isAndroidInPwa()) {
            return PermissionsMessagingTypes.ANDROID_IN_PWA;
        } else if (uaDetails.isIOSinChrome()) {
            return PermissionsMessagingTypes.IOS_CHROME;
        } else if (uaDetails.isIOSinSafari()) {
            return PermissionsMessagingTypes.IOS_SAFARI;
        } else {
            return PermissionsMessagingTypes.DEFAULT;
        }
    };

    return {
        getSystemMessagingType,
    };
}
