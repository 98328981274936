<template>
    <div class="uix-control uix-textarea-control">
        <div class="uix-control-container">
            <div class="uix-control-groups">
                <div
                    class="uix-control-group"
                    data-input-type="textarea"
                    data-default-placeholder="Textarea Control *"
                    data-small-placeholder="Textarea *"
                    data-alert-type=""
                    data-alert-message=""
                >
                    <textarea
                        v-model="inputModel"
                        :class="{
                            'uix-control-text': true,
                            'has-placeholder-offset': !hasContent,
                        }"
                        name="ControlInputTextarea"
                        autocomplete="new-password"
                        required
                        :placeholder="placeholder"
                        @input="$emit('update:modelValue', $event.target.value)"
                        v-on:blur="onBlur"
                        v-on:change="onChange"
                        v-on:focus="onFocus"
                        v-on:keydown="onKeydown"
                        v-on:keyup="onKeyup"
                        v-on:keypress="onKeypress"
                        :maxlength="maxlength"
                    ></textarea>
                    <a
                        href="javascript:void(0);"
                        @click="onClear()"
                        :class="{ 'uix-control-clear': true, show: hasContent }"
                        title="Clear Value"
                        tabindex="-1"
                        >&times;</a
                    >
                </div>
            </div>
        </div>
        <label
            for="ControlInputTextarea"
            class="uix-control-description"
        ></label>
    </div>
</template>
<script>
import { clone } from 'lodash';
import { isNullOrUndefined, stopPropagation } from '@/shared/helpers';
export default {
    name: 'FbxTextarea',
    components: {},
    props: {
        model: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
            default: -1,
        },
        onBlurEvent: {
            type: Function,
            required: false,
            default: null,
        },
        onChangeEvent: {
            type: Function,
            required: false,
            default: null,
        },
        onFocusEvent: {
            type: Function,
            required: false,
            default: null,
        },
        onKeyupEvent: {
            type: Function,
            required: false,
            default: null,
        },
        onKeydownEvent: {
            type: Function,
            required: false,
            default: null,
        },
        onKeypressEvent: {
            type: Function,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        showClear: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data: function () {
        return {
            inputModel: '',
        };
    },
    created() {
        this.inputModel = clone(this.model || '');
    },
    watch: {
        model: function () {
            this.inputModel = clone(this.model || '');
        },
    },
    computed: {
        hasContent: function () {
            return this.inputModel?.length > 0;
        },
    },
    methods: {
        onClear: function () {
            this.inputModel = '';
            this.$emit('update:modelValue', this.inputModel);
        },
        onKeyup: function (evt) {
            if (!isNullOrUndefined(this.onKeyupEvent)) {
                stopPropagation(evt);
                this.onKeyupEvent.call(this, evt);
                return false;
            }
        },
        onKeydown: function (evt) {
            if (!isNullOrUndefined(this.onKeydownEvent)) {
                stopPropagation(evt);
                this.onKeydownEvent.call(this, evt);
                return false;
            }
        },
        onKeypress: function (evt) {
            if (!isNullOrUndefined(this.onKeypressEvent)) {
                stopPropagation(evt);
                this.onKeypressEvent.call(this, evt);
                return false;
            }
        },
        onChange: function (evt) {
            if (!isNullOrUndefined(this.onChangeEvent)) {
                stopPropagation(evt);
                this.onChangeEvent.call(this, evt);
                return false;
            }
        },
        onFocus: function (evt) {
            if (!isNullOrUndefined(this.onFocusEvent)) {
                stopPropagation(evt);
                this.onFocusEvent.call(this, evt);
                return false;
            }
        },
        onBlur: function (evt) {
            if (!isNullOrUndefined(this.onBlurEvent)) {
                stopPropagation(evt);
                this.onBlurEvent.call(this, evt);
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.uix-control.uix-textarea-control {
    min-height: 180px;

    .uix-control-container {
        min-height: 180px;
        font-family: inherit;
        padding: 0px;
    }
    .uix-control-group {
        height: auto;
        width: 100%;
        z-index: 0;
        .uix-control-sm-label {
            background-color: $uix-shade-white;
            height: 23px;
            right: 8px;
        }
        .uix-control-text {
            height: auto;
            font-family: inherit;
            padding-bottom: 8px;
            font-weight: 500;
            padding-top: 20px;
            padding-left: 12px;
            padding-right: 89px;
            width: calc(100% - 100px);
            resize: none;
            scrollbar-3dlight-color: $uix-shade-white;
            scrollbar-arrow-color: $uix-shade-300;
            scrollbar-base-color: $uix-shade-white;
            scrollbar-face-color: $uix-shade-300;
            scrollbar-highlight-color: $uix-shade-white;
            scrollbar-shadow-color: $uix-shade-white;
            scrollbar-track-color: $uix-shade-white;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                background-color: $uix-shade-white;
                cursor: pointer !important; // TODO: This is not working in all browsers. Figure out another solution to provide visual feedback on hover.
                height: 8px;
                width: 8px;
            }

            &::-webkit-scrollbar-corner {
                background-color: $uix-shade-white;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $uix-shade-300;

                &:hover {
                    background-color: $uix-shade-300;
                    border: 0 solid $uix-shade-300;
                }

                &:active {
                    background-color: $uix-shade-300;
                    border: 0 solid $uix-shade-300;
                }
            }
            &.has-placeholder-offset {
                font-family: inherit;
                padding-top: 1rem; // 16px
                padding-left: 0.75rem;
                padding-right: 0.75rem;
                width: calc(100% - 1.5em);
            }
        }
        // Not spanning full height of control so textarea can be scrolled
        .uix-control-clear {
            bottom: auto;
            min-height: 48px;
            text-decoration: none;
        }
    }
    .uix-control-btn {
        justify-content: normal; // vertically align button text to top
    }
    &.disabled {
        .uix-control-group {
            .uix-control-sm-label {
                background-color: $uix-shade-100;
            }
            .uix-control-text {
                scrollbar-3dlight-color: $uix-shade-100;
                scrollbar-base-color: $uix-shade-100;
                scrollbar-highlight-color: $uix-shade-100;
                scrollbar-shadow-color: $uix-shade-100;
                scrollbar-track-color: $uix-shade-100;

                &::-webkit-scrollbar {
                    background-color: $uix-shade-100;
                    cursor: pointer;
                    height: 8px;
                    width: 8px;
                }
            }
        }
    }
}
</style>
