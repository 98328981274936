import { apm } from './index';
import { Transaction } from '@elastic/apm-rum';
import router from '@/router/index';
import { DateTime } from 'luxon';
import { store } from '@/store';
import Driver from '@/types/Driver';
import { AssociationType } from '@/types/AssociationType';
import Geopoint from '@/types/Geopoint';
import { GetDistanceBetweenPoints } from '@/libraries/haversine/haversine';
import TrailerReservation from '@/types/TrailerReservation';

const instrumentUnhandledApiResponse = function (): void {
    const transaction = apm.getCurrentTransaction();
    transaction?.addLabels({ unhandledApiResponse: true });
};

const instrumentApiTimeout = function (): void {
    const transaction = apm.getCurrentTransaction();
    transaction?.addLabels({ apiTimeout: true });
};

const instrumentTrailerAssociation = function (
    eventId: string,
    attachingTrailerIdentifier = '',
    identifierType: string,
    association: string,
    attachingTrailerLocationId: string | undefined,
    attachingTrailerLocation?: Geopoint | undefined,
    attachingTrailerSelectionMethod?: string | undefined,
    reservedTrailerLocationId?: string | undefined,
    reservedTrailerIdentifier?: string | undefined,
    reservedTrailerLocationWhenReserved?: Geopoint | undefined,
    reservedTrailerCurrentLocation?: Geopoint | undefined
) {
    const isAttachEvent = association === AssociationType.ATTACH;

    const transactionName = isAttachEvent
        ? 'Trailer Attached'
        : 'Trailer Detached';
    const transaction = startTransaction(transactionName);

    if (transaction) {
        transaction.addLabels({
            eventId: eventId ?? '',
            trailerIdentifier: attachingTrailerIdentifier,
            identifierType: identifierType,
            attachedTrailerSelectionMethod:
                attachingTrailerSelectionMethod ?? '',
            attachedTrailerLatitude: attachingTrailerLocation?.lat ?? '',
            attachedTrailerLongitude: attachingTrailerLocation?.lng ?? '',
            attachedTrailerLocationId: attachingTrailerLocationId ?? '',
        });

        if (isAttachEvent) {
            transaction.addLabels({
                hadReservation: reservedTrailerIdentifier ? true : false,
                attachedTrailerWasReserved:
                    attachingTrailerIdentifier == reservedTrailerIdentifier,
            });

            if (reservedTrailerIdentifier) {
                transaction.addLabels({
                    reservedTrailerIdentifier: reservedTrailerIdentifier ?? '',
                    reservedTrailerLatitudeWhenReserved:
                        reservedTrailerLocationWhenReserved?.lat ?? '',
                    reservedTrailerLongitudeWhenReserved:
                        reservedTrailerLocationWhenReserved?.lng ?? '',
                    reservedTrailerCurrentLatitude:
                        reservedTrailerCurrentLocation?.lat ?? '',
                    reservedTrailerCurrentLongitude:
                        reservedTrailerCurrentLocation?.lng ?? '',
                    attachedTrailerAndReservationLocationsAreSame:
                        attachingTrailerLocationId == reservedTrailerLocationId,
                    reservedTrailerLocationId: reservedTrailerLocationId ?? '',
                });

                if (reservedTrailerLocationWhenReserved !== undefined) {
                    if (attachingTrailerLocation !== undefined) {
                        transaction.addLabels({
                            distanceFromReservationLocation:
                                GetDistanceBetweenPoints(
                                    attachingTrailerLocation,
                                    reservedTrailerLocationWhenReserved
                                ),
                        });
                    }

                    if (reservedTrailerCurrentLocation !== undefined) {
                        transaction.addLabels({
                            distanceReservedTrailerMoved:
                                GetDistanceBetweenPoints(
                                    reservedTrailerCurrentLocation,
                                    reservedTrailerLocationWhenReserved
                                ),
                        });
                    }
                }
            }
        }
    }

    endTransaction(transaction);
};

const instrumentTrailerReservationCreation = function (
    eventId: string,
    trailerReservation: TrailerReservation | undefined,
    minutesToDestination: number | undefined,
    milesToDestination: number | undefined
): void {
    if (trailerReservation != undefined && trailerReservation.isActive) {
        const transaction = startTransaction('Trailer Reserved');

        if (transaction) {
            transaction.addLabels({
                eventId: eventId ?? '',
                driverId: trailerReservation.driverId || '',
                reservationExpiration:
                    trailerReservation.expiresAt.toISO() || '',
                reservationTimeRemaining:
                    trailerReservation?.timeRemaining()?.toMillis() /
                        1000 /
                        60 || '',
                reservedTrailerIdentifier: trailerReservation.trailerId || '',
                minutesToReservedDestination: minutesToDestination || '',
                milesToReservedDestination: milesToDestination || '',
            });
        }

        endTransaction(transaction);
    }
};

const instrumentFailedAssociabilityRule = function (
    event: string,
    rules: string[],
    trailerIdentifier = '',
    identifierType: string
): void {
    const transaction = startTransaction('Associability Rule Limit');

    if (transaction) {
        transaction.addLabels({ event: event });
        transaction.addLabels({ rules: rules.join(' ') });
        transaction.addLabels({ trailerIdentifier: trailerIdentifier });
        transaction.addLabels({ identifierType: identifierType });
    }

    endTransaction(transaction);
};

const instrumentDriverFeedbackSubmission = function (
    feedback: string,
    viewName: string
): void {
    const transaction = startTransaction('Issues Reported');

    if (transaction) {
        transaction.addLabels({ driverFeedback: feedback, view: viewName });
    }

    endTransaction(transaction);
};

const instrumentDriverArrivedAtVinVerification = function (
    trailer: string,
    proximity: number
): void {
    const transaction = startTransaction('VIN Verification');

    if (transaction) {
        transaction.addLabels({ trailerId: trailer, proximity: proximity });
    }

    endTransaction(transaction);
};

const instrumentTrailerNotFound = function (
    reasonCode: number,
    message: string,
    trailerIdentifier = '',
    identifierType: string
): void {
    const transaction = startTransaction('Trailer Not Found');

    if (transaction) {
        transaction.addLabels({ reasonCode: reasonCode.toString() });
        transaction.addLabels({ errorMessage: message });
        transaction.addLabels({ trailerIdentifier: trailerIdentifier });
        transaction.addLabels({ identifierType: identifierType });
    }

    endTransaction(transaction);
};

const instrumentTrailerQrNotFound = function (
    reasonCode: number,
    message: string,
    trailerQrIdentifier = '',
    identifierType: string
): void {
    const transaction = startTransaction('Qr Not Found');

    if (transaction) {
        transaction.addLabels({ reasonCode: reasonCode.toString() });
        transaction.addLabels({ errorMessage: message });
        transaction.addLabels({ trailerQrIdentifier: trailerQrIdentifier });
        transaction.addLabels({ identifierType: identifierType });
    }

    endTransaction(transaction);
};

const instrumentUnauthorized = function (
    driverCode: string,
    company: string
): void {
    const transaction = startTransaction('Unauthorized User');

    if (transaction) {
        transaction.addLabels({ unauthorized: 'true' });
        addUserContext(driverCode, company);
    }

    endTransaction(transaction);
};

const instrumentUnhandledError = function (message: string): void {
    const transaction = startTransaction('Unhandled Error');

    if (transaction) {
        transaction.addLabels({ errorMessage: message });
    }

    endTransaction(transaction);
};

export enum GeolocationInfoType {
    DeviceGeolocationWatchBegin,
    DeviceGeolocationWatchEnd,
    DeviceGeolocationFetched,
    DeviceGeolocationUsed,
    TruckGeolocationUsed,
    DeviceGeolocationAccessAllowed,
    DeviceGeolocationAccessDenied,
    DeviceGeolocationError,
}

const instrumentCameraPermissionUpdate = function (permission: string) {
    const transaction = startTransaction('Camera Permission');

    if (transaction) {
        setUserDetails();
        transaction.addLabels({ cameraPermission: permission });
    }

    endTransaction(transaction);
};

const getReadableGeolocationInfoType = function (
    type: GeolocationInfoType
): string {
    switch (type) {
        case GeolocationInfoType.DeviceGeolocationWatchBegin:
            return 'DeviceGeolocationWatchBegin';
        case GeolocationInfoType.DeviceGeolocationWatchEnd:
            return 'DeviceGeolocationWatchEnd';
        case GeolocationInfoType.DeviceGeolocationFetched:
            return 'DeviceGeolocationFetched';
        case GeolocationInfoType.DeviceGeolocationUsed:
            return 'DeviceGeolocationUsed';
        case GeolocationInfoType.TruckGeolocationUsed:
            return 'TruckGeolocationUsed';
        case GeolocationInfoType.DeviceGeolocationAccessAllowed:
            return 'DeviceGeolocationAccessAllowed';
        case GeolocationInfoType.DeviceGeolocationAccessDenied:
            return 'DeviceGeolocationAccessDenied';
        case GeolocationInfoType.DeviceGeolocationError:
            return 'DeviceGeolocationError';
        default:
            return 'Unknown';
    }
};

const instrumentGeolocationInfo = function (
    type: GeolocationInfoType,
    message: string,
    geolocation = undefined as GeolocationPosition | undefined,
    geopoint = undefined as Geopoint | undefined
) {
    const transaction = startTransaction('App Geolocation Info');

    if (transaction && type !== undefined) {
        transaction.addLabels({
            type: getReadableGeolocationInfoType(type),
        });
    }

    if (transaction && message !== undefined) {
        transaction.addLabels({
            message: message,
        });
    }

    if (transaction) {
        if (geolocation?.coords !== undefined) {
            transaction.addLabels({
                accuracy: geolocation.coords.accuracy,
                latitude: geolocation.coords.latitude,
                longitude: geolocation.coords.longitude,
            });
        } else if (geopoint !== undefined) {
            transaction.addLabels({
                latitude: geopoint.lat,
                longitude: geopoint.lng,
            });
        }
    }

    endTransaction(transaction);
};

const startTransaction = function (
    transactionName: string
): Transaction | undefined {
    const currentTime = DateTime.now().toUTC().toMillis();
    const transaction = apm.startTransaction(transactionName, 'custom', {
        startTime: currentTime,
    });
    transaction?.startSpan(transactionName, undefined, {
        startTime: currentTime + 1,
    });
    return transaction;
};

const addUserContext = function (driverCode: string, company: string): void {
    if (driverCode && company) {
        apm.setUserContext({
            id: (driverCode?.toUpperCase() +
                '|' +
                company?.toLowerCase()) as string,
        });
    }
};

const setUserDetails = function () {
    const { driverCode, company } = router.currentRoute.value.params;
    addUserContext(driverCode as string, company as string);

    if (store.getters.getDriver) {
        const { firstName, lastName, truckCode } = store.getters
            .getDriver as Driver;
        apm.setUserContext({
            username: firstName + ' ' + lastName,
        });
        apm.setCustomContext({
            truckCode: truckCode,
        });
    }
};

const endTransaction = function (transaction: Transaction | undefined): void {
    // custom transition MUST have a set, non-0 duration - adding time here to transition gives us a set duration
    transaction?.end(DateTime.now().toUTC().toMillis() + 2);
};

const addMessage = function (
    transaction: Transaction | undefined,
    message: string
): void {
    if (transaction && message) {
        transaction.addLabels({
            message: message,
        });
    }
};

export default {
    instrumentApiTimeout,
    instrumentCameraPermissionUpdate,
    instrumentFailedAssociabilityRule,
    instrumentTrailerAssociation,
    instrumentTrailerNotFound,
    instrumentTrailerQrNotFound,
    instrumentUnauthorized,
    instrumentUnhandledApiResponse,
    instrumentUnhandledError,
    instrumentDriverFeedbackSubmission,
    instrumentDriverArrivedAtVinVerification,
    instrumentGeolocationInfo,
    instrumentTrailerReservationCreation,
    setUserDetails,
};
