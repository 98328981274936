export interface IEleosAuthenticationResponseDto {
    authorizationType: AuthorizationTypes;
    errorMessage: string;
    error: {
        message: string;
    };
    username: string;
    company: string;
    responseType: UnauthorizedResponseTypes;
}

export enum AuthorizationTypes {
    EleosToken,
    SessionGrant,
}

export enum UnauthorizedResponseTypes {
    None,
    Unauthorized,
    NotFound,
    Expired,
}
