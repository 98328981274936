import NearbyTrailerResult from '@/types/NearbyTrailerResult';
import Trailer from '@/types/Trailer';
import { isNullOrUndefined } from '@/shared/helpers';
import { NearbyTrailerResultType } from '@/types/NearbyTrailerResultType';

export function cleanDuplicateTrailers(results: Array<NearbyTrailerResult>) {
    const trailerIds: string[] = [];

    for (const trailerResult of results) {
        const trailers: Trailer[] = [];

        for (const trailer of trailerResult.trailers) {
            const index = trailerIds.findIndex((id) => id === trailer.id);

            if (index === -1) {
                trailers.push(trailer);
                trailerIds.push(trailer.id);
            }
        }

        trailerResult.trailers = trailers;
    }
}

export function mergeSameCustomer(
    results: Array<NearbyTrailerResult>,
    reservedTrailerNumber: string
) {
    const reservedIndex = results.findIndex(
        (item) =>
            !isNullOrUndefined(
                item.trailers.find(
                    (trailer) => trailer.trailerNumber === reservedTrailerNumber
                )
            ) && item.type === NearbyTrailerResultType.customerLocation
    );

    if (reservedIndex > -1) {
        const reservedRow = results[reservedIndex];

        results.map((item, index) => {
            if (
                index !== reservedIndex &&
                item.locationName === reservedRow.locationName
            ) {
                reservedRow.trailers = reservedRow.trailers.concat(
                    item.trailers
                );
                reservedRow.geofenceIds = item.geofenceIds;
                item.trailers = [];
            }
        });
    }
}
