import authorizeDriverCookie from './authorizeDriverCookie';
import createNavigationTask from './createNavigationTask';
import deleteActiveNavigationTask from './deleteActiveNavigationTask';
import getActiveTrailerAssessment from './getLastActiveTrailerAssessment';
import getAttachedTrailersByTruckId from './getAttachedTrailersByTruckId';
import getCurrentNavigationDestination from './getActiveNavigationDestination';
import getCustomerByLocation from './getCustomerByLocation';
import getDriverByDriverCodeAndCompany from './getDriverByDriverCodeAndCompany';
import getDriverByDriverOrTruckCodeAndCompany from './getDriverByDriverOrTruckCodeAndCompany';
import getFeatureFlags from './getFeatureFlags';
import getNearbyTrailers from './getNearbyTrailers';
import getReservedTrailerByDriverId from './GetReservedTrailerByDriverId';
import getTrailerById from './getTrailerById';
import getTrailerByQrId from './getTrailerByQrId';
import getTrailersAtCustomer from './getTrailersAtCustomer';
import getTruckById from './getTruckById';
import postTrailerAssessment from './postTrailerAssessment';
import getActiveLoadByLoadNumber from './getActiveLoadByLoadNumber';
import updateDriverPta from './updateDriverPta';
import postDeactivateTrailerAssessment from './postDeactivateTrailerAssessment';
import getAssociabilityEvaluation from './getAssociabilityEvaluation';
import postDriverFeedback from './postDriverFeedback';
import postReportQrUnassociated from './postReportQrTrailerUnassociated';
import * as trailerAssociations from './trailerAssociations';
import getPersistentAlert from './getPersistentAlert';

export default {
    authorizeDriverCookie: authorizeDriverCookie,
    createNavigationTask: createNavigationTask,
    deleteActiveNavigationTask: deleteActiveNavigationTask,
    getActiveLoadByLoadNumber: getActiveLoadByLoadNumber,
    getActiveTrailerAssessment: getActiveTrailerAssessment,
    getAttachedTrailersByTruckId: getAttachedTrailersByTruckId,
    getCurrentNavigationDestination: getCurrentNavigationDestination,
    getCustomerByLocation: getCustomerByLocation,
    getDriverByDriverCodeAndCompany: getDriverByDriverCodeAndCompany,
    getDriverByDriverOrTruckCodeAndCompany:
        getDriverByDriverOrTruckCodeAndCompany,
    getAssociabilityEvaluation: getAssociabilityEvaluation,
    getFeatureFlags: getFeatureFlags,
    ...trailerAssociations,
    getNearbyTrailers: getNearbyTrailers,
    getReservedTrailerByDriverId: getReservedTrailerByDriverId,
    getTrailerById: getTrailerById,
    getTrailerByQrId: getTrailerByQrId,
    getTrailersAtCustomer: getTrailersAtCustomer,
    getTruckById: getTruckById,
    postTrailerAssessment: postTrailerAssessment,
    updateDriverPta: updateDriverPta,
    postDeactivateTrailerAssessment: postDeactivateTrailerAssessment,
    postDriverFeedback: postDriverFeedback,
    postReportQrUnassociated: postReportQrUnassociated,
    getPersistentAlert: getPersistentAlert,
};
