import { FeedbackTypes } from './FeedbackTypes';
import Geopoint from './Geopoint';
import { PartyType } from './PartyTypes';

export default class DriverFeedback {
    feedbackType: FeedbackTypes;
    driverCode: string;
    username: string;
    location: string;
    availableOptions: string[];
    timestamp: string;
    trailerId: string | null;
    customerName: string | null;
    partyType: PartyType | null | undefined;
    mcDotNumber: string | null;
    truckNumber: string | null;
    driverLocation: string | null;
    phoneNumber: string | null;
    viewName: string;
    company: string;

    constructor(
        feedbackType: FeedbackTypes,
        driverId: string,
        username: string,
        location: string,
        availableOptions: string[],
        timestamp: string,
        trailerId: string | null,
        customerName: string | null,
        partyType: PartyType | null | undefined,
        mcDotNumber: string | null,
        truckNumber: string | null,
        driverLocation: string | null,
        phoneNumber: string | null,
        viewName: string,
        company: string
    ) {
        this.feedbackType = feedbackType;
        this.driverCode = driverId;
        this.username = username;
        this.location = location;
        this.availableOptions = availableOptions;
        this.timestamp = timestamp;
        this.trailerId = trailerId;
        this.customerName = customerName;
        this.partyType = partyType;
        this.mcDotNumber = mcDotNumber;
        this.truckNumber = truckNumber;
        this.driverLocation = driverLocation;
        this.phoneNumber = phoneNumber;
        this.viewName = viewName;

        this.company = company;
    }
}
