import Driver from '@/types/Driver';
import Load from '@/types/Load';
import { DateTime } from 'luxon';

const PAST_PTA = DateTime.now().minus({ minutes: 60 });
const FUTURE_PTA = DateTime.now().plus({ minutes: 60 });
const EDT_BEFORE_PTA = FUTURE_PTA.minus({ minutes: 30 });
const EDT_AFTER_PTA = FUTURE_PTA.plus({ minutes: 30 });

const PAYLOADS: any = {
    NO_ACTIVE_LOAD__PTA_FUTURE: {
        driver: { pta: FUTURE_PTA.toISO() },
        activeLoad: null,
    },
    NO_ACTIVE_LOAD__PTA_PAST: {
        driver: { pta: PAST_PTA.toISO() },
        activeLoad: null,
    },
    ACTIVE_LOAD__PTA_FUTURE__PTA_BEFORE_EDT: {
        driver: { pta: FUTURE_PTA.toISO() },
        activeLoad: {
            id: '12345',
            destination: {
                earliestArrivalTime: EDT_AFTER_PTA.toISO(),
                latestArrivalTime: EDT_AFTER_PTA.plus({ minutes: 90 }).toISO(),
                eventType: 'Live Unload',
            },
        },
    },
    ACTIVE_LOAD__PTA_FUTURE__PTA_AFTER_EDT: {
        driver: { pta: FUTURE_PTA.toISO() },
        activeLoad: {
            id: '12345',
            destination: {
                earliestArrivalTime: EDT_BEFORE_PTA.toISO(),
                latestArrivalTime: EDT_BEFORE_PTA.plus({ minutes: 90 }).toISO(),
                eventType: 'Live Unload',
            },
        },
    },
    ACTIVE_LOAD__PTA_PAST: {
        driver: { pta: PAST_PTA.toISO() },
        activeLoad: {
            id: '12345',
            destination: {
                earliestArrivalTime: EDT_BEFORE_PTA.toISO(),
                latestArrivalTime: EDT_BEFORE_PTA.plus({ minutes: 90 }).toISO(),
                eventType: 'Live Unload',
            },
        },
    },
};

export function getMockDriverData() {
    return PAYLOADS[process.env.VUE_APP_TEST_MODE]?.driver;
}

export function getMockLoadData() {
    return PAYLOADS[process.env.VUE_APP_TEST_MODE]?.activeLoad;
}
