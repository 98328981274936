import BaseMapPoint from './BaseMapPoint';
import Geopoint from './Geopoint';
import NearbyTrailerResult from './NearbyTrailerResult';

export default class CustomerMapPoint implements BaseMapPoint {
    location: Geopoint;
    icon: HTMLElement;
    data: NearbyTrailerResult;

    constructor(location: Geopoint, data: NearbyTrailerResult) {
        this.location = location;
        this.data = data;
        this.icon = this.getIcon();
    }

    getIcon() {
        const element = document.createElement('div');
        element.classList.add('customer-icon');
        element.classList.add('map-icon');
        if (this.data.trailers.length >= 100)
            element.innerHTML = '<span style="font-size:14px;">99+</span>';
        else element.innerHTML = this.data.trailers.length.toString();
        return element;
    }
}
