import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import FbaApiError from '@/types/FbaApiError';
import Load from '@/types/Load';

export default async function getActiveLoadByLoadNumber(
    loadNumber: string
): Promise<Load | null | FbaApiError> {
    try {
        const response = await axios({
            url: `${process.env.VUE_APP_EXT_API_URL}api/loads/${loadNumber}`,
        });

        if (response.data === null) {
            return null;
        }

        return Load.mapFromLoadDto(response.data);
    } catch (error) {
        return apiErrorHandler(error);
    }
}
