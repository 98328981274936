<template>
    <button
        :name="name"
        :data-transaction-name="name"
        type="button"
        :class="buttonClasses"
        :disabled="disabled"
        @click="$emit('clicked')"
    >
        <span class="btn-text" :data-transaction-name="name" :name="name">
            {{ buttonText }}<span v-if="showLoading" class="loading"></span>
        </span>
    </button>
</template>

<script>
import BaseStyleControl from '../styles/baseStyleControl.js';
const buttonThemes = BaseStyleControl.buttonThemes;

export default {
    name: 'FbxButton',
    props: {
        showLoading: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Array,
            default: null,
        },
        overrideClass: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'fbx-button',
        },
        theme: {
            type: String,
            default: 'primary',
            validator(theme) {
                if (!buttonThemes.includes(theme)) {
                    console.warn(
                        `fbx-button has invalid theme - ${theme}. valid themes are ${buttonThemes}`
                    );
                    return false;
                } else {
                    return true;
                }
            },
        },
    },
    emits: ['clicked'],
    computed: {
        buttonClasses() {
            let retVal = '';

            if (this.theme == 'link') {
                retVal = 'btn btn-link';
            } else {
                if (this.overrideClass) {
                    retVal = this.overrideClass;
                } else {
                    retVal = BaseStyleControl.getButtonClassesForTheme(
                        this.theme
                    );
                }
            }

            if (this.disabled === true) {
                retVal += ' disabled';
            }

            return retVal;
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.btn-text {
    position: relative;

    .loading {
        bottom: -2px;
        font-size: 1rem;
        line-height: 0;
        padding-left: 1px;
        position: absolute;

        &::after {
            animation: ellipsis steps(4, end) 900ms infinite;
            -webkit-animation: ellipsis steps(4, end) 900ms infinite;
            content: '\2026'; /* ascii code for the ellipsis character */
            display: inline-block;
            height: 10px;
            overflow: hidden;
            vertical-align: bottom;
            width: 0px;
        }
    }
}

.italics {
    font-style: italic !important;
}
.wide {
    width: 100%;
}
</style>
