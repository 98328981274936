import globalConstants from '@/shared/globalConstants';
import Geopoint from '../../types/Geopoint';

const KmToMilesMultiplier =
    1000 / globalConstants.distanceConversions.metersPerMile; //0.6213711

//Earth's radius varies from 6357 at poles to 6378 at the equator.
//This number is the Mean radius https://en.wikipedia.org/wiki/Earth_radius#Mean_radius
const RadiusOfEarthInKM = 6371.0088;
const RadiusOfEarthInMiles = RadiusOfEarthInKM * KmToMilesMultiplier;

export enum UnitOfMeasurement {
    Miles,
    Km,
}

/// <summary>
/// GetDistanceBetweenPoints - Uses the Haversine Function to get the distance in KM between two points
/// </summary>
/// <param name="firstPoint">Knx.AssetTracker.Domain.Entities.GeoLocation</param>
/// <param name="secondPoint">Knx.AssetTracker.Domain.Entities.GeoLocation</param>
/// <param name="milesOrKm">Unit of Measure to Return, Defaults to Miles</param>
/// <returns>Distance in Km as a Double</returns>
export function GetDistanceBetweenPoints(
    firstPoint: Geopoint,
    secondPoint: Geopoint,
    milesOrKm: UnitOfMeasurement = UnitOfMeasurement.Miles
) {
    const earthRad =
        milesOrKm == UnitOfMeasurement.Miles
            ? RadiusOfEarthInMiles
            : RadiusOfEarthInKM;
    const lat1Rad = degreesToRadians(firstPoint.lat);
    const lat2Rad = degreesToRadians(secondPoint.lat);
    const lon1Rad = degreesToRadians(firstPoint.lng);
    const lon2Rad = degreesToRadians(secondPoint.lng);

    const deltaLatRad = Math.sin((lat2Rad - lat1Rad) * 0.5);
    const deltaLonRad = Math.sin((lon2Rad - lon1Rad) * 0.5);

    const a =
        Math.pow(deltaLatRad, 2) +
        Math.pow(deltaLonRad, 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
    return earthRad * 2 * Math.asin(Math.sqrt(a));
}

function degreesToRadians(deg: number) {
    return (deg * Math.PI) / 180.0;
}
