import { State, state as initialState } from './state';
import Truck from '@/types/Truck';
import Driver from '@/types/Driver';
import User from '@/types/User';
import { MutationTypes } from './mutationTypes';
import { MutationTree } from 'vuex';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';
import Customer from '@/types/Customer';
import FbaApiError from '@/types/FbaApiError';
import TrailerAssessment from '@/types/TrailerAssessment';
import Trailer from '@/types/Trailer';
import Load from '@/types/Load';
import { DateTime } from 'luxon';
import LastTrailerAssessmentResult from '@/types/LastTrailerAssessmentResult';
import TrailerAssessmentMeta from '@/types/TrailerAssessmentMeta';
import FeatureFlags from '@/types/FeatureFlags';
import {
    BannerAlert,
    BannerAlertType,
    LocationBeggingModalOptions,
} from './types';
import { NearbyTrailerResultType } from '@/types/NearbyTrailerResultType';
import DeviceInfo from '@/types/DeviceInfo';
import DeviceCameraInfo from '@/types/DeviceCameraInfo';
import Geopoint from '../types/Geopoint';
import { WorkflowTypes } from '@/types/WorkflowTypes';
import { TrailerSelectionMethod } from '@/types/TrailerSelectionMethod';

export type Mutations<S = State> = {
    [MutationTypes.SET_CURRENT_NAVIGATION_DESTINATION](
        state: S,
        payload: string | null | undefined
    ): void;
    [MutationTypes.SET_CURRENT_RESERVATION](
        state: S,
        payload: State['currentTrailerReservation']
    ): void;
    [MutationTypes.SET_CUSTOMER_AT_CURRENT_LOCATION](
        state: S,
        payload: Customer | null
    ): void;
    [MutationTypes.SET_FEATURE_FLAGS](
        state: S,
        payload: FeatureFlags | null
    ): void;
    [MutationTypes.SET_NEARBY_TRAILER_RESULTS](
        state: S,
        payload: Array<NearbyTrailerResult> | undefined
    ): void;
    [MutationTypes.SET_TRAILERS_AT_CURRENT_LOCATION](
        state: S,
        payload: Array<NearbyTrailerResult>
    ): void;
    [MutationTypes.SET_TRUCK](state: S, payload: Truck | null): void;
    [MutationTypes.SET_USER](state: S, payload: User): void;
    [MutationTypes.SET_DRIVER](state: S, payload: Driver | null): void;
    [MutationTypes.SET_ACTIVE_LOAD](state: S, payload: Load | null): void;
    [MutationTypes.SET_ERROR](state: S, payload: FbaApiError): void;

    [MutationTypes.SET_DEVICE_INFO](state: S, payload: DeviceInfo): void;
    [MutationTypes.SET_DEVICE_CAMERA_INFO](
        state: S,
        payload: DeviceCameraInfo
    ): void;

    [MutationTypes.SET_LAST_ACTIVE_TRAILER_ASSESSMENT_RESULT](
        state: S,
        payload: LastTrailerAssessmentResult | null | undefined
    ): void;
    [MutationTypes.SET_IS_RUNNING_IN_EXTERNAL_BROWSER](
        state: S,
        payload: boolean
    ): void;
    [MutationTypes.UPDATE_TRAILER_ASSESSMENT](
        state: S,
        payload: TrailerAssessment
    ): void;
    [MutationTypes.UPDATE_TRAILER_ASSESSMENT_META](
        state: S,
        payload: TrailerAssessmentMeta
    ): void;
    [MutationTypes.CLEAR_TRAILER_ASSESSMENT](state: S): void;
    [MutationTypes.UPDATE_SELECTED_TRAILER](state: S, payload: Trailer): void;
    [MutationTypes.CLEAR_SELECTED_TRAILER](state: S): void;
    [MutationTypes.UPDATE_TRAILER_SELECTION_METHOD](
        state: S,
        payload: TrailerSelectionMethod
    ): void;
    [MutationTypes.CLEAR_TRAILER_SELECTION_METHOD](state: S): void;
    [MutationTypes.SET_NETWORK_TIMEOUT](state: S, payload: boolean): void;
    [MutationTypes.CLEAR_ERROR](state: S): void;
    [MutationTypes.UPDATE_DRIVER_PTA](state: S, payload: DateTime): void;
    [MutationTypes.SET_EXCLUDE_CUSTOMER](
        state: S,
        payload: string | null | undefined
    ): void;
    [MutationTypes.SET_IS_LOADING](state: S, payload: boolean): void;
    [MutationTypes.RESET_STATE](state: S): void;
    [MutationTypes.ADD_BANNER_ALERT](state: S, payload: BannerAlert): void;
    [MutationTypes.REMOVE_BANNER_ALERT](state: S, payload: number): void;
    [MutationTypes.REMOVE_BANNER_ALERTS_OF_TYPE](
        state: S,
        payload: BannerAlertType
    ): void;
    [MutationTypes.SET_PINNED_LOCATION](
        state: S,
        payload: Geopoint | undefined
    ): void;
    [MutationTypes.SHOW_LOCATION_BEGGING_MODAL](
        state: S,
        payload: LocationBeggingModalOptions
    ): void;
    [MutationTypes.SET_BANNER_ALERT_MAINTENANCE_DISPLAYED](
        state: S,
        payload: boolean
    ): void;
    [MutationTypes.SET_WORKFLOW_ORIGIN](
        state: S,
        payload: WorkflowTypes | undefined
    ): void;
    [MutationTypes.SET_PERMISSIONS_STEPS_GUIDE_SHOWN](
        state: S,
        payload: boolean
    ): void;
};

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_CURRENT_RESERVATION](state, payload) {
        state.currentTrailerReservation = payload;
    },
    [MutationTypes.SET_CURRENT_NAVIGATION_DESTINATION](state, payload) {
        state.currentNavigationDestination = payload;
    },
    [MutationTypes.SET_CUSTOMER_AT_CURRENT_LOCATION](state, payload) {
        state.customerAtCurrentLocation = payload;
    },
    [MutationTypes.SET_FEATURE_FLAGS](state, payload) {
        state.featureFlags = payload;
    },
    [MutationTypes.SET_NEARBY_TRAILER_RESULTS](state, payload) {
        state.nearbyTrailerResults = payload;
    },
    [MutationTypes.SET_TRAILERS_AT_CURRENT_LOCATION](state, payload) {
        state.trailersAtCurrentLocation = payload.filter(
            (trl) =>
                trl.type != NearbyTrailerResultType.noResultsAtCustomerLocation
        );
        state.nearbyTrailerResultsDeniedResponses = payload.filter(
            (trl) =>
                trl.type == NearbyTrailerResultType.noResultsAtCustomerLocation
        );
    },
    [MutationTypes.SET_TRUCK](state, payload) {
        state.truck = payload;
    },
    [MutationTypes.SET_USER](state, payload) {
        state.user = payload;
    },
    [MutationTypes.SET_DRIVER](state, payload) {
        state.driver = payload;
    },
    [MutationTypes.SET_ACTIVE_LOAD](state, payload) {
        state.activeLoad = payload;
    },
    [MutationTypes.SET_ERROR](state, payload) {
        state.error = payload;
    },
    [MutationTypes.SET_DEVICE_INFO](state, payload) {
        const oldWatcher = state.deviceInfo?.deviceLocationWatcher;
        const newWatcher = payload.deviceLocationWatcher;

        if (
            oldWatcher !== undefined &&
            newWatcher !== undefined &&
            oldWatcher?.watcherId != newWatcher?.watcherId
        ) {
            oldWatcher.stopWatching();
        } else if (oldWatcher?.watcherId !== undefined) {
            oldWatcher.stopWatching();
        }

        state.deviceInfo = payload;
    },
    [MutationTypes.SET_DEVICE_CAMERA_INFO](state, payload) {
        state.deviceCameraInfo = payload;
    },
    [MutationTypes.SET_LAST_ACTIVE_TRAILER_ASSESSMENT_RESULT](state, payload) {
        state.lastActiveTrailerAssessmentResult = payload;
    },
    [MutationTypes.SET_IS_RUNNING_IN_EXTERNAL_BROWSER](state, payload) {
        state.isRunningInExternalBrowser = payload;
    },
    [MutationTypes.UPDATE_TRAILER_ASSESSMENT](state, payload) {
        state.trailerAssessment = payload;
    },
    [MutationTypes.UPDATE_TRAILER_ASSESSMENT_META](state, payload) {
        state.trailerAssessmentMeta = payload;
    },
    [MutationTypes.CLEAR_TRAILER_ASSESSMENT](state) {
        state.trailerAssessment = undefined;
        state.trailerAssessmentMeta = undefined;
    },

    [MutationTypes.UPDATE_SELECTED_TRAILER](state, payload) {
        state.selectedTrailer = payload;
    },
    [MutationTypes.CLEAR_SELECTED_TRAILER](state) {
        state.selectedTrailer = undefined;
    },

    [MutationTypes.UPDATE_TRAILER_SELECTION_METHOD](state, payload) {
        state.trailerSelectionMethod = payload;
    },
    [MutationTypes.CLEAR_TRAILER_SELECTION_METHOD](state) {
        state.trailerSelectionMethod = undefined;
    },

    [MutationTypes.SET_NETWORK_TIMEOUT](state, payload) {
        state.networkTimeout = payload;
    },

    [MutationTypes.CLEAR_ERROR](state) {
        state.error = undefined;
    },

    [MutationTypes.UPDATE_DRIVER_PTA](state, payload) {
        if (!state.driver) {
            return;
        }

        state.driver.pta = payload;
    },
    [MutationTypes.SET_EXCLUDE_CUSTOMER](state, payload) {
        state.excludeCustomer = payload;
    },

    [MutationTypes.SET_IS_LOADING](state, payload) {
        state.isLoading = payload;
    },
    [MutationTypes.RESET_STATE](state) {
        Object.assign(state, initialState);
    },
    [MutationTypes.ADD_BANNER_ALERT](state, payload: BannerAlert) {
        if (
            state.bannerAlertMessages.find(
                (message: BannerAlert) =>
                    message.getMessageId() === payload.getMessageId()
            ) === undefined
        ) {
            state.bannerAlertMessages.push(payload);
            state.bannerAlertMessages.sort(
                (alert1: BannerAlert, alert2: BannerAlert) => {
                    const alert1Priority = alert1.getPriority();
                    const alert2Priority = alert2.getPriority();

                    //Invert the order to sort descending.
                    return alert2Priority - alert1Priority;
                }
            );
        }
    },
    [MutationTypes.REMOVE_BANNER_ALERT](state, payload: number) {
        state.bannerAlertMessages = state.bannerAlertMessages.filter(
            (message: BannerAlert) => message.getMessageId() !== payload
        );
    },
    [MutationTypes.REMOVE_BANNER_ALERTS_OF_TYPE](
        state,
        payload: BannerAlertType
    ) {
        state.bannerAlertMessages = state.bannerAlertMessages.filter(
            (message: BannerAlert) => message.getAlertType() !== payload
        );
    },
    [MutationTypes.CLEAR_ALL_BANNER_ALERTS](state) {
        state.bannerAlertMessages = [];
    },
    [MutationTypes.SET_PINNED_LOCATION](state, payload: Geopoint | undefined) {
        state.pinnedBestAvailableLocation = payload;
    },
    [MutationTypes.SHOW_LOCATION_BEGGING_MODAL](
        state,
        payload: LocationBeggingModalOptions
    ) {
        state.locationBeggingModalShown = payload.isModalShown();
    },
    [MutationTypes.SET_BANNER_ALERT_MAINTENANCE_DISPLAYED](
        state,
        payload: boolean
    ) {
        state.bannerAlertMaintenanceDisplayed = payload;
    },
    [MutationTypes.SET_WORKFLOW_ORIGIN](state, payload) {
        state.workflowOrigin = payload;
    },
    [MutationTypes.SET_PERMISSIONS_STEPS_GUIDE_SHOWN](state, payload) {
        state.permissionsStepsGuideShown = payload;
    },
};
