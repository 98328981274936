<template>
  <div class="overlay-container">
    <div class="overlay-wrapper">
      <div class="overlay-card-container">
        <div class="overlay-card-content">
          <div class="title">Invalid QR</div>
          <div class="message">Please scan another</div>
          <div class="cta-container">
            <FbxButton
              button-text="Close"
              @click="() => $emit('close')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FbxButton from './fbx-button.vue';

export default defineComponent({
  emits: ['close'],
  components: {
    FbxButton
  },
})
</script>

<style scoped lang="scss">
.overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.overlay-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto 16px;
}

.overlay-card-container {
  border-radius: 8px;
  background-color: $uix-shade-white;
  display: flex;
  width: 100%;
  max-width: 398px;
}

.overlay-card-content {
  margin: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
  font-family: 'Montserrat', 'Open Sans', Avenir, Helvetica, Arial, sans-serif;
}

.message {
  font-size: 15px;
  font-weight: 400;
  font-family: 'Open Sans', 'Montserrat', Avenir, Helvetica, Arial, sans-serif;
}

.cta-container {
  width: 100%;
  margin-top: 40px;
  display: flex;
}
</style>