import Customer from '@/types/Customer';
import Driver from '@/types/Driver';
import FbaApiError from '@/types/FbaApiError';
import NearbyTrailerResult from '@/types/NearbyTrailerResult';
import TrailerAssessmentMeta from '@/types/TrailerAssessmentMeta';
import TrailerAssessment from '@/types/TrailerAssessment';
import Truck from '@/types/Truck';
import Trailer from '@/types/Trailer';
import TrailerReservation from '@/types/TrailerReservation';
import User from '@/types/User';
import Load from '@/types/Load';
import LastTrailerAssessmentResult from '@/types/LastTrailerAssessmentResult';
import FeatureFlags from '@/types/FeatureFlags';
import { BannerAlert } from './types';
import DeviceInfo from '@/types/DeviceInfo';
import DeviceCameraInfo from '@/types/DeviceCameraInfo';
import Geopoint from '@/types/Geopoint';
import { WorkflowTypes } from '@/types/WorkflowTypes';
import { TrailerSelectionMethod } from '@/types/TrailerSelectionMethod';

export const state = {
    currentNavigationDestination: undefined as string | null | undefined,
    customerAtCurrentLocation: undefined as Customer | null | undefined,
    currentTrailerReservation: undefined as
        | TrailerReservation
        | null
        | undefined,
    nearbyTrailerResults: undefined as Array<NearbyTrailerResult> | undefined,
    acceptedTrailerHasBodyDamage: undefined as boolean | undefined,
    trailersAtCurrentLocation: undefined as
        | Array<NearbyTrailerResult>
        | undefined,
    nearbyTrailerResultsDeniedResponses: undefined as
        | Array<NearbyTrailerResult>
        | undefined,
    trailerAssessment: undefined as TrailerAssessment | undefined,
    trailerAssessmentMeta: undefined as TrailerAssessmentMeta | undefined,
    lastActiveTrailerAssessmentResult: undefined as
        | LastTrailerAssessmentResult
        | null
        | undefined,
    selectedTrailer: undefined as Trailer | undefined,
    trailerSelectionMethod: undefined as TrailerSelectionMethod | undefined,
    truck: undefined as Truck | undefined | null,
    driver: undefined as Driver | undefined | null,
    activeLoad: undefined as Load | null | undefined,
    error: undefined as FbaApiError | undefined,
    user: undefined as User | undefined,
    networkTimeout: false as boolean,
    excludeCustomer: undefined as string | null | undefined,
    isLoading: false as boolean,
    featureFlags: undefined as FeatureFlags | null | undefined,
    bannerAlertMessages: [] as Array<BannerAlert>,
    bannerAlertMaintenanceDisplayed: undefined as boolean | undefined,
    deviceInfo: undefined as DeviceInfo | undefined,
    deviceCameraInfo: new DeviceCameraInfo() as DeviceCameraInfo,
    pinnedBestAvailableLocation: undefined as Geopoint | undefined,
    permissionsStepsGuideShown: false as boolean,
    locationBeggingModalShown: undefined as boolean | undefined,
    isRunningInExternalBrowser: false as boolean,
    workflowOrigin: undefined as WorkflowTypes | undefined,
};

export type State = typeof state;
