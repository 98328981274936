import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import Driver from '@/types/Driver';
import DriverDto from '@/types/ApiDtos/IDriverDto';
import FbaApiError from '@/types/FbaApiError';
import { getMockDriverData } from './mock';

export default async function getDriverByDriverCodeAndCompany(
    company: string,
    driverCode: string
): Promise<Driver | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/drivers/AssetDriverByDriverCodeAndCompany/${driverCode}/${company}`,
        });

        Object.assign(response.data, getMockDriverData());
        return Driver.mapFromDriverDto(response.data as DriverDto);
    } catch (error) {
        return apiErrorHandler(error);
    }
}
