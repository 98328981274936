import { isNullOrUndefined } from '@/shared/helpers';
import { DateTime } from 'luxon';
import Address from './Address';
import Geopoint from './Geopoint';

export default class Truck {
    company: string;
    id: string;
    location: Geopoint;
    latitude: number;
    longitude: number;
    truckId: string;
    telemetryLastReported: DateTime | undefined;
    address: Address | undefined;

    constructor(
        id: string,
        location: Geopoint,
        telemetryLastReported?: string,
        address?: Address
    ) {
        this.id = id;
        this.location = location;
        this.company = id.split('|')[1];
        this.latitude = location.lat;
        this.longitude = location.lng;
        this.truckId = id.split('|')[0];
        if (!isNullOrUndefined(telemetryLastReported)) {
            this.telemetryLastReported = DateTime.fromISO(
                <string>telemetryLastReported
            );
        }
        if (address instanceof Address) {
            this.address = address;
        }
    }

    setAddress(address: Address) {
        if (
            !(address instanceof Address) ||
            isNullOrUndefined(address.city) ||
            isNullOrUndefined(address.state)
        ) {
            this.address = undefined;
        }
        this.address = address;
    }
}
