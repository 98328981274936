import { getCurrentLocation } from '@/shared/helpers';
import apmTransactions, {
    GeolocationInfoType,
} from '@/libraries/apm/transactions';

export default class DeviceLocationWatcher {
    watcherId = undefined as number | undefined;
    options = undefined as PositionOptions | undefined;

    constructor(
        onSuccess: PositionCallback,
        onError: PositionErrorCallback,
        options?: PositionOptions,
        interval?: number
    ) {
        if (navigator.geolocation) {
            this.options = options;
            interval ??= 15000;
            let isWaitingForLocation = false;

            apmTransactions.instrumentGeolocationInfo(
                GeolocationInfoType.DeviceGeolocationWatchBegin,
                `Beginning to watch device location in ${
                    this.options?.enableHighAccuracy ? 'high' : 'low'
                } accuracy mode.`
            );

            this.watcherId = setInterval(() => {
                if (isWaitingForLocation) return;
                isWaitingForLocation = true;

                return getCurrentLocation(options)
                    .then((pos: GeolocationPosition) => {
                        apmTransactions.instrumentGeolocationInfo(
                            GeolocationInfoType.DeviceGeolocationFetched,
                            `Geolocation fetched in ${
                                this.options?.enableHighAccuracy
                                    ? 'high'
                                    : 'low'
                            } accuracy mode.`,
                            pos
                        );

                        onSuccess(pos);
                    })
                    .catch((error: GeolocationPositionError) => {
                        onError(error);
                    })
                    .finally(() => {
                        isWaitingForLocation = false;
                    });
            }, interval);
        } else {
            onError(<GeolocationPositionError>{
                code: GeolocationPositionError.POSITION_UNAVAILABLE,
                message: 'navigator.geolocation unavailable or denied.',
            });
        }
    }

    /**
     * Always call this before allowing this object to be garbage collected.
     */
    stopWatching() {
        if (this.watcherId != undefined) {
            clearInterval(this.watcherId);
            this.watcherId = undefined;

            apmTransactions.instrumentGeolocationInfo(
                GeolocationInfoType.DeviceGeolocationWatchEnd,
                `Stopped watching device location in ${
                    this.options?.enableHighAccuracy ? 'high' : 'low'
                } accuracy mode.`
            );
        }
    }
}
