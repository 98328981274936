import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import FbaApiError from '@/types/FbaApiError';

export default async function deleteActiveNavigationTask(
    company: string,
    driverCode: string
): Promise<void | FbaApiError> {
    try {
        await axios({
            method: 'delete',
            url: `${process.env.VUE_APP_EXT_API_URL}api/eleosnavigationtasks/companies/${company}/drivers/${driverCode}`,
        });
    } catch (error) {
        return apiErrorHandler(error);
    }
}
