import { ref } from 'vue';
import { useStore } from 'vuex';
import { MutationTypes } from '@/store/mutationTypes';
import { ActionTypes } from '@/store/actionTypes';
import { AssociationType } from '@/types/AssociationType';
import FbaApiError from '@/types/FbaApiError';
import { isNullOrUndefined } from '../helpers';
import DriverTrailerAssociationResponseDto from '@/queries/trailerAssociations';
import Geopoint from '@/types/Geopoint';
import { TrailerSelectionMethod } from '@/types/TrailerSelectionMethod';

export default function useAssociation() {
    const store = useStore();

    const error = ref<FbaApiError | null>(null);
    const isLoading = ref<boolean>(false);
    const result = ref<boolean | null>(null);

    const updateAssociation = async (
        associationType: AssociationType,
        trailerNumber: string,
        trailerCompany: string,
        driverLocation: Geopoint,
        trailerQrId: string | undefined = undefined,
        trailerSelectionMethod: TrailerSelectionMethod | undefined = undefined
    ) => {
        let associationResponse:
            | DriverTrailerAssociationResponseDto
            | FbaApiError
            | undefined;
        try {
            isLoading.value = true;
            const actionName =
                associationType === AssociationType.DETACH
                    ? ActionTypes.POST_DETACH_DRIVER_TRAILER
                    : ActionTypes.POST_ATTACH_DRIVER_TRAILER;
            associationResponse = await store.dispatch(actionName, {
                trailerNumber,
                trailerCompany,
                driverLocation,
                trailerQrId,
                trailerSelectionMethod,
            });
        } finally {
            isLoading.value = false;
        }

        if (isNullOrUndefined(associationResponse)) {
            const invalidParamsError = new FbaApiError(
                'Tried to post trailer association without all required parameters'
            );
            store.commit(MutationTypes.SET_ERROR, invalidParamsError);
            error.value = invalidParamsError;
            return;
        }

        if (associationResponse instanceof FbaApiError) {
            store.commit(MutationTypes.SET_ERROR, associationResponse);
            error.value = associationResponse;
            return;
        }

        if (associationResponse.isSuccessful !== true) {
            const associationFailedError = new FbaApiError(
                'Trailer attach/detach association failed',
                associationResponse.reasonCode
            );
            store.commit(MutationTypes.SET_ERROR, associationFailedError);
            error.value = associationFailedError;
            return;
        }

        result.value = true;
    };

    return {
        result,
        error,
        isLoading,
        updateAssociation,
    };
}
