import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import FbaApiError from '@/types/FbaApiError';
import Truck from '@/types/Truck';
import Geopoint from '../types/Geopoint';

export default async function getTruckById(
    company: string,
    id: string
): Promise<Truck | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trucks/${company}/${id}`,
        });
        return new Truck(
            response.data.id,
            {
                lat: response.data.latitude,
                lng: response.data.longitude,
            } as Geopoint,
            response.data.telemetryLastReported
        );
    } catch (error) {
        return apiErrorHandler(error);
    }
}
