import IFeatureFlagResponseDto from '@/types/ApiDtos/IFeatureFlagResponseDto';

export default class FeatureFlags {
    trailerAssociationsEnabled: boolean;
    constructor(trailerAssociations: boolean) {
        this.trailerAssociationsEnabled = trailerAssociations;
    }

    static mapFromFeatureFlagResponseDto(dto: IFeatureFlagResponseDto) {
        const { driverTypeExclusionFilterEnabled } = dto.trailerSolutions;

        // if driverTypeExclusionFilterEnabled is true, meaning an exclusion filter is in place for the driver,
        // we should set trailerAssociationsEnabled to false.
        return new FeatureFlags(
            driverTypeExclusionFilterEnabled === true ? false : true
        );
    }
}
