import { defineComponent } from 'vue';
import { isNullOrUndefined } from '@/shared/helpers';
import { mapGetters } from 'vuex';
import queries from '@/queries';
import Truck from '@/types/Truck';
import Trailer from '@/types/Trailer';

export default defineComponent({
    computed: {
        ...mapGetters({ truck: 'getTruck' }),
    },
    methods: {
        async getTrailerAlreadyAttachedToTruck(): Promise<Trailer | null> {
            if (this.truck instanceof Truck) {
                const attachedTrailers =
                    await queries.getAttachedTrailersByTruckId(
                        this.truck?.truckId,
                        this.truck?.company
                    );
                if (
                    !isNullOrUndefined(attachedTrailers) &&
                    Array.isArray(attachedTrailers)
                ) {
                    const attachedTrailer = attachedTrailers[0];
                    if (attachedTrailer instanceof Trailer)
                        return attachedTrailer;
                }
            }

            return null;
        },
    },
});
