import { isNullOrUndefined } from '@/shared/helpers';
import FbaApiError from '@/types/FbaApiError';
import Geopoint from '@/types/Geopoint';
import RouteSummary from '@/types/RouteSummary';

/* eslint-disable @typescript-eslint/no-explicit-any */
let routingService: any;

if (!isNullOrUndefined((<any>window)?.H?.service?.Platform)) {
    const mapPlatform = new (<any>window).H.service.Platform({
        apikey: `${process.env.VUE_APP_ELEOS_DRIVER_FRONTEND_HERE_MAPS_API_KEY}`,
    });

    routingService = mapPlatform.getRoutingService(null, 8);
}

export default async function calculateRoute(
    destination: Geopoint,
    origin: Geopoint
): Promise<RouteSummary | undefined | FbaApiError> {
    const response = await routingService.calculateRoute(
        {
            transportMode: 'truck',
            origin: `${origin?.lat},${origin?.lng}`,
            destination: `${destination?.lat},${destination?.lng}`,
            return: 'travelSummary',
        },
        null,
        function (error: any) {
            alert(error.message);
            return new FbaApiError();
        }
    );
    const result = response.routes[0].sections[0].travelSummary;
    if (result) {
        return new RouteSummary(
            result.baseDuration,
            result.duration,
            result.length
        );
    }

    return undefined;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
