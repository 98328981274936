import apiErrorHandler from '@/libraries/apiErrorHandler';
import {
    DriverIdentifierDto,
    TrailerIdentifierDto,
    WithOptionalLocation,
} from '@/types/ApiDtos';
import { AssociationType } from '@/types/AssociationType';
import FbaApiError from '@/types/FbaApiError';
import axios from 'axios';
import apmTransactions from '@/libraries/apm/transactions';

export interface DriverTrailerAttachabilityDto {
    trailer: TrailerIdentifierDto;
    driver: WithOptionalLocation<DriverIdentifierDto>;
}

enum RawEvaluationType {
    TrailerStatus,
    TrailerAttached,
    TrailerReserved,
    TrailerProximity,
    LoadedStatus = 6,
    TrailerLoaded = 99, // Loaded is a temporary failure condition for the initial phase of attach/detach, so it was placed at 99 in the enum stack @joneh 20Sep2023
}

enum RawEvaluationResult {
    Pass,
    Warn,
    Fail,
}

export type EvaluationResult = 'override' | 'pass' | 'warn' | 'fail';
export type EvaluationType =
    | 'trailer_status'
    | 'trailer_attached'
    | 'trailer_reserved'
    | 'in_trailer_pool'
    | 'trailer_proximity'
    | 'loaded_status'
    | 'trailer_loaded';

interface RawAttachabilityResponseDto {
    canAttach: boolean;
    attachabilityEvaluations: RawAttachabilityEvaluationDto[];
}

export interface AttachabilityResponseDto {
    canAttach: boolean;
    attachabilityEvaluations: AttachabilityEvaluationDto[];
}

interface RawAttachabilityEvaluationDto {
    evaluationType: RawEvaluationType;
    name: RawEvaluationType;
    result: RawEvaluationResult;
    message: string;
}

interface AttachabilityEvaluationDto {
    name: EvaluationType;
    result: EvaluationResult;
    message: string;
}

interface ValidationError {
    field: string;
    message: string;
}

export default async function getAssociatabilityEvaluation(
    association: AssociationType,
    dto: DriverTrailerAttachabilityDto
): Promise<AttachabilityResponseDto | ValidationError[] | FbaApiError> {
    const endpoint =
        association === AssociationType.ATTACH
            ? 'driverTrailerAttachability'
            : 'driverTrailerDetachability';
    const url = `${process.env.VUE_APP_EXT_API_URL}api/trailerAssociations/${endpoint}`;

    let response;
    try {
        response = await axios({
            url,
            method: 'POST',
            data: {
                ...trailerToParams(dto.trailer),
                ...driverToParams(dto.driver),
            },
        });
    } catch (e) {
        return apiErrorHandler(e);
    }

    if (response.status === 200) {
        const mappedResponse = rawResponseToResponse(response.data);
        logResponseWarnOrFail(mappedResponse, association, dto.trailer);

        return mappedResponse;
    }

    if ('validationErrors' in response.data) {
        return response.data.validationErrors as ValidationError[];
    }

    return new FbaApiError(response.data);
}

function trailerToParams(trailer: DriverTrailerAttachabilityDto['trailer']) {
    if ('scannedQrId' in trailer) {
        return { scannedId: trailer.scannedQrId };
    }

    return { trailerId: trailer.compositeId };
}

function driverToParams(driver: DriverTrailerAttachabilityDto['driver']) {
    const params: any = {};

    if ('compositeId' in driver) {
        params.assetDriverId = driver.compositeId;
    } else {
        params.driverMobileNumber = driver.mobileNumber;
    }

    if ('location' in driver) {
        params.reportedLocation = driver.location;
    }

    return { driver: params };
}

function logResponseWarnOrFail(
    attachabilityResponse: AttachabilityResponseDto,
    association: AssociationType,
    dto: TrailerIdentifierDto
): void {
    const failOrWarnEvaluationTypes: string[] = [];

    attachabilityResponse.attachabilityEvaluations.forEach((aE) => {
        if (aE.result !== 'pass') {
            failOrWarnEvaluationTypes.push(aE.name.toString());
        }
    });

    if (failOrWarnEvaluationTypes.length > 0) {
        const trailerMapped = trailerToParams(dto);

        apmTransactions.instrumentFailedAssociabilityRule(
            association,
            failOrWarnEvaluationTypes,
            trailerMapped.trailerId || trailerMapped.scannedId,
            Object.keys(trailerMapped)[0]
        );
    }
}

function rawResponseToResponse(
    rawResponse: RawAttachabilityResponseDto
): AttachabilityResponseDto {
    const attachabilityEvaluations = rawResponse.attachabilityEvaluations.map(
        (e) => ({
            ...e,
            name: nameMap[e.evaluationType],
            result: resultMap[e.result],
        })
    );

    return {
        ...rawResponse,
        attachabilityEvaluations,
    };
}

const resultMap: {
    [R in RawEvaluationResult]: EvaluationResult;
} = {
    [RawEvaluationResult.Fail]: 'fail',
    [RawEvaluationResult.Pass]: 'pass',
    [RawEvaluationResult.Warn]: 'warn',
};

const nameMap: {
    [N in RawEvaluationType]: EvaluationType;
} = {
    [RawEvaluationType.TrailerAttached]: 'trailer_attached',
    [RawEvaluationType.TrailerProximity]: 'trailer_proximity',
    [RawEvaluationType.TrailerReserved]: 'trailer_reserved',
    [RawEvaluationType.TrailerStatus]: 'trailer_status',
    [RawEvaluationType.TrailerLoaded]: 'trailer_loaded',
    [RawEvaluationType.LoadedStatus]: 'loaded_status',
};
