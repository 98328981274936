import IGeopointDto from './ApiDtos/IGeopointDto';
import IGeopoint from './Geopoint';
import Truck from './Truck';

export default class Geopoint implements IGeopoint {
    lat: number;
    lng: number;
    accuracy: number | undefined;

    constructor(
        lat: number,
        lng: number,
        accuracy = undefined as number | undefined
    ) {
        this.lat = lat;
        this.lng = lng;
        this.accuracy = accuracy;
    }

    static mapFromGeolocationPosition(geo: GeolocationPosition) {
        return new Geopoint(
            geo.coords.latitude,
            geo.coords.longitude,
            geo.coords.accuracy
        );
    }

    static mapFromIGeopointDto(geo: IGeopointDto) {
        return new Geopoint(geo.lat, geo.lon);
    }

    static mapToIGeopointDto(geo: Geopoint | undefined) {
        return { lat: geo?.lat ?? 0, lon: geo?.lng ?? 0 } as IGeopointDto;
    }

    static mapFromTruck(truck: Truck) {
        return new Geopoint(truck.latitude, truck.longitude);
    }
}
