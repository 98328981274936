import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import IEleosNavigationTaskDto from '@/types/ApiDtos/IEleosNavigationTaskDto';
import IReserveTrailerResponseDto from '@/types/ApiDtos/IReserveTrailerResponseDto';
import FbaApiError from '@/types/FbaApiError';
import Geopoint from '@/types/Geopoint';

export default async function createNavigationTask(
    eventId: string,
    company: string,
    driverCode: string,
    truckNumber: string,
    name: string,
    city: string,
    state: string,
    zip: string,
    destination: Geopoint,
    trailerId?: string | undefined,
    minutesToReserveFor?: number | undefined,
    driverId?: string | undefined
): Promise<IReserveTrailerResponseDto | FbaApiError> {
    try {
        const taskDto: IEleosNavigationTaskDto = {
            eventId,
            driverCode,
            company: company.toLocaleLowerCase(),
            truckNumber: `${truckNumber}|${company.toLocaleLowerCase()}`,
            locationDescription: name,
            city,
            state,
            zip,
            latitude: destination.lat,
            longitude: destination.lng,
            trailerId,
            minutesToReserveFor,
            driverId,
        };
        const response = await axios({
            method: 'put',
            url: `${process.env.VUE_APP_EXT_API_URL}api/eleosnavigationtasks`,
            data: taskDto,
        });
        return <IReserveTrailerResponseDto>response.data.reservation;
    } catch (error) {
        return apiErrorHandler(error);
    }
}
