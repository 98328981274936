import apiErrorHandler from '@/libraries/apiErrorHandler';
import axios from '@/libraries/axios/axiosConfig';
import CustomerDto from '@/types/ApiDtos/ICustomerDto';
import Customer from '@/types/Customer';
import FbaApiError from '@/types/FbaApiError';
import Geopoint from '../types/Geopoint';

export default async function getCustomerByLocation(
    company: string,
    location: Geopoint
): Promise<Customer | null | FbaApiError> {
    try {
        const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_EXT_API_URL}api/customers/bylocation?lat=${location.lat}&lng=${location.lng}&company=${company}&limit=1`,
        });
        if (response.data && response.data.length > 0) {
            return Customer.mapFromCustomerDto(response.data[0] as CustomerDto);
        } else {
            return null;
        }
    } catch (error) {
        return apiErrorHandler(error);
    }
}
